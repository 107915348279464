import React, {useState,useEffect} from 'react';
import {useLocation,useNavigate,Link} from 'react-router-dom';
import constants from '../../../constants/constants';
import { getCustomisedBreadcrumbs, success } from '../../../functions/functions';
import ReactQuill from 'react-quill';
import {fetchAdminApi} from '../../../services/api';
import { COOKIE, getCookie } from '../../../../src/services/cookie/index';
import {
	Row,
	Col,
	Breadcrumb,
	Card,
	Select,
	Form,
	Button,
	Radio,
	Image,
	Input,
	Upload,
	InputNumber,
	message
} from 'antd';
const {Dragger} = Upload;
import {PlusOutlined} from '@ant-design/icons';

const EditQuestion = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [messageApi, contextHolder] = message.useMessage();
	const [form] = Form.useForm();
	const [edit, setEdit] = useState(false);
	const [answerinfo, setAnswerinfo] = useState([]);
	const [tobeModifiedId, settobeModifiedId] = useState();
	const [chapterid, setChapterid] = useState('');
	const [allChapters, setallChapters] = useState([]);
	const [answerdata, setanswerData] = useState([]);
	const [radiovalue, setradioValue] = useState();
	const [modexplanation, setModexplanation] = useState('');
	const {questionid,entireRecord} = location.state || {};
	const [chapter, setChapter] = useState('');
	const [newQuestionvalue, setnewQuestionvalue] = useState('');
	const [check, setCheck] = useState(false);
	const [updatedArray, setUpdatedArray] = useState([]);
	const [qType, setqtype] = useState('');
	const [imageOptioncount, setImageoptioncount] = useState('');
	const [imageInfo, setimageInfo] = useState([]);
	const [imageurl, setImageurl] = useState('');
	const [imagename, setImagename] = useState('');
	const [showupload, setShowupload] = useState(false);
	const [originalOplength, setOriginalOplength] = useState('');
	const [showUploadList, setShowuploadlist] = useState(false);
	const [originalimage, setOriginalimage] = useState('');
	const [thumbimage,setThumbimage] = useState('');
	const [labels, setLabels] = useState([{}]);
	const [imageChange,setImagechange] = useState(false);
	const [points,setPoints] = useState();
	const [formError, setFormError] = useState(false);
	const [fileList, setFileList] = useState([{
		uid: '-1',
		status: 'done',
		url: thumbimage,
		thumbUrl: thumbimage,
		name: imagename,
	}])
	const [correctOptioncount,setCorrectoptioncount] = useState();

	const onChangePoints = (value) => {
		setPoints(value)
	}

	const onChangeCorrectoptioncount = (value) => {
		setCorrectoptioncount(value)
	}

	const newCrumb = [<Link key={0} to={`/editQuestion/${tobeModifiedId}`} className="font-semibold !text-black"> Edit Question </Link>]
	useEffect(() => {
		if (questionid && questionid != '') {
			settobeModifiedId(questionid);
			setqtype(entireRecord.q_type);
			setChapterid(entireRecord.chapter_id);
			setEdit(true);
			//gettobeEditedQuestionid();
			if (imagename == null) {
				setShowuploadlist(false);
			} else {
				setShowuploadlist(true);
			}
		}
		getchapterDetails();
		if (allChapters && allChapters != '') {
			setChapter(allChapters.filter((el) => {
				return el.value == chapterid
			}));
		}
	}, [imagename,tobeModifiedId, edit])

	useEffect(()=>{
		if (questionid && questionid != '') {
			settobeModifiedId(questionid);
			setEdit(true);
			gettobeEditedQuestionid();
		}
	}, [tobeModifiedId,edit])

	const onValuesChange = async (values) => {
		try {
			await form.validateFields();
			//setFormError(false);
		} catch (err) {
			//setFormError(true);
		}
	}

	
	const goBack = () => {
		const cancelClick = true;
		localStorage.setItem('cancelledQuestion', entireRecord.q_type);
		localStorage.setItem('cancelledId', entireRecord.chapter_id);
		localStorage.setItem('cancelledClick', cancelClick);
		navigate(-1);
	}
	const handleimageChange = (info) => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);
		setFileList(fileList);
		setImagechange(true);
	}
	const props = {defaultFileList: [...fileList]}
	const handleoptionNumbers = (value) => {
		setImageoptioncount(value);
	}
	const normFile = (e) => {
		setimageInfo(e?.fileList);
		setShowuploadlist(true)
		if (Array.isArray(e)) {
			return e;
		}
		return e?.fileList;
	}
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	},[])
	useEffect(() => {
		// async function fetchData() {
		// 	if (!check) {
		// 		setCheck(true);
		// 		fetchAdminApi(`/get-question?question_id=${questionid}`, 'get').then((res) => {
		// 			if (res?.data && res?.data.length > 0) {
		// 				setanswerData(res.data[0].answer_data);
		// 			} else {
		// 				setanswerData([]);
		// 			}
		// 		})
		// 	}
		// }
		// fetchData();
		const updatedAnswersArray = answerinfo.map((obj, idx) => ({
			...obj,
			answerId: idx + 1
		}));
		setUpdatedArray(updatedAnswersArray);
		if (imageOptioncount > originalOplength) {
			const newQuills = [...Array(imageOptioncount - originalOplength).fill().map((x, i) => ({
				option_id: [i + 65].map((x) => String.fromCharCode(x + originalOplength)).at(0).toLowerCase(),
				option_text: '',
				corr_opt: 't',
				answerId: i + (originalOplength + 1),
			}))]
			Array.prototype.push.apply(answerinfo, newQuills);
			setUpdatedArray(answerinfo);
			setOriginalOplength(answerinfo.length);
		} else if (imageOptioncount < originalOplength) {
			let min = 0;
			let max = imageOptioncount;
			const filteredData = answerinfo.filter((_, i) => (i + 1 <= max && i + 1 > min));
			setUpdatedArray(filteredData);
		}

		if (imageOptioncount == originalOplength) {
			setUpdatedArray(answerinfo);
			setOriginalOplength(answerinfo.length);
		}
	}, [answerdata, answerinfo, imageOptioncount, originalOplength])

	function selectFewerPropsforSingleselect(show) {
		const { answer_option_id } = show;
		return {answer_option_id}
	}

	const optionIds = answerinfo.map(selectFewerPropsforSingleselect);
	let optionIdentities = optionIds.map(a => +(a.answer_option_id));

	useEffect(() => {
		setRadiovalue(radiovalue);
		setImageoptioncount(originalOplength);
	}, [radiovalue, originalOplength])
	const defaultCheckedradioValue = (arr) => {
		const getRadiovalue = arr.map(radioVal => radioVal.corr_opt).indexOf('t') + 1;
		return getRadiovalue;
	}
	const setRadiochangevalue = (e) => {
		setradioValue(e.target.value);
	}
	const getModifiedexplanation = (value) => {
		setModexplanation(value);
	}
	const getchapterDetails = () => {
		const chapters = JSON.parse(localStorage.getItem('chapterDetails'));
		if (chapters && chapters != '') {
			setallChapters(chapters);
		}
	}
	const handleChange = (value) => {
		//setChapter(value);
		setChapterid(value);
		const changedIdstatus = 'chapterIdChanged';
		localStorage.setItem('idChanged', changedIdstatus);
		localStorage.setItem('changedchapterId', value);
	}
	const selectFewerPropsforSingleselectEdit = (displayOnly) => {
		const {
			answer_option_id,
			option_text,
			corr_opt,
			other_opt
		} = displayOnly;
		return { answer_option_id, option_text, corr_opt, other_opt }
	}
	const modifiedAnswerDataSingleselect = updatedArray.map(selectFewerPropsforSingleselectEdit);
	const gettobeEditedQuestionid = () => {
		if (tobeModifiedId && tobeModifiedId != '') {
			fetchAdminApi(`/get-question?question_id=${questionid}`, 'get').then((res) => {
				if (res && res != '') {
					setAnswerinfo(res.data ? res.data[0].answer_data : '')
					setOriginalOplength((res.data[0].answer_data).length);
					setnewQuestionvalue(res.data[0].question_data[0].question_text);
					setImageurl(res.data[0].question_data[0].image_path);
					setThumbimage(res.data[0].question_data[0].image_thumb);
					setImagename(res.data[0].question_data[0].image_name);
					setShowupload(res.data[0].question_data[0].image_name !== "null" ? true : false);
					setOriginalimage(res.data[0].question_data[0].orig_image);
					setPoints(res.data[0].question_data[0].points);
					setCorrectoptioncount(res.data[0].question_data[0].corr_opt_cnt);
					setFileList((prev) => [{
						uid: '-1',
						status: 'done',
						url: res.data[0].question_data[0].image_thumb,
						thumbUrl: res.data[0].question_data[0].image_thumb,
						name: res.data[0].question_data[0].image_name
					}])
					form.setFieldsValue({
						oldquestionValue: res.data[0].question_data[0].question_text,
						Explanation: res.data[0].question_data[0].explanation,
						radio: (defaultCheckedradioValue(res.data[0].answer_data)),
						dragger: res.data[0].question_data[0].image_name,
						optionNumber: qType == 2 ? (res.data[0].answer_data).length : 4,
						originalImage: res.data[0].question_data[0].orig_image,
						midiumSizedimage: res.data[0].question_data[0].image_path,
						points: points,
						correctoptioncount: res.data[0].question_data[0].corr_opt_cnt,
					})
				}
			})
		}
	}
	const onImageUpload = async () => {
		try {
			const formData = new FormData();
			imageInfo.forEach((file) => {
				formData.append('image', file.originFileObj);
			});
			const response = await fetch(`${constants.AdminApiUrl}/upload-question-image/${questionid}`, {
				method: 'POST',
				body: formData,
				headers: {
					Authorization: `Bearer ${getCookie(COOKIE.Token)}`
				},
			});
			const data = await response.json();
			localStorage.setItem('imageUpdationStatus', data.code);
			if (data.code === 200) {
				navigate(-1);
			}
		} catch (error) { }
	}
	const updateQuillOptionValues = (index, value) => {
		const updateStepsArrayData = updatedArray.map((data, idx) => {
			return idx === index ? {
				...data,
				option_text: value,
				other_opt: '',
			} : data;
		});
		setUpdatedArray(updateStepsArrayData);
	}
	const getchangedQuillvalue = (value) => {
		setnewQuestionvalue(value);
	}
	const setRadiovalue = (correctValue) => {
		const newState = updatedArray.map((obj, i) => {
			if (i + 1 === correctValue) {
				return {
					...obj,
					corr_opt: 't'
				}
			} else if (i + 1 !== correctValue) {
				return {
					...obj,
					corr_opt: 'f'
				}
			}
			return obj;
		});
		setUpdatedArray(newState);
	}
	
	const submitNewvalues = (values) => {
		let incorrectOptioncount = updatedArray.filter((each) => (each.other_opt == null || each.other_opt == ''));
		let correctOptioncount = imageOptioncount - incorrectOptioncount.length;
		setCorrectoptioncount(correctOptioncount);
		let modifiedPayload = null;
		modifiedPayload = {
			answer_data: modifiedAnswerDataSingleselect,
			question_text: newQuestionvalue,
			chapter_id: chapterid,
			explanation: values.Explanation,
			edited_by: "1",
			is_tutorial: "f",
			opt_cnt: qType == 1 ? 4 : imageOptioncount,
			points: points,
			other_opt: '',
			corr_opt_cnt: qType == 1 ? 1 : correctOptioncount
		};

		fetchAdminApi(`/edit-question/${questionid}`, 'put', modifiedPayload).then((res) => {
			if (res.code === 200) {
				const editConfirmation = true;
				localStorage.setItem('questionEdited', editConfirmation);
				localStorage.setItem('editedchapterId', entireRecord.chapter_id);
				localStorage.setItem('editedQtype', entireRecord.q_type);
				//		navigate(-1);
				if (fileList && fileList.length > 0 && imageChange) {
					onImageUpload();
				} else {
					//navigate(-1);
					navigate(`/get-chapter-questions/${chapterid}`, { state: { chapterID: chapterid } });
				}
			}
		})
	}
	const updateLabelIds = (i, e) => {
		const updateLabelsarray = updatedArray.map((data, idx) => {
			return idx == i ? {
				...data,
				other_opt: e.target.value,
			} : data;
		});
		setUpdatedArray(updateLabelsarray);
	}
	const displayNotifics = (e) => {
		let { value } = e.target;
		const regex = /^(\d,?)+$/
		if (value != '') {
			if (value.match(regex) && value.split(',').length == 4 && value.split(',').length - 1 == 3) {
				const modifiedValue = value.split(',');
				let enteredValues = modifiedValue.map(a => +(a));
				if (optionIdentities.length >= 4) {
					const hasAllElems = enteredValues.every(elem => optionIdentities.includes(elem));
					if (hasAllElems) {
						success('success', 'optionIds are correct!', 'notificationPosition', messageApi)
						setFormError(false);
					} else {
						success('error', 'Values need to be same as answerIds!', 'notificationPosition', messageApi)
						setFormError(true);
					}
					return value;
				} else if (optionIdentities.length < 4) {
					const setB = new Set(optionIdentities);
					const commonEls = enteredValues.filter(el => setB.has(el));
					const commonArrLen = commonEls.length;
					if (commonArrLen < 4) {
						success('error', `Remaining  ${4 - commonArrLen} option(s) need to be same as answerIds!`, 'notificationPosition', messageApi);
						setFormError(true);
					} else {
						setFormError(false);
						return value;
					}
				}
			} else {
				success('error', constants.incorrect_label_format, 'notificationPosition', messageApi)
			}
		} else {
			setFormError(false);
		}

	}

	return (
		<div className="cursorChange">
			<Row>
				<Col
					xs={24}
					sm={24}
					md={24}
					lg={24}
				>
					<div className="min-h-screen p-4 bg-gray-100">
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.questionPagecrumbs, newCrumb)}
								className='bg-neutral-100 shadow-lg p-3 mb-3' />
						</div>
						<Card
							extra={
								<div className="editCard-extra">
									<span className="font-semibold text-lg pr-5 qIdDetails"> {`Qn  #${tobeModifiedId}`} </span>
									<span className="font-semibold text-lg pr-5 qIdDetails">|</span>
									<span className="font-semibold text-lg pr-5 qIdDetails"> Question type: {
										qType == 1 ? 'Single select' : 'Image Question'
									}
									</span>
								</div>
							}
							hoverable
							className='editQcard'
							title=
							{
								<div
									className="editcardHeader">
									<span className="mr-8"> EDIT QUESTION </span>
									<Select
										// options={allChapters}
										onChange={handleChange}
										className='editQ-select w-80'
										value={chapterid}>
										{
											allChapters.map((each) => {
												return <Option key={each.value} value={
													each.value.toString()
												}> {each.label} </Option>
											})
										}

									</Select>
								</div>}
						>
							<Form
								form={form}
								onFinish={submitNewvalues}
								name="basicform"
								id='editform'
								onValuesChange={onValuesChange}
							>
								<Card
									title="Question"
									className='mt-2 editCard questionCard'
									hoverable
								>
									<Form.Item
										name='oldquestionValue'
										rules={
											[{
												required: true,
												message: constants.mandatoryField
											},
											{
												validator: (_, value) =>
													value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
											},
											]
										}
									>
										<ReactQuill
											theme="snow"
											value={newQuestionvalue}
											modules={constants.ReactQuillModules}
											formats={constants.ReactQuillFormats}
											onChange={getchangedQuillvalue}
										/>
									</Form.Item>
								</Card>
								<Card className='mt-3' hoverable>
									<Row>
										<Col md={24}>
											<div className="flex w-full flex-wrap">
											<Form.Item
												label="No. of options :"
												className='defaultflexDirection'
												name='optionNumber'
												rules={
													qType == 1 ? [{
														required: false,
														message: ""
													}] : [{
														required: true,
														message: constants.mandatoryField
													}]
												}>
												{
													qType == 1
														?
														<div className='absolute font-medium text-base'> 4 </div>
														:
														<InputNumber
															min={1}
															max={26}
															value={imageOptioncount}
															onChange={handleoptionNumbers}
															className='mt-3'
														/>
												}
											</Form.Item>
											<Form.Item name="points" label="Points" className='ml-7 points-field'>
												<InputNumber
													min={0}
													value={points}
													onChange={onChangePoints}
													className='mt-3'
												/>
											</Form.Item>
											<Form.Item name="correctoptioncount" label="Correct option count" className='ml-7 points-field'>
												<InputNumber
													min={0}
													value={correctOptioncount}
													onChange={onChangeCorrectoptioncount}
													className='mt-3'
												/>
											</Form.Item>
										</div>
										</Col>
									</Row>
									<Row className='mt-7'>
										<Col className='flex w-full'>
											<Form.Item
												style={{ flexBasis: '75%' }}
												name="dragger"
												getValueFromEvent={normFile}
												action="/upload.do"
												className='defaultflexDirection mb-5'
												label="Image :"
												rules={
													qType == 1 ? [{
														required: false,
														message: ""
													}] : [{
														required: true,
														message: ""
													},
													{
														validator: (_, value) =>
															value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
													},
													]
												}
											>
												<Dragger
													name="files"
													action=""
													showUploadList={showUploadList}
													listType="picture"
													onChange={handleimageChange}
													fileList={[...fileList]}
													maxCount={1}
													accept=".png,.jpeg,.jpg"
													beforeUpload={
														(file) => {
															const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
															if (!isJpgOrPng) {
																message.error(constants.imageTypeSelection);
															}
															return false;
														}
													} {...props}>
													<p className="ant-upload-drag-icon"> <PlusOutlined /> </p>
													<p className="ant-upload-text"> {constants.chooseImage} </p>
												</Dragger>
											</Form.Item>
											{showUploadList === true && imageChange === false ?
												<Form.Item className="ml-2" name="midiumSizedimage" style={{ flexBasis: '25%' }}>
													<Image src={imageurl} alt="image"></Image>
												</Form.Item>
												:
												<div></div>}
										</Col>
										{/* <Col>
											{showUploadList === true && imageChange === false?
													<Form.Item name="originalImage">
														<Image src={originalimage} alt="image"></Image>
													</Form.Item>
													: 
													<div></div>
												}
											</Col> */}
									</Row>
								</Card>
								{
									qType == 1 ?
										<Card
											title={constants.selectOneoptionText}
											className='mt-2 editCard optionEditors'
											hoverable
										>
											<Row className="editCardops flex flex-row">
												<Col className="radioflexBasis" md={2} lg={3}>
													<Form.Item name='radio'
														rules={
															[{
																required: true,
																message: constants.selectcorrectOption,
															},
															{
																validator: (_, value) =>
																	value != '' ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
															}
															]
														}
													>
														<Radio.Group
															onChange={setRadiochangevalue}
															className='font-bold text-base flex flex-col'>
															<Radio value={1} className='label1'></Radio>
															<Radio value={2} className='label2'></Radio>
															<Radio value={3} className='label3'></Radio>
															<Radio value={4} className='label4'></Radio>
														</Radio.Group>
													</Form.Item>
												</Col>
												<Col className='quill-setion quillflexBasis' xs={10} sm={18} md={22} lg={21}>
													{
														updatedArray.map((field, index) => {
															const totalCount = [index + 65];
															const alphabets = totalCount.map((x) => String.fromCharCode(x));
															return (
																<div className="optns_image_types" key=''>
																	<Form.Item>
																		<div className='font-bold text-base labels relative top-7'> {alphabets} </div>
																	</Form.Item>
																	<Form.Item
																		key={index}
																		name={[field.option_id, "option_text"]}
																		initialValue={field.option_text}
																		rules={
																			[{
																				required: true,
																				message: "",
																			},
																			{
																				validator: (_, value) =>
																					value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
																			},
																			]
																		}>

																		<ReactQuill
																			theme="snow"
																			modules={constants.ReactQuillModules}
																			formats={constants.ReactQuillFormats}
																			className='quill-option mt-2'
																			onChange={value => updateQuillOptionValues(index, value)}
																		/>
																	</Form.Item>
																</div>
															)
														})
													}
												</Col>
											</Row>
										</Card>
										:
										<Card
											title="Options"
											className='mt-2 editCard optionEditors'
											hoverable
										>
											{
												updatedArray.map((field, index) => {
													const totalCount = [index + 65];
													const alphabets = totalCount.map((x) => String.fromCharCode(x));
													return (
														<div className="optns_image_types" key=''>
															<Form.Item style={{ flexBasis: '10%' }} className='flex align-items justify-center'>
																<div className='font-bold text-base labels relative top-11'>{alphabets}</div>
																<div className='font-bold text-sm ml-7 relative top-5 mr-2 optionid_color'>({field.answer_option_id
																})
																</div>
															</Form.Item>
															<Form.Item style={{ flexBasis: '67%' }} key={index}
																name={
																	[field.option_id, "option_text"]
																}
																initialValue={field.option_text}
																rules={
																	[{
																		required: true,
																		message: "",
																	},
																	{
																		validator: (_, value) =>
																			value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
																	},
																	]
																}>
																<ReactQuill
																	theme="snow"
																	modules={constants.ReactQuillModules}
																	formats={constants.ReactQuillFormats}
																	className='quill-option mt-2'
																	onChange={value => updateQuillOptionValues(index, value)}
																/>
															</Form.Item>
															<Form.Item initialValue={field.other_opt} label="Other options" name={[index, "labels"]} className='ml-3 labelFormitem' style={{ flexBasis: '26%' }}
																rules={
																	[{
																		required: false,
																		message: ''
																	},
																	]
																}
															>
																<Input className='h-16' value={labels[index]} onChange={(e) => updateLabelIds(index, e)} onBlur={displayNotifics}></Input>
															</Form.Item>
														</div>
													)
												})
											}
										</Card>
								}
								<Card
									title="Explanation"
									className='mt-2 explaination'
									hoverable
								>
									<Form.Item name='Explanation'>
										<ReactQuill
											theme="snow"
											value={modexplanation}
											modules={constants.ReactQuillModules}
											formats={constants.ReactQuillFormats}
											className='quill-option'
											onChange={getModifiedexplanation}
										/>
									</Form.Item>
								</Card>
								<Form.Item>
									<Button type="primary" htmlType="submit" className="mt-3 font-semibold" disabled={!form.isFieldsTouched() || formError}> Submit </Button>
									<Button type="primary" className='ml-4 font-semibold' onClick={goBack}> Cancel </Button>
								</Form.Item>
								{contextHolder}
								<Form.Item>
									{showUploadList === true && imageChange === false ?
										<Form.Item name="originalImage">
											<Image src={originalimage} alt="image"></Image>
										</Form.Item>
										:
										<div></div>
									}
								</Form.Item>
							</Form>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}
export default EditQuestion;
