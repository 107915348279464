import React, { useState, useEffect } from 'react';
import { getCustomisedBreadcrumbs } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { Chart } from "react-google-charts";
import dayjs from 'dayjs';
import { useNavigate,Link } from 'react-router-dom';
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Table,
	DatePicker,
	Empty
} from 'antd';
import { fetchAdminApi } from '../../../services/api';

function RegisterationsByPeriod(props) {
	const navigate = useNavigate();
	const [year, setYear] = useState(dayjs().weekYear());
	const newCrumb = [<Link key={1} to="/total-registrations" className="!text-black font-semibold">Total Registrations</Link>]
	const [registerationdata, setregisterationData] = useState([]);
	const [filteredData, setFiltereddata] = useState([]);
	const [registrationcount, setRegistrationcount] = useState();
	const yearFormat = 'YYYY';
	const defaultYear = dayjs().year().toString();

	useEffect(() => {
		getDonutchartdata();
	}, [year])

	const getDonutchartdata = () => {
		fetchAdminApi(`/total-registration?year=${year}`, "get").then(
			(res) => {
				if (res?.data) {
					setregisterationData(res.data);
					setFiltereddata(res.data);
					let chartHeader = [["Months", "RegisterationCount"]];
					for (let index = 0; index < res.data.length; index++) {
						if (res.data[index].student_count) {
							let all = [];
							all.push(res.data[index].month_in_3_words, Number(res.data[index].student_count));
							chartHeader.push(all);
						}
					}
					setregisterationData(chartHeader);
				}
			})
	}

	const getInfoAndRedirect = (record,index)=>{
		let month = index+1;
		let chosenYear = record.year;
		if (month && chosenYear){
			let startD = dayjs().year(chosenYear).month(month-1).startOf('month').format('MM/DD/YYYY');
			let endD = dayjs().year(chosenYear).month(month-1).endOf('month').format('MM/DD/YYYY');
			let newRegistrationdate  = {};
			newRegistrationdate.startdate = startD;
			newRegistrationdate.enddate = endD;
			newRegistrationdate.criteria = `Registrations from ${startD} to ${endD}`;
			navigate('/registered-students', { state: { newPeriodData: newRegistrationdate }});
		}
	}

	const switchYear = (date, dateString) => {
		setYear(dateString);
	}

	const options = {
		title: `REGISTRATIONS FOR THE YEAR ${year}`,
		titleTextStyle: {
			fontSize: 18,
			bold: 'true',
			color: '#8a1253',
		},
		pieHole: 0.4,
		is3D: false,
		responsive: true,
		maintainAspectRatio: false,
		pieSliceText: 'label',
		sliceVisibilityThreshold: 0,
		slices: {
			0: { color: '#58b3d3' },
			1: { color: '#BA55D3' },
			2: { color: '#fdb44b' },
			3: { color: '#f76b8a' },
			4: { color: '#DC143C' },
			5: { color: '#BDB76B' },
			6: { color: '#556B2F' },
			7: { color: '#191970' },
			8: { color: '#BC8F8F' },
			9: { color: '#007cb9' },
			10: { color: '#8B4513' },
			11: { color: '#FF8C00' },
		},
		chartArea: { left: 120, top: 70, width: "80%", height: "80%" }
	};


	const tabColumns = [
		{
			title: 'Month',
			key: 'Month',
			align: "left",
			render: (text,record,index) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer' onClick={() => getInfoAndRedirect(record,index)}> {record.month_in_words}</div>
				)
			}
		},
		{
			title: 'Registrations',
			key: 'Registerations',
			align: "right",
			render: (text, record, index) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer' onClick={() => getInfoAndRedirect(record, index)}> {record.student_count}</div>
				)
			}
		}
	]

	const locale = {
		lang: {
			placeholder: year,
			locale: "zh-tw"
		}
	};


	return (
		<div className="cursorChange">
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card title="TOTAL REGISTRATIONS" className="addQcard" hoverable>
							<Row>
								<Col xs={24} md={8} lg={8}>
									<Table
										bordered
										dataSource={filteredData}
										columns={tabColumns}
										className='registrationTable'
										pagination={false}
										rowKey={(record) => record.count}
										title={() => { return <div className="font-bold text-base text-white">{`REGISTRATIONS FOR THE YEAR ${year}`}</div> }}
										size="small"
										summary={(pageData) => {
											console.log(pageData);
											let totalPoints = 0;
											pageData.forEach(({ student_count }) => {
												totalPoints += Number(student_count)
												setRegistrationcount(totalPoints);
											});
											return (
												<Table.Summary fixed>
													<Table.Summary.Row>
														<Table.Summary.Cell index={0} className="font-bold text-base text-left">Total</Table.Summary.Cell>
														<Table.Summary.Cell index={1} className="font-bold text-base text-right">{totalPoints}</Table.Summary.Cell>
													</Table.Summary.Row>
												</Table.Summary>
											)
										}}
									/>
								</Col>
								<Col xs={24} sm={24} md={16} lg={16}>
									<Card hoverable className="mb-2 ml-3">
										<span className="text-base mr-3 font-semibold text-red-600">Year :</span>
										<DatePicker picker="year" format={yearFormat} defaultValue={dayjs(defaultYear, yearFormat)} locale={locale} className='w-2/4' onChange={switchYear} />
									</Card>
									<Card className='ml-5 text-center' style={{ height: '500px' }} hoverable>
										{registrationcount != 0
											?
											<Chart
												chartType="PieChart"
												width={'100%'}
												height={'500px'}
												data={registerationdata}
												options={options}
												loader={<div className="text-lg h-full w-full text-center font-bold">Loading Chart...</div>}
											/>
											:
											<div className=''><Empty description={false}><span className="text-lg text-center font-bold font-sans text-sky-600">NO DATA TO DISPLAY</span></Empty></div>
										}
									</Card>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default RegisterationsByPeriod
