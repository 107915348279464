import React, {useState,useEffect} from 'react';
import {
	Table,
	Row,
	Col,
	Breadcrumb,
	Tooltip,
} from 'antd';
import {Link} from 'react-router-dom';
import {getCustomisedBreadcrumbs} from '../../../functions/functions';
import constants from '../../../constants/constants';
import { fetchAdminApi} from '../../../services/api';


function ImageList() {
	const [chapterwiseImagedata, setChapterwiseImagedata] = useState([]);
	const newCrumb = [<Link key={0} to="/image-list" className="font-semibold !text-black"> Images </Link>]
	useEffect(() => {
		getchapterrelatedImageData();
	},[])
	const getchapterrelatedImageData = () => {
		fetchAdminApi(`/images-list`, 'get').then((res) => {
			if(res?.data&&res?.data.length>0){
				setChapterwiseImagedata(res.data);
			}else{
				setChapterwiseImagedata([]);
			}
		})
	}
	const tabColumns = [{
			title: <div className='text-base'>Chapter</div>,
			dataIndex: "chapter_id",
			key: "chapter_id",
			align: "center",
			width: '10%',
			sorter: (a, b) => a.chapter_id - b.chapter_id,
		},
		{
			title: <div className="text-base">Title (No. of images)</div>,
			dataIndex: "chapter_name",
			key: "chapter_name",
			width: '80%',
			render: (chapter_name,record) => {
				return ( 
					<Tooltip title = {
						chapter_name
					}>
						<Link 
						to = {`/get-chapter-images/${record.chapter_id}`}
						state = {
							{
								chapterId: record.chapter_id,
								entireRec: record
							}
						}
							className='linkColor'
						>
							<span className="mr-2 text-sm"> {chapter_name} </span>
							<span className='italic font-semibold text-sm'>({record.total_count})</span>
						</Link> 
					</Tooltip>
				)
			}
		},
	]
	
	return ( 
		<div>
				<Row>
						<Col xs = {24} sm = {24} md = {24} lg ={24}>
								<div className = 'p-4 min-h-screen bg-gray-100'>
									<div>
										<Breadcrumb items = {getCustomisedBreadcrumbs(constants.flashCardpageCrumbs, newCrumb)}
										className = 'bg-neutral-100 shadow-md p-3'/>
									</div> 
									<Table 
									bordered 
									columns = {tabColumns}
									dataSource = {chapterwiseImagedata}
									pagination = {false}
									locale = {{emptyText:constants.emptyTableText}}
									tableLayout = 'auto'
									title = {
										() => <div className = "font-bold tracking-wide text-lg pl-3 flashTableTitle" > IMAGE LIST </div>
									}
									className = 'mt-4'>
									</Table> 
								</div> 
						</Col> 
				</Row> 
		</div>
	)
	}

	export default ImageList
