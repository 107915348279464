import React, {useState,useEffect} from 'react';
import {
	Row,
	Col,
	Button,
	Breadcrumb,
	Form,
	Card,
	Select
} from 'antd';
import ReactQuill from 'react-quill';
import {useLocation,useNavigate,Link} from 'react-router-dom';
import {getCustomisedBreadcrumbs} from '../../../functions/functions';
import constants from '../../../constants/constants';
import {fetchAdminApi} from '../../../services/api';
import { COOKIE, getCookie} from '../../../../src/services/cookie/index';

function EditFlashcard() {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const location = useLocation();
	const {flashId,entireRecord} = location.state || {};
	const [flashcardId, setFlashcardId] = useState('');
	const [flashQuestion, setFlashquestion] = useState('');
	const [flashAnswer, setFlashanswer] = useState('');
	const [chapterid, setChapterid] = useState(entireRecord.chapter_id);
	const [allChapters, setAllchapters] = useState([]);
	useEffect(() => {
		let userId = getCookie(COOKIE.UserId);
		if (!userId) {
			navigate('/admin-login');
		}
	}, []);
	const newCrumb = [<Link key={0}
			to = "/flashcard-list" className='font-semibold'> Flashcards </Link>,
			<Link  key={1} to={`/edit -flashcard/${flashcardId}`} className='font-semibold !text-black'>Edit Flashcard</Link>]

	useEffect(() => {
		getFlashcardinfo();
	}, [])
	const getFlashcardinfo = () => {
		fetchAdminApi(`/get-flashcard?flashcard_id=${flashId}`, 'get').then((res) => {
			if (res?.data && res?.data.length>0) {
				form.setFieldsValue({
					flashQuestion: res.data[0].question,
					flashAnswer: res.data[0].answer
				})
			}
		})
	}
	useEffect(()=>{
		if (flashId && flashId != ''){
			setFlashcardId(flashId);
		}
		getchapterDetails();
	}, [flashcardId])
	const getchapterDetails = () => {
		const chapters = JSON.parse(localStorage.getItem('chapterDetails'))
		if (chapters && chapters != '') {
			setAllchapters(chapters);
		}
	}
	const handleFlashQuestion = (value)=>{
		setFlashquestion(value);
	}
	const handleFlashAnswer = (value) =>{
		setFlashanswer(value);
	}
	const changeFlashChapterid = (value)=>{
		setChapterid(value);
		const flashchapterEdited = true;
		localStorage.setItem('flashcardchapterEdited', flashchapterEdited);
		localStorage.setItem('flashChapterchangeId', value);
	}
	const submitFlashcardInfo = (values)=>{
		let modifiedflashPayload = null;
		modifiedflashPayload = {
		question: values.flashQuestion,
		answer: values.flashAnswer,
		flashcard_id: flashId,
		chapter_id: chapterid
		};
	fetchAdminApi(`/edit-flashcard/${flashId}`,'put',modifiedflashPayload).then((res) => {
		if (res.code === 200) {
			const editConfirmation = true;
			localStorage.setItem('flashquestionEdited', editConfirmation);
			localStorage.setItem('flashChapterId', entireRecord.chapter_id);
			navigate(`/get-chapter-flashcards/${chapterid}`, { state: { chapterID: chapterid } });
			form.resetFields();
		}
	})
	}
	const redirectTolistpage = () => {
		const flashQuestionnotEdited = true;
		localStorage.setItem('flashquestionNotEdited', flashQuestionnotEdited);
		localStorage.setItem('flashChapterId',chapterid);
		navigate(-1);
	}
	
	return (
	<div className="cursorChange">
		<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
						<div className = 'p-4 min-h-screen bg-gray-100'>
									<div>
										<Breadcrumb 
											items = {getCustomisedBreadcrumbs(constants.flashCardpageCrumbs,newCrumb)}
											className = 'bg-neutral-100 shadow-lg p-3 mb-3'
										/>
									</div>
									<Card 
										className='mt-3 editFlashcard'
										title={<div className='flex-space'><span className="mr-8">EDIT FLASHCARD</span>
										<Select
										onChange={changeFlashChapterid}
										value={chapterid}
										className='editCard-extra w-80'>
												{
													allChapters.map((each) => {
														return <Option key={each.value} value={
															each.value.toString()
														}> {each.label} </Option>
													})
												}
										</Select>
										</div>
										}
										extra = 
										{<span className="font-semibold text-xl pr-2 text-pink-600">Flashcard  #{flashId}</span>}
										hoverable
									>
										<Form form={form} onFinish={submitFlashcardInfo}>
											<Card title="Question" hoverable className="editflashQ">
												<Form.Item 
													name="flashQuestion"
													rules = {
													[{
													required: true,
													message: constants.mandatoryField
													},
													{
													validator: (_, value) =>
													value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
													},
													]
													}>
														<ReactQuill 
															theme = "snow"
															value={flashQuestion}
															modules = {constants.ReactQuillModules}
															formats = {constants.ReactQuillFormats}
															className = 'quill-option'
															onchange={handleFlashQuestion}
														/>
												</Form.Item>
											</Card>
											<Card 
												title = "Answer"
												className = 'mt-5 editflashA'
												hoverable
											>
												<Form.Item 
													name="flashAnswer"
													rules = {
														[{
																required: true,
																message: constants.mandatoryField
															},
															{
																validator: (_, value) =>
																	value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
															},
														]
													}
												>
													<ReactQuill 
														theme = "snow"
														value={flashAnswer}
														modules = {constants.ReactQuillModules}
														formats = {constants.ReactQuillFormats}
														className = 'quill-option' onchange={handleFlashAnswer}
													/>
												</Form.Item>
											</Card>
												<Button className="mt-5 font-semibold" type="primary" htmlType="submit">Submit</Button>
												<Button type="primary" className='mt-3 ml-3 font-semibold' onClick={redirectTolistpage}>Cancel</Button>
										</Form>
									</Card>
						</div>
				</Col>
		</Row>
	</div>
	)
}

export default EditFlashcard;
