
import React,{useState,useEffect} from 'react';
import { Button, Layout, Menu, message, Avatar, Drawer } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { HomeOutlined, CaretDownOutlined, RedoOutlined, SnippetsOutlined, LogoutOutlined, LineChartOutlined, UserOutlined, FormOutlined } from '@ant-design/icons';
const {Sider} = Layout;
import { COOKIE, deleteCookie, getCookie } from '../../../services/cookie';
import { fetchAdminApi } from '../../../services/api';
import constants from '../../../constants/constants';

const Siders = () => {
	const navigate = useNavigate();
	let location = useLocation();
	const [usertype,setUsertype] = useState();
	const [username, setUserName] = useState('');
	const [drawervisble,setDrawerVisible] = useState(false);
	const [current, setCurrent] = useState(location.pathname);
	const [selectedKeyAdmin,setSelectedkeyAdmin] = useState('1');
	const [selectedKeyPublisher,setSelectedkeyPublisher] = useState('1');
	const [sidebarmenus, setSidebarMenus] = useState();
	const adminNavItems = [{ icon: HomeOutlined, label: <Link to="/" className="font_size">Dashboard</Link> }, { icon: SnippetsOutlined, label: <Link to="/master-menus" className="font_size">Masters</Link> }, { icon: LineChartOutlined, label: <Link  to="/analysis-menus" className="font_size">Analysis</Link> }, { icon: FormOutlined, label: <Link to="/feedback-menus" className="font_size">Feedback</Link> }].map((icon, index) => {
		const key = String(index + 1);
		return {
			key: key,
			icon: React.createElement(icon.icon),
			label: icon.label
		}
	});
	useEffect(() => {
		if (usertype == '0'){
			if (location) {
				if (location.pathname == '/') {
					setSelectedkeyAdmin('1');
				} else if (location.pathname == '/master-menus'){
					setSelectedkeyAdmin('2');
				} else if (location.pathname == '/analysis-menus') {
					setSelectedkeyAdmin('3');
				}else if (location.pathname == '/feedback-menus') {
					setSelectedkeyAdmin('4');
				}
			}
		}
		if (usertype == '1') {
			if (location) {
				if (location.pathname == '/') {
					setSelectedkeyPublisher('1');
				}else if (location.pathname == '/analysis-menus') {
					setSelectedkeyPublisher('2');
				} else if (location.pathname == '/feedback-menus') {
					setSelectedkeyPublisher('3');
				}
			}
		}
	}, [location, usertype,selectedKeyAdmin, selectedKeyPublisher, sidebarmenus]);

	const publisherNavItems = [{ icon: HomeOutlined, label: <Link to="/" className="font_size">Dashboard</Link> },{ icon: LineChartOutlined, label: <Link to="/analysis-menus" className="font_size">Analysis</Link> }, { icon: FormOutlined, label: <Link to="/feedback-menus" className="font_size">Feedback</Link>}].map((icon, index) => {
		const key = String(index + 1);
		return {
			key: key,
			icon: React.createElement(icon.icon),
			label: icon.label
		}
	});
	
	const displayDrawer = ()=>{
		setDrawerVisible(true);
	}

	const hideDrawer = () => {
		setDrawerVisible(false);
	};

	const redirectToRstPaswdPage = ()=>{
		navigate('/change-password');
		setDrawerVisible(false);
	}

	const displayModalContent = ()=>{
		return (
			 <Drawer
				title={<div className="flex justify-center items-center flex-col"><Avatar className="bg-cyan-700 text-white" shape="square" size={64} icon={<UserOutlined />} /><div className="mt-3 text-lg text-black font-semibold">{`Hi, ${username}!`}</div></div>}
				height={90}
				width={250}
				placement='left'
        closable={true}
				onClose={hideDrawer}
				open={drawervisble}
      >
			<div className="flex flex-col">
					<Button className='font-semibold bg-pink-700 text-white h-10 btnFontsize' icon={<RedoOutlined />} onClick={redirectToRstPaswdPage}>{constants.ChangePswBTN}</Button>
					<Button className='font-semibold bg-pink-700 text-white h-10 btnFontsize mt-3' icon={<LogoutOutlined />} onClick={handleLogout}>Logout</Button>
				</div>
      </Drawer>
		)
	}

	useEffect(()=>{
		if (localStorage.getItem('userType')) {
			const user = localStorage.getItem('userType');
			const username = localStorage.getItem('userName');
			setUsertype(user);
			setUserName(username);
		}
	},[])

	useEffect(() => {
		if (usertype != null || usertype != ''){
			usertype == 0 ? setSidebarMenus(adminNavItems) : setSidebarMenus(publisherNavItems);
		}
	}, [usertype])
	
	const handleLogout = () => {
		let userIdFromCookies = getCookie(COOKIE.UserId);
		let payload = null;
		payload = { is_logged_in: 0, };
		fetchAdminApi(`/logout/${userIdFromCookies}`, "post", payload).then((res) => {
			if (res && res.code && res.code === 200) {
				deleteCookie(COOKIE.UserId, "/");
				deleteCookie(COOKIE.Token,"/");
				localStorage.removeItem('userType');
				localStorage.removeItem('userName');
				window.location.href = constants.BaseUrl + "/admin-login";
				sessionStorage.clear();
			} else {
				message.error(res.message);
			}
		});
	};

	const handleClick = (e)=>{
		setCurrent(e.keyPath);
	}
	return (
		<Sider 
			className = "bg-cyan-300 sidePanel"
			collapsible 
			breakpoint='lg'
			collapsedWidth = '0'
			onBreakpoint={(broken) => {}}
			onCollapse={(collapsed, type) => {
			}}
		>
			<div className="text-center mx-auto my-5">
				<UserOutlined style={{fontSize:'19px'}} className='bg-cyan-700 p-2 text-white border-slate-50 border-solid border-2 rounded-full'/>
				<div className='mt-2 text-pink-600 font-semibold text-lg cursor-pointer' onClick={() => displayDrawer()}>{username}<CaretDownOutlined className='ml-1 text-red-600 mt-2'/></div>
			</div>
			<Menu selectedKeys={usertype == 0 ? selectedKeyAdmin : selectedKeyPublisher}  onClick={handleClick} theme="dark" mode="inline" items={sidebarmenus} defaultSelectedKeys={["1"]}></Menu>
			{drawervisble && displayModalContent()}
		</Sider>
)
}
export default Siders
