import React, {useState,useEffect} from 'react'
import {useLocation,useNavigate,Link} from 'react-router-dom';
import {
	Row,
	Col,
	Breadcrumb,
	Button,
	Form,
	Card,
	message
} from 'antd';
import {getCustomisedBreadcrumbs,success} from '../../../functions/functions';
import constants from '../../../constants/constants';
import ReactQuill from 'react-quill';
import {fetchAdminApi} from '../../../services/api';
import { COOKIE, getCookie} from '../../../../src/services/cookie/index';

function AddFlashcard() {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();
	const location = useLocation() || {};
	const {data} = location.state || {};
	const [chaptername,setChaptername] = useState('');
	const [flashQuestion, setflashQuestion] = useState('');
	const [flashAnswer, setflashAnswer] = useState('');
	const newCrumb = [<Link key = {0} to = "/flashcard-list" className='font-semibold'> Flashcards </Link>,
	<Link key={1} to="/addFlashcard" className='font-semibold !text-black'>Add Flashcard</Link>]

	useEffect(() => {
		let userId = getCookie(COOKIE.UserId);
		if (!userId) {
			navigate('/admin-login');
		}
	}, []);

	const submitFlashcardDetails = (values) => {
		let flashcardpayload = null;
		flashcardpayload = {
			question: flashQuestion,
			chapter_id: data[0].id,
			answer: flashAnswer
		}

		fetchAdminApi(`/add-flashcard`,'post', flashcardpayload).then((res) => {
			if (res.code === 200) {
				let successCode = res.code;
				localStorage.setItem('flashcardSaved', successCode);
				localStorage.setItem('flashChapterid', data[0].id);
				localStorage.getItem('flashChapterid');
				navigate(-1);
				form.resetFields();
			} else {
				form.resetFields();
			}
		})
	}
	const handleflashQuestion = (value) => {
		setflashQuestion(value);
	}
	const handleflashAnswer = (value) => {
		setflashAnswer(value);
	}
	useEffect(() => {
		getChapterDetails();
	}, [])
	const redirectTolistpage = () => {
		let questionNotadded = true;
		localStorage.setItem('flashcardnotSaved', questionNotadded);
		localStorage.setItem('flashChapterid', data[0].id);
		navigate(-1);
	}
	const getChapterDetails = () => {
		if (data && data.length > 0) {
			const chapterId = data[0].id;
			const chapterArr = data[0].chapters;
			const getFilteredChapter = chapterArr.filter((each) => (each.value == chapterId));
			setChaptername(getFilteredChapter[0].label);
		}
	}

	return ( 
		<div className="cursorChange">
			<Row>
				<Col xs = {24} sm = {24} md = {24} lg ={24}> 
					<div className ='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items = {getCustomisedBreadcrumbs(constants.flashCardpageCrumbs, newCrumb)}
							className = 'bg-neutral-100 shadow-lg p-3 mb-3'/>
						</div>
						<Card 
							title = "ADD FLASHCARD"
							className = 'mainflashCard'
							hoverable extra = {
							<div className = "font-semibold chapterInfo flashDivColor"> {chaptername} </div>}>
							<Form 
								form = {form}
								onFinish = {submitFlashcardDetails}
								onFinishFailed = {
									() => {
										window.scrollTo(0, 0);
										success('error', constants.flashcard_added_failure, 'flashSuccess', messageApi)
									}
								}
								>
									<Card 
										hoverable 
										title = "Question"
										className = 'flashQuestioncard'
									>
										<Form.Item
											name = "question"
											dependencies = {['question']}
											rules = {
												[{
														required: true,
														message: constants.mandatoryField,
													},
													{
														validator: (_, value) =>
															value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
													},
												]
											}
										>
											<ReactQuill 
												theme = "snow"
												modules = {constants.ReactQuillModules}
												value = {flashQuestion}
												formats = {constants.ReactQuillFormats}
												onChange = {handleflashQuestion}
											/> 
										</Form.Item>
									</Card>
									<Card 
										hoverable 
										title = "Answer"
										className ='mt-3 flashAnswercard'
									>
										<Form.Item
											name = "answer"
											dependencies = {['answer']}
											rules = {
												[{
														required: true,
														message: constants.mandatoryField
													},
													{
														validator: (_, value) =>
															value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
													},
												]
											}
										>
											<ReactQuill 
											theme = "snow"
											modules = {constants.ReactQuillModules}
											value = {flashAnswer}
											formats = {constants.ReactQuillFormats}
											onChange = {handleflashAnswer}
											/> 
										</Form.Item>
									</Card> 
							<Form.Item>
								<Button 
									htmlType = 'submit'
									type = "primary"
									className = 'mt-3 font-semibold'> Submit </Button> 
								{contextHolder} 
								<Button 
									type = "primary"
									className = 'mt-3 ml-3 font-semibold'
									onClick = {redirectTolistpage}> Cancel </Button> 
							</Form.Item> 
							</Form> 
						</Card> 
					</div> 
				</Col>
			</Row> 
		</div>
				)
			}
export default AddFlashcard;
