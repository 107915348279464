import React, { useState, useEffect } from "react";
import { List, Button, Space, Divider, Card, Skeleton, Popconfirm, message, Select, Form, Result, Spin, Tooltip } from 'antd';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import constants from "../../../constants/constants";
import { COOKIE,getCookie } from "../../../services/cookie";
import {  fetchApi } from "../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { getColorClass } from "../../../functions/functions";
const Notes = () => {
  const location = useLocation();
	const navigate = useNavigate();
	const [loading,setLoading]=useState(false);
	const [initLoading,setInitLoading]=useState(false);
	const [notesList,setNotesList]=useState([]);
	const [displayNotesList,setdisplayNotesList]=useState([]);
	const [noteId,setNoteId]=useState(null);
	const [apiWait,setApiWait]=useState(false);
	const [loadingApi,setLoadingApi]=useState(false);
	const [chapterList,setChapterList]=useState([]);
	const [chapterId,setChapterId]=useState("0");
	const count = 9;
	document.addEventListener("contextmenu", (evt) => {
		evt.preventDefault();
	}, false);
	document.addEventListener("copy", (evt) => {
		evt.clipboardData.setData("text/plain", "");
		evt.preventDefault();
	}, false);
	//Button to display more notes
	const onLoadMore = () => {
		setLoading(true);
		let tempMainNotesList=notesList;
		let notesToAdd=tempMainNotesList.slice(displayNotesList.length,notesList.length>=displayNotesList.length+count?displayNotesList.length+count:notesList.length);
		let tempDisplayList=displayNotesList;
		let noteData = tempDisplayList.concat(notesToAdd);	 
		setdisplayNotesList(noteData);
		setLoading(false);
		window.dispatchEvent(new Event('resize'));
	};
	const loadMore =
			!initLoading && !loading ? (
				<div
					style={{
						textAlign: 'center',
						marginTop: 12,
						height: 32,
						lineHeight: '32px',
					}}
				>
					{notesList.length>displayNotesList.length?
						<Button type="primary" className="navy-btn" onClick={onLoadMore}>Load more</Button>
						:null}
				</div>
			) : null;
	useEffect(() => {
		setInitLoading(false);
		getChapterList();
		fetchNotesListData();
	}, []);
	//To fetch notes list data
	const fetchNotesListData = (chapter) => {
		setApiWait(false);
		let userId = getCookie(COOKIE.UserId);
    let payload={
      stud_id:userId
    }
    if(location?.state?.chapter_id||chapter&&chapter!=="0"){
      payload.chapter_id=location?.state?.chapter_id?location.state.chapter_id:chapter
    }
		fetchApi(`/get-notes`, "post",payload).then((res) => {
			setLoadingApi(true);
			if (res && res.data && res.data!==null&& res.data!==undefined) {
				setNotesList(res.data!==null?res.data:[]);
				let filterArrayData= res.data.slice(0,res.data.length>=count?count:res.data.length);
				setdisplayNotesList(filterArrayData);
				setApiWait(true);
				setLoadingApi(false);
			} else {
				setNotesList([]);
				setdisplayNotesList([]);
				setApiWait(true);
				setLoadingApi(false);
			}
		});
	};	
	//To delete note
	const deleteNote = () => {
		fetchApi(`/delete-note/${noteId}`, "delete").then((res) => {
			if (res && res.code===200) {
				const findNoteIndex=notesList.findIndex(a => a.note_id === noteId);
				let tempArrayNotes=notesList;
				tempArrayNotes.splice(findNoteIndex,1);
				setNotesList(tempArrayNotes);
				let newDisplayList=tempArrayNotes.slice(0,displayNotesList.length<=tempArrayNotes.length?displayNotesList.length:tempArrayNotes.length);
				setdisplayNotesList(newDisplayList);
				message.success(res.message);
			} else {
				message.error(res.message);
			}
		});
	};
	//Get chapter list
	const getChapterList = () =>{
		fetchApi(`/list-chapters`, "get").then((res) => {
			if (res && res.data && res.data!==null&& res.data!==undefined) {
				setChapterList(res.data);
			} else {
				setChapterList([])
			}
		});
	}
	//On chapter selection
	const onChangeChapters =(e)=>{
		setChapterId(e);
		fetchNotesListData(e);
	}
	return (
		<Spin spinning={loadingApi}>
			{location?.state?.chapter_id?
			<div
				style={{ backgroundColor: getColorClass(location?.state?.chapter_id, "colorcode") }}
				className='text-white w-12 lg:w-36 roboto-slab font-bold text-4xl flex justify-center items-end pb-3.5 absolute right-0 top-[-103px] h-36'
			>
				{location?.state?.chapter_id}
			</div>:null}
			<div className="container mx-auto">
			{location?.state?.chapter_id?
				<div className='flex justify-between items-center'>
				<div style={{ color: getColorClass(location?.state?.chapter_id, "colorcode") }} className='text-base lg:text-2xl underline underline-offset-4 font-extrabold uppercase w-4/5 lg:w-auto'>{location?.state?.chapter_name}</div>
				</div>:null}
			<div className="flex flex-wrap justify-between items-center mt-3 gap-2">
				<Space wrap>
					<h1 className="font-bold text-xl">{constants.MyNotes}</h1>
					{location?.state?.chapter_id?null:
						<Form layout="inline" /* form={form} */>
							<Form.Item name={"chapter"}>
								<Select  
									showSearch
									onChange={onChangeChapters} 
									className='!w-[450px]' 
									placeholder="Chapter"
									optionFilterProp="children"
								>
									{chapterList.length > 0 ? chapterList?.map((listChapter) => (
										<Option  key={listChapter.chapter_id} value={listChapter.chapter_id} >
											{listChapter.chapter}
										</Option>
									 )) : null} 
									 	<Option key="0" value="0">All Chapters</Option>
								</Select>
							</Form.Item>
						</Form>
						}
				</Space>
				<div>
					{location?.state?.chapter_id?
					<>
						<Button onClick={() => {
							const state = {
								chapter_id: location?.state?.chapter_id
							};
							navigate('/chapter-resources', { state, replace: true });
						}}
							className='rounded-lg bg-blue-700 text-white'
							icon={<ArrowLeftOutlined />}>{constants.BackToChapter}
						</Button>
					</>:
					<>
						<Button onClick={() => navigate('/dashboard')} icon={<ArrowLeftOutlined />} className='rounded-lg bg-blue-700 text-white'>{constants.BckDashbd}</Button>
					</>
				}
				</div>
			</div>
			<Divider className="my-3"/>
			{apiWait?
				<div>
					{ displayNotesList.length>0?
						<div>
							<List
								dataSource={displayNotesList}
								loadMore={loadMore}
								renderItem={item => (
									<List.Item >
										<Skeleton 
											loading={false} 
											active>
											<Card className="bg-amber-100 border-amber-200 w-full" size="small" bodyStyle={{backgroundColor:'rgb(255 251 235)'}}
												title={
													<>
													<Space>
														{location?.state?.chapter_id|| chapterId!=="0"?null:
															<Tooltip title={item.chapter_name}>
																{
																item.score_card.chapter.length>23?
																<strong>{item.score_card.chapter.slice(0,20)}...</strong>
																:<strong>{item.score_card.chapter}</strong>
																}
															</Tooltip>
														}
														<strong>Attempt #{item.score_card.attempt!==null?item.score_card.attempt:0} - </strong>
														<strong>{item.dt_created}</strong> 
														{Number(item.is_review)===1?
															<i >[During Test Review]</i>
																:<i >[During Test]</i>
															}
													</Space>	
														<span className="relative left-3.5">
															<Popconfirm
																title="Are you sure to delete this note?"
																onConfirm={deleteNote}
																okText="Yes"
																cancelText="No"
																placement="left"
															>
																<Button type="text" danger size="small" icon={<DeleteOutlined />} onClick={() => setNoteId(item.note_id)}/>
															</Popconfirm>
														</span>
													</>
												}>
												<div>
													{item.notes}
												</div>
											</Card>
										</Skeleton>
									</List.Item>
								)}
							/>
						</div>:
						<div>
							<Result
                title={constants.NoNotesCreated}
								subTitle={constants.NoNotesCreatedSub}
              />
						</div> 
					}
				</div>
				:null}
		</div>
		</Spin>
	);
};
export default Notes;
