import React, { useRef, useEffect, useState } from 'react';
import { Row, Select, Card, Divider, Col, Progress, Button, Carousel, FloatButton, Skeleton, Typography, Form, Modal, Spin, Radio, Input, Space, message, Layout, Avatar } from 'antd';
import { StarOutlined, InfoCircleOutlined, RightCircleFilled, LeftCircleFilled, BarChartOutlined, MessageOutlined, ReadOutlined, SolutionOutlined, UserOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { fetchApi } from '../../../services/api';
import { Chart } from "react-google-charts";
import { useNavigate, useLocation } from 'react-router-dom';
import constants from '../../../constants/constants';
import { COOKIE, createCookie, deleteCookie, getCookie } from '../../../services/cookie';
import axios from "axios";
import {getColorClass, success} from '../../../functions/functions';
import PhoneInput from "react-phone-input-2";
import Footers from '../../../Footers';
const { Text } = Typography;
const { TextArea } = Input;
const { Content, Header } = Layout;
const Dashboard = () => {
	const [form] = Form.useForm();
	const [messageApi, contextHolder] = message.useMessage();
	const carouselRef = useRef();
  let location = useLocation();
	const navigate = useNavigate();
	const handleNext = () => carouselRef.current.next();
	const handlePrev = () => carouselRef.current.prev();
	const [dashboardData, setDashboardData] = useState({});
	const colorCode = ['#7F1D1D', '#155E75', '#6B21A8', '#15803D'];
	const [testPerCard, setTestPerCard] = useState({})
	const [graphData, setGraphData] = useState([]);
	const [showCarouselbutton, setShowCarouselbutton] = useState(false);
	const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
	const [otherMessage, setOtherMessage] = useState(false);
	const [firstSubmit,setFirstSubmit] = useState(false);
	const [studentispresent,setStudentIsPresent] = useState();
  const [testPausedCount,setTestPausedCount]=useState(0);
  const [updateprofileModalVisible,setUpdateProfileModalVisible] = useState(false);

  //UpdateProfile states
  const [otheruniversity, setOtherUniversity] = useState(false);
  const [universityop, setUniversityop] = useState(false);
  const [stateop, setStateop] = useState(true);
  const [otherrole, setOtherRole] = useState(false);
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [emailid, setEmailId] = useState('');
  const [roleoption, setRoleOption] = useState();
  const [universityoption, setUniversityOption] = useState('');
  const [statevalue, setStateValue] = useState('');
  const [countryvalue, setCountryValue] = useState('238');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [countrycode, setCountrycode] = useState('US');
  const [countryObject, setCountryObject] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState()
  const [name, setName] = useState(getCookie(COOKIE.FirstName) ? {firstName:getCookie(COOKIE.FirstName),lastName:getCookie(COOKIE.LastName)}: null);

	const data = [
		["Element", "Chapter 1", { role: "style" }],
		["Ch. 1 (#1)", 8.94, "#2F8548"],
		["Ch. 1 (#2)", 10.49, "#0F455D"],
		["Ch. 2 (#1)", 19.3, "#723E85"],
	];
	const userId="14";

  var chartOptions = {
    chartArea: { width: '100%' },
    legend: { position: 'none' },
    vAxes: [
      {
        minValue: 0,
        ticks: [0 ,20, 40, 60, 80, 100],
      },
    ],
  }
  useEffect(() => {
    if(process.env.REACT_APP_ENV === "qa" || process.env.REACT_APP_ENV === "dev") {
      createCookie(COOKIE.UserId, userId, null, "/");
      getDashboardData();
    } else {
      let userId = sessionStorage.getItem("UserIdSession");
      if (userId) {
        getDashboardData();
        getStudentDetails();
      } else {
        fetchUrlParameters();
      }
    }
    getCountries();
  }, []);
  const getCountries = () => {
    fetchApi(`/countries`, 'get').then((res) => {
      if (res?.data) {
        setCountryObject(res?.data);
        const countryNames = res?.data.map((obj) => (obj.country_name))
        setCountries(countryNames);
        const defaultCountry = res?.data.filter((obj) => (obj.country_id == '238'));
        setDefaultCountry(defaultCountry[0].country_id);
      }
    })
  }

  const getStudentDetails = () => {
    let userId = getCookie(COOKIE.UserId);
    fetchApi(`/get-student-data/${userId}`, 'get').then((res) => {
      if (res?.data) {
        setFirstName(res?.data[0]?.stud_firstname);
        setLastName(res?.data[0]?.stud_lastname);
        setEmailId(res?.data[0]?.stud_email);
      }
      // if (res?.data[0]?.alternate_stud_email == '' || res?.data[0]?.alternate_stud_email == null) {
      //   setStudentPresent(false);
      //   navigate('/update-profile');
      // } else {
      //   setStudentPresent(true);
      //   navigate('/dashboard');
      // }
    })
  }

  const fetchUrlParameters=()=>{
		let queryString = window.location.href;
		var tokenId = queryString.substring(queryString.lastIndexOf('?') + 1);
		var tempValueHref=queryString.substring(queryString.lastIndexOf('/') + 1);
    getToken(tokenId);
  };
  const getToken=(tokenId)=>{
    axios({
      method: "GET",
      url: `${constants.ApiUrl}`+`/get-token/`+`${tokenId}`,
    })
      .then((res) => {
        if(res?.data?.code!==400 ){
          let profileName = {};
          profileName.firstName = res?.data?.data?.stud_firstname;
          profileName.lastName = res?.data?.data?.stud_lastname;
          setName(profileName);
          createCookie(COOKIE.UserId,res?.data?.data?.stud_id, null, "/");
          createCookie(COOKIE.Token,res?.data?.data?.token,null,"/");
          createCookie(COOKIE.FirstName,res?.data?.data?.stud_firstname,null,"/");
          createCookie(COOKIE.LastName,res?.data?.data?.stud_lastname,null,"/");
          sessionStorage.setItem("UserIdSession",res?.data?.data?.stud_id);
          getStudentDetails();
          getDashboardData();
        }else{
          setName({});
          message.error("Invalid token");
          deleteCookie(COOKIE.UserId, "/");
          deleteCookie(COOKIE.Token,"/");
          deleteCookie(COOKIE.FirstName,"/");
          deleteCookie(COOKIE.LastName,"/");
          if(process.env.REACT_APP_ENV==="staging"){
            window.location.href = constants.StagingWebsiteURL;
          }else{
            window.location.href = constants.LiveWebsiteURL;
          }
        }
      })
      .catch((err) => {
        setName({});
        deleteCookie(COOKIE.UserId, "/");
        deleteCookie(COOKIE.Token,"/");
        deleteCookie(COOKIE.FirstName,"/");
        deleteCookie(COOKIE.LastName,"/");
        if(process.env.REACT_APP_ENV==="staging"){
          window.location.href = constants.StagingWebsiteURL;
        }else{
          window.location.href = constants.Li;
        }
      });
  }
  const getDashboardData = () => {
    let userIdCookieValue = getCookie(COOKIE.UserId);
    if(process.env.REACT_APP_ENV==="qa"||process.env.REACT_APP_ENV==="dev"){
      userIdCookieValue=userId;
    }else{
      userIdCookieValue = getCookie(COOKIE.UserId);
    }
    fetchApi(`/dashboard/${userIdCookieValue}`, "get").then((res) => {
      if (res?.code === 200) {
        if (res?.data?.latest_3_scores?.length > 0) {
          const gfdata = res?.data?.latest_3_scores;
          gfdata[0][gfdata[0].length - 1] = { role: "style" };
          setGraphData(gfdata);
        }
        let profileName = {};
        profileName.firstName = res?.data?.first_name;
        profileName.lastName = res?.data?.last_name;
        setName(profileName);
        setTestPausedCount(Number(res?.data?.test_paused_count));
        let testpercard = calculatePercent(res?.data?.tests?.total, res?.data?.tests?.completed);
        if(Number(res?.data?.tests?.completed) === testpercard?.fifty || Number(res?.data?.tests?.completed) === testpercard.twentyfive || Number(res?.data?.tests?.completed) === testpercard.seventyfive || Number(res?.data?.tests?.completed) === res?.data?.tests?.total){
          setShowCarouselbutton(true);
        }
        if((Number(res?.data?.test_paused_count)>0)){
          setShowCarouselbutton(true);
        }
        setTestPerCard(testpercard);
        setDashboardData(res.data);
        if (res?.data?.is_present === false) {
          setStudentIsPresent(false);
          setUpdateProfileModalVisible(true);
        //navigate('/update-profile')
        }else{
          setStudentIsPresent(true);
          setUpdateProfileModalVisible(false);
        // navigate('/dashboard',{ replace: true })
        }
        if (res?.data?.is_overall_submitted == true) {
          setFirstSubmit(true);
        }else{
          setFirstSubmit(false);
        }
      } else {
        setDashboardData({});
      }
    })
  };
  const testScore = () => {
    navigate('/compare-score');
  };
  const feedback = () => {
    setIsFeedbackModalVisible(true);
    setOtherMessage(false);
  };
  const myNotes = () => {
    navigate('/notes')
  };

  const routeToChapter = (chapter_id) => {
    const state = { 
      chapter_id: chapter_id
    };
    navigate('/chapter-resources', { state });
  };
  const calculatePercent = (typetotal, completed) => {
    return {
      twentyfive: completed == Math.ceil(0.25 * typetotal) ? Math.ceil(0.25 * typetotal) : Math.floor(0.25 * typetotal),
      fifty: completed == Math.ceil(0.50 * typetotal) ? Math.ceil(0.50 * typetotal) : Math.floor(0.50 * typetotal),
      seventyfive: completed == Math.ceil(0.75 * typetotal) ? Math.ceil(0.75 * typetotal) : Math.floor(0.75 * typetotal),
    };
  }
  const handleFeedbackCancel = () => {
    form.resetFields();
    setIsFeedbackModalVisible(false);
  };
  const onChangeFeedbackQ2 = e => {
    if (e.target.value === 7) {
      setOtherMessage(true);
    }
    else {
      setOtherMessage(false);
    }
  };
  const handleOtherFeedBackValidation = (rule, value, callback) => {
    try {
      if (value) {
        if (value.trim() === "") {
          throw new Error(constants.FeedbackMessageError);
        }
      } else {
        throw new Error(constants.FeedbackMessageError);
      }
      callback(); // < -- this
    } catch (err) {
      callback(err);
    }
  };
  const submitFeedbackform = (values)=>{
    let user = getCookie(COOKIE.UserId);
    let feedbackformPayload = null;
    let commentFeedbackFormPayload = null;

    otherMessage ? 
    feedbackformPayload ={
      stud_id: user,
      is_book: values.bookreq,
      product_source: values.hearabtbook,
      other_source: 'Other',
      product_rating: values.prodrat,
      test_rating: values.chaltest,
      overall_comments: values.feedbackcomment !== '' ? values.feedbackcomment : '',
      first_submit: true,
      other_feedback: values.otherFeedback,
    }
    :
      feedbackformPayload={
        stud_id: user,
        is_book: values.bookreq,
        product_source: values.hearabtbook,
        // other_source: values.otherFeedback,
        product_rating: values.prodrat,
        test_rating: values.chaltest,
        overall_comments: values.feedbackcomment !== '' ? values.feedbackcomment : '',
        first_submit: true,
      }

    commentFeedbackFormPayload = {
      stud_id: user,
      overall_comments: values.commentInfo,
      first_submit: false,
    }

    const feedbackFormPayload = firstSubmit ? commentFeedbackFormPayload : feedbackformPayload;
    fetchApi(`/feedback`, 'post', feedbackFormPayload).then((res) => {
      if (res.code === 200) {
        handleFeedbackCancel();
        form.resetFields();
        success('success', constants.feedbackSentSuccess, 'afterQaddsuccess', messageApi);
      } else {
        form.resetFields();
      }

      if (res.is_overall_submitted == true){
        setFirstSubmit(true);
      }
    })
  }
  const feedbackModal = () => {
    return <Modal footer={false} title={<span>{constants.FeedbackHeader}</span>} centered open={isFeedbackModalVisible} onCancel={handleFeedbackCancel}
    >
      {/* <Spin size="large"> */}
      {!firstSubmit
        ?
        <Form name="feedback_form" form={form} autoComplete="off" layout="vertical" onFinish={submitFeedbackform}>
          <>
            <Form.Item
              label={constants.FeedbackQ1}
              name="bookreq"
              rules={[
                {
                  required: true,
                  message: constants.FeedbackOptionError,
                },
              ]}
            >
              <div className="pl-3">
                <Radio.Group
                >
                  <Radio value={0}>{constants.BookReq1}</Radio>
                  <Radio value={1}>{constants.BookReq2}</Radio>
                </Radio.Group>
              </div>
            </Form.Item>
            <Form.Item
              label={constants.FeedbackQ2}
              name="hearabtbook"
              rules={[
                {
                  required: true,
                  message: constants.FeedbackOptionError,
                },
              ]}
            >
              <div className="pl-3">
                <Radio.Group onChange={onChangeFeedbackQ2}>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} lg={12} className="pl-0"><Radio value={0}>{constants.HearAbtBookOption8}</Radio></Col>
                    <Col xs={24} lg={12} className="pl-0"><Radio value={1}>{constants.HearAbtBookOption1}</Radio></Col>
                    <Col xs={24} lg={12} className="pl-0"><Radio value={2}>{constants.HearAbtBookOption2}</Radio></Col>
                    <Col xs={24} lg={12} className="pl-0"><Radio value={3}>{constants.HearAbtBookOption3}</Radio></Col>
                    <Col xs={24} lg={12} className="pl-0"><Radio value={4}>{constants.HearAbtBookOption4}</Radio></Col>
                    <Col xs={24} lg={12} className="pl-0"><Radio value={5}>{constants.HearAbtBookOption5}</Radio></Col>
                    <Col xs={24} lg={12} className="pl-0"><Radio value={6}>{constants.HearAbtBookOption6}</Radio></Col>
                    <Col xs={24} lg={12} className="pl-0"><Radio value={7}>{constants.HearAbtBookOption7}</Radio></Col>
                  </Row>
                </Radio.Group>
              </div>
            </Form.Item>
            {otherMessage ?
              <Form.Item name="otherFeedback"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  { validator: handleOtherFeedBackValidation },
                ]}>
                <Input className="w-100" />
              </Form.Item>
              : null
            }
            <Form.Item
              label={<span>{constants.FeedbackQ3}</span>}
              name="chaltest"
              rules={[
                {
                  required: true,
                  message: constants.FeedbackOptionError,
                },
              ]}
            >
              <div className="pl-3">
                <Radio.Group
                >
                  <Radio value={0}>{constants.ChalTestOpt1}</Radio>
                  <Radio value={1}>{constants.ChalTestOpt2}</Radio>
                  <Radio value={2}>{constants.ChalTestOpt3}</Radio>
                </Radio.Group>
              </div>
            </Form.Item>
            <Form.Item
              label={<span className="pl-3">{constants.FeedbackQ4}</span>}
              name="prodrat"
            >
              <div className="pl-3">
                <Radio.Group
                >
                  <Radio value={0}>{constants.RatingOpt1}</Radio>
                  <Radio value={1}>{constants.RatingOpt2}</Radio>
                  <Radio value={2}>{constants.RatingOpt3}</Radio>
                  <Radio value={3}>{constants.RatingOpt4}</Radio>
                </Radio.Group>
              </div>
            </Form.Item>
          </>
          <Form.Item name="feedbackcomment"
            className=""
          >
            <TextArea placeholder="Comments..." allowClear
            />
          </Form.Item>
          <div className='text-right'>
            <Space>
              <Button
                key="submit"
                type="primary"
                className="bg-green-800"
                htmlType="submit"
              >
                Submit
              </Button>
              <Button onClick={handleFeedbackCancel} className="text-white border border-blue-600 bg-blue-600" >
                Cancel
              </Button>
            </Space>
          </div>
        </Form>
          :
        <Form form={form} onFinish={submitFeedbackform}>
          <Form.Item name="commentInfo" 
            rules={
              [{
                required: true,
                message: constants.EmptyCommentError
              },
              {
                validator: (_, value) =>
                  value != '' ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
              },
              ]
            }
          >
            <TextArea placeholder="Comments..." allowClear
            />
          </Form.Item>
          <Form.Item className="relative">
            <div className="absolute right-0">
              <Button
                type="primary"
                htmlType="submit"
                className='font-semibold'
              // disabled={formError}
              >Submit</Button>
              <Button className='ml-2 font-semibold bg-blue-600 text-white' onClick={handleFeedbackCancel}>Cancel</Button>
            </div>
          </Form.Item>
        </Form>
      }
      {/* </Spin> */}
    </Modal>
  };
  const getTestPerCard = () => {
    if (Number(dashboardData?.tests?.completed) === testPerCard.twentyfive) {
      return <Row>
        <Col xs={12} sm={10} md={5} lg={10} xl={5}>
          <StarOutlined className='text-yellow-400 text-7xl' />
        </Col>
        <Col xs={12} sm={14} md={19} lg={14} xl={19}>
          <div>
            <div className='font-bold text-base'>{constants.CardTitle[25]}</div>
            <div className='font-bold mt-3'>{constants.OptionPercentChapterTest[25]}</div>
          </div>
        </Col>
      </Row>
    } else if (Number(dashboardData?.tests?.completed) === testPerCard.fifty) {
      return <Row>
        <Col xs={12} sm={10} md={5} lg={10} xl={5}>
          <StarOutlined className='text-yellow-400 text-7xl' />
        </Col>
        <Col xs={12} sm={14} md={19} lg={14} xl={19}>
          <div>
            <div className='font-bold text-base'>{constants.CardTitle[50]}</div>
            <div className='font-bold mt-3'>{constants.OptionPercentChapterTest[50]}</div>
          </div>
        </Col>
      </Row>
    } else if (Number(dashboardData?.tests?.completed) === testPerCard.seventyfive) {
      return <Row>
        <Col xs={12} sm={10} md={5} lg={10} xl={5}>
          <StarOutlined className='text-yellow-400 text-7xl' />
        </Col>
        <Col xs={12} sm={14} md={19} lg={14} xl={19}>
          <div>
            <div className='font-bold text-base'>{constants.CardTitle[75]}</div>
            <div className='font-bold mt-3'>{constants.OptionPercentChapterTest[75]}</div>
          </div>
        </Col>
      </Row>
    } else if (Number(dashboardData?.tests?.completed) === Number(dashboardData?.tests?.total)) {
      return <Row>
        <Col xs={12} sm={10} md={5} lg={10} xl={5}>
          <StarOutlined className='text-yellow-400 text-7xl' />
        </Col>
        <Col xs={12} sm={14} md={19} lg={14} xl={19}>
          <div>
            <div className='font-bold text-base'>{constants.CardTitle[100]}</div>
            <div className='font-bold mt-3'>{constants.OptionPercentChapterTest[100]}</div>
          </div>
        </Col>
      </Row>
    }
  };

  useEffect(() => {
    roleoption && roleoption == '3' ? setOtherRole(true) : setOtherRole(false);
    if (countrycode) {
      getStates();
      getUniversities();
    }
  }, [roleoption, countrycode])

  const getStates = () => {
    fetchApi(`/states?country_code=${countrycode}`, 'get').then((res) => {
      if (res?.data) {
        setStates(res?.data);
      }
    })
  }

  const getRolevalue = (value) => {
    setRoleOption(value);
  }
  const getStateValue = (value) => {
    setStateValue(value);
  }
  const getUniversities = () => {
    fetchApi(`/universities?country_code=${countrycode}`, 'get').then((res) => {
      if (res?.data) {
        setUniversities(res?.data);
        Object.entries(res.data).forEach(([key, value]) => {
          if (key == "Alaska") {
            for (let i = 0; i < value?.universities.length; i++) {
              if (value?.universities?.region_code === undefined) {
                value?.universities?.splice(i, 1);
              }
            }
          }
        });
      } else {
        setUniversities([]);
      }
    })
  }
  const getCountryValue = (value, option) => {
    setCountryValue(value);
    const countryCode = countryObject.filter((eachCountry, index) => (eachCountry.country_id == value));
    setCountrycode(countryCode[0].country_code)
    if (option.children !== 'United States') {
      setUniversityop(true);
      setStateop(true);
    } else {
      setUniversityop(false);
      setStateop(true);
    }
  }
  const getUniversityvalue = (value, option) => {
    setStateValue(option.props.region);
    setUniversityOption(value);
    if (option.children === 'Other') {
      setUniversityOption(null);
      setOtherUniversity(true);
    } else {
      setOtherUniversity(false);
    }
  }
  //UpdateProfile form validations
  const validateEmail = (_rule, value, callback) => {
    try {
      if (value) {
        const emailReg = /^([a-zA-Z0-9_])+([^#<>(){}|![\]\\,;:\s@"-])*@([a-zA-Z]+\.[a-zA-Z]{2,3})$/;
        if (emailReg.test(value) === false) {
          throw new Error(`${value} is not valid!`);
        }
        if (value === emailid) {
          throw new Error(constants.duplicateIdError);
        }
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };
  const validatePhone = (_rule, value, callback) => {
    try {
      if (value != '') {
        let numberValue = /^[0-9]+$/;
        if (numberValue.test(value) === false) {
          throw new Error('');
        }
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };
 
  //Submit UpdateProfile form info
  const updateStudentProfileData = (values) => {
    let user = getCookie(COOKIE.UserId);
    let withoutUniversityPayload = null;
    let withUniversityPayload = null;
    withUniversityPayload = {
      stud_id: user,
      alternate_email: values.alternatemail,
      contact_no: values.contactnumber,
      country_id: values.country,
      state_id: statevalue === '-' ? null : statevalue,
      university_id: universityoption === 'NA' ? null : universityoption,
      //university_id: universityoption === 'NA' ? 0 : universityoption === 0 ? null : universityoption,
      other_university: universityoption === 'NA' ? 'NA' : values.otherUniversity,
      role: values.role,
      occupation: values.otherRole,
    }
    withoutUniversityPayload = {
      stud_id: user,
      alternate_email: values.alternatemail,
      contact_no: values.contactnumber,
      country_id: values.country,
      role: values.role,
      occupation: values.otherRole
    }
    const newPayload = !universityop ? withUniversityPayload : withoutUniversityPayload;
    fetchApi(`/update-student-profile`, 'put', newPayload).then((res) => {
      if (res?.code == 200) {
        success('success', constants.sucessfulProfileupdation, 'afterQaddsuccess', messageApi);
        setUpdateProfileModalVisible(false);
        navigate('/dashboard');
      } else {
        success('error', constants.profileUpdationError, 'afterQaddsuccess', messageApi);
        setUpdateProfileModalVisible(true);
      }
    })

    console.log(values);
  }

  const handleUpdateProfileCancel = () => {
    form.resetFields();
  };

  const filterOption = (input, option) =>
    (option?.children.toLowerCase().includes(input.toLowerCase()));

  //Display updateProfile form inside Modal
 const displayUpdateProfileForm = () => {
   return (
     <Modal open={updateprofileModalVisible} footer={null} className='updateProfileformModal'>
       <div className="flex justify-center mt-5">
         <Row>
           <Col style={{width:'520px'}}>
             <Card className='relative bg-sky-100 border-slate-300 border-2 w-full'
               hoverable>
               <div className="flex flex-col justify-center items-center relative top_position"><div className=""><UserOutlined className="relative student_user bg-slate-300 width-70 text-5xl rounded-full text-white !inline-block" /></div><div className="font-bold text-base">{`${firstname}  ${lastname}`.toUpperCase()}</div><div className="font-semibold text-sm">{emailid}</div></div>
               <Divider className='-mt-12' />
               <div class="text-center text-base font-semibold relative -top-3">Update your profile</div>

               <Form form={form} layout="vertical" className='updateProfileForm' onFinish={updateStudentProfileData} initialValues={
                 {
                   country: countryvalue,
                 }
               } autocomplete="off">
                 <Form.Item label="Alternate Email"
                   name="alternatemail"
                   rules={[
                     {
                       type: 'email',
                       message: '',
                     },
                     {
                       required: true,
                       message: 'Please input your E-mail!',
                     },
                      { validator: validateEmail },
                   ]}
                   tooltip={{
                     title: constants.alternateEmailText,
                     icon: <InfoCircleOutlined />,
                   }}
                 >
                   <Input></Input>
                 </Form.Item>
                 <Form.Item label="Contact Number"
                   name="contactnumber"
                   rules={[
                    {
                      required: true,
                      message: constants.requiredContactNumber,
                    },
                    { validator: validatePhone },
                   ]}
                 >
                   <PhoneInput country={'us'} placeholder="1(999)123-4567" className="contactInput" />
                 </Form.Item>
                 <Form.Item label="Role"
                   name="role"
                   rules={[
                     {
                       required: true,
                       message: constants.requiredRole,
                     },
                   ]}
                 >
                   <Select onChange={getRolevalue}>
                     {constants.roleOptions.map((role, index) => (
                       <Select.Option key={role} value={index}>
                         {role}
                       </Select.Option>
                     ))}
                   </Select>
                 </Form.Item>
                 {otherrole ?
                   <Form.Item label="Occupation"
                     name="otherRole"
                     rules={[
                       {
                         required: true,
                         message: constants.requiredOccupation,
                       },
                     ]}
                   >
                     <Input></Input>
                   </Form.Item>
                   :
                   <div></div>
                 }
                 <Form.Item label="Country"
                   name="country"
                   rules={[
                     {
                       required: true,
                       message: constants.requiredCountry,
                     },
                   ]}
                 >
                   <Select onChange={getCountryValue} showSearch filterOption={filterOption}>
                     {countryObject.map((country) => (
                       <Select.Option key={country?.country_id} value={country?.country_id}>
                         {country?.country_name}
                       </Select.Option>
                     ))}
                   </Select>
                 </Form.Item>
               
                 {!stateop
                   ?
                   <Form.Item label="State"
                     name="state"
                     rules={[
                       {
                         required: !stateop ? true : false,
                         message: constants.requiredState,
                       },
                     ]}
                   >
                     <Select onChange={getStateValue}  showSearch filterOption={filterOption}>
                       {states.map((state, index) => (
                         <Select.Option key={index} value={state?.region_id}>
                           {state?.region_name}
                         </Select.Option>
                       ))}
                     </Select>
                   </Form.Item>
                   :
                   <div></div>
                 }
                 {!universityop
                   ?
                   <Form.Item label="University"
                     name="university"
                     rules={[
                       {
                         required:  true ,
                         message: constants.requiredUniversity,
                       },
                     ]}
                   >
                     <Select 
                        onChange={getUniversityvalue} 
                        showSearch
                        filterOption={(input, option) => {
                         if (option.children) {
                           return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                         } else if (option.label) {
                           return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                         }
                       }}
                      >
                       {universities &&
                         Object.entries(universities).map(([key, value]) => (
                           <Select.OptGroup label={key} key={key}>
                             {value && value.universities && value.universities.length > 0 &&
                               value.universities.map((uni) => (
                                 <Select.Option region={uni.region_id} value={uni.university_id} key={uni.university_id}>{uni.university_name + (uni.city ? "," + uni.city : "")}</Select.Option>
                               ))}
                           </Select.OptGroup>
                         ))}
                     </Select>
                   </Form.Item>
                   :
                   <div></div>
                 }
                 
                 {otheruniversity ?
                   <Form.Item label="University Name"
                     name="otherUniversity"
                     rules={[
                       {
                         required: true,
                         message: constants.requiredUniversity,
                       },
                     ]}
                   >
                     <Input></Input>
                   </Form.Item>
                   :
                   <div></div>
                 }
                 <Form.Item className="relative"
                 >
                   <div className="absolute right-0">
                     <Button
                       type="primary"
                       htmlType="submit"
                       className='font-semibold'>Update</Button>
                     <Button className='ml-2 font-semibold bg-blue-600 text-white' onClick={handleUpdateProfileCancel}>Reset</Button>
                   </div>
                 </Form.Item>
               </Form>

             </Card>
             {contextHolder}
           </Col>
         </Row>

       </div>
    </Modal>
   )
 }
  return (
    <Layout className="!bg-white">
      <div className={`absolute h-24 w-full z-0 opacity-75 bg-gradient-to-b ${getColorClass(location?.state?.chapter_id ? location?.state?.chapter_id : "1", "gradientclass")}`}></div>
      <Header id='top' className='!leading-4 !bg-white mt-4 z-10 px-2 lg:px-0'>
        <div className='flex justify-between items-center h-full container mx-auto'>
          <Space direction='vertical' className='cursor-pointer' size={0} onClick={() => navigate('/dashboard')}>
            <span className='block lg:hidden text-xl roboto-slab font-normal'>FAPSLH</span>
            <span className='hidden lg:block text-xl roboto-slab font-normal'>{constants.BookName}</span>
            <span className='text-rose-800 font-bold text-xs lg:text-base'>{constants.Author}</span>
          </Space>
          <Space>
            <Avatar className='bg-rose-800 text-white'>{name?.firstName ? name?.firstName?.substring(0, 1) : ""}</Avatar>
            <span className='flex items-center'>{name?.firstName ? name?.firstName + " " + name?.lastName : ""}</span>
          </Space>
        </div>
      </Header>
      <Content className="min-h-[82vh] py-6 px-2 lg:px-0">
        <div className='container mx-auto'>
          <Row gutter={[{
            xs: 0,
            sm: 0,
            md: 24,
            lg: 24,
            xl: 12
          }, {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 16,
            xl: 24
          }]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className='rounded-lg border border-red-900 bg-pink-100 p-3 h-full'>
                <div className='pb-3 font-semibold'>My Progress</div>
                {parseInt(dashboardData?.flashcards?.completed) > 0
                  || parseInt(dashboardData?.tests?.completed) > 0
                  || parseInt(dashboardData?.tutorials?.completed) > 0
                  ?
                  <div className='grid grid-cols-3 place-content-center place-items-center gap-6'>
                    <Progress
                      strokeColor="#1677ff"
                      type="circle"
                      format={() => <div className='text-center text-base font-normal'>
                        {dashboardData?.tests?.completed}/{dashboardData?.tests?.total}<br />Tests
                      </div>}
                      size={100}
                      trailColor="#FAF9F6"
                      percent={(Number(dashboardData?.tests?.completed) / Number(dashboardData?.tests?.total)) * 100}
                    />
                    <Progress
                      strokeColor="#1677ff"
                      type="circle"
                      format={() => <div className='text-center text-base font-normal'>
                        {dashboardData?.tutorials?.completed}/{dashboardData?.tutorials?.total}<br />Tutorials
                      </div>}
                      percent={(Number(dashboardData?.tutorials?.completed) / Number(dashboardData?.tutorials?.total)) * 100}
                      size={100}
                      trailColor="#FAF9F6"
                    />
                    <Progress
                      strokeColor="#1677ff"
                      type="circle"
                      format={() => <div className='text-center text-base font-normal'>
                        {dashboardData?.flashcards?.completed}/{dashboardData?.flashcards?.total}<br />Flashcards
                      </div>}
                      percent={(Number(dashboardData?.flashcards?.completed) / Number(dashboardData?.flashcards?.total)) * 100}
                      size={100}
                      trailColor="#FAF9F6"
                    />
                  </div> :
                  <div>
                    {constants.MyProgressNullMsg}
                  </div>
                }
              </div>
              {contextHolder}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className='flex justify-between items-center smart-crds pr-1.5'>
                {showCarouselbutton ? <Button onClick={handlePrev} type='text' icon={<LeftCircleFilled />} size='large' /> : null}
                <Carousel dots={false} ref={carouselRef} autoplay autoplaySpeed="70">
                  {Number(dashboardData?.tests?.completed) === testPerCard.twentyfive ||
                    Number(dashboardData?.tests?.completed) === testPerCard.fifty ||
                    Number(dashboardData?.tests?.completed) === testPerCard.seventyfive ||
                    Number(dashboardData?.tests?.completed) === Number(dashboardData?.tests?.total) ? (
                    <div>
                      <Card className='border border-solid border-purple-800 bg-purple-200 w-full h-40'>
                        {getTestPerCard()}
                      </Card>
                    </div>
                  ) : null}
                  <div>
                    <Card className='border border-solid border-purple-800 bg-purple-200 w-full h-40'>
                      <div className="uppercase font-bold pb-2">{constants.FeedbackTitle}</div>
                      <Row gutter={[0, 18]} className="w-full">
                        <Col xs={12} sm={10} md={5} lg={10} xl={5}>
                          <SolutionOutlined className='purple text-7xl' />
                        </Col>
                        <Col xs={12} sm={14} md={19} lg={14} xl={19} className='mt-1.5'>
                          <Text><a onClick={feedback} >Click here</a> {constants.FeedbackMessage}</Text>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                  {testPausedCount > 0 ?
                    <div>
                      <Card className='border border-solid border-purple-800 bg-purple-200 w-full h-40'>
                        <div className="uppercase font-bold pb-2">{constants.pausedTests}</div>
                        <Row gutter={[0, 18]} className="w-full">
                          <Col xs={12} sm={14} md={19} lg={14} xl={19} className='mt-1.5'>
                            <Text>You have {testPausedCount} {testPausedCount === 1 ? "test paused." : "tests paused."} </Text>
                            <Text><a onClick={() => testScore()}>Click here</a> {testPausedCount === 1 ? constants.pausedCard3 : constants.pausedCard2}</Text>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                    : null}
                </Carousel>
                {showCarouselbutton ? <Button onClick={handleNext} type="text" icon={<RightCircleFilled />} size='large' /> : null}
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className='w-full border border-gray-300 leading-8 rounded-lg p-3'>
                <div className='text-center font-bold text-base leading-6 mb-1'>{constants.DashboardChaptHeader}</div>
                <Space direction="vertical" className='w-full'>
                  {dashboardData?.chapters?.map((chapterDetails, index) => {
                    return <div
                      style={{ borderColor: colorCode[index % colorCode.length] }}
                      className={`border leading-8 rounded-lg overflow-hidden flex justify-between cursor-pointer`}
                      key={chapterDetails?.chapter_id}
                      onClick={() => routeToChapter(chapterDetails?.chapter_id)}
                    >
                      <div className='flex gap-2'>
                        <div style={{ backgroundColor: colorCode[index % colorCode.length] }} className={`text-center text-white w-9`}>{chapterDetails?.chapter_id}</div>
                        <div className='font-semibold'>{chapterDetails?.chapter_title}</div>
                      </div>
                      <span className='pr-3'>{'>>'}</span>
                    </div>
                  })}
                </Space>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={6}>
                  <Card hoverable className='w-full h-full bg-orange-200 text-center font-semibold' onClick={() => navigate('/video-resources')} >
                    <PlayCircleOutlined className='text-5xl' />
                    <div className='mt-3'>
                      {constants.Videos}
                    </div>
                  </Card>
                </Col>
                <Col xs={24} lg={6}>
                  <Card hoverable className='w-full h-full bg-blue-100 text-center font-semibold' onClick={testScore} >
                    <BarChartOutlined className='text-5xl' />
                    <div className='mt-3'>
                      Test Score<br />Comparison
                    </div>
                  </Card>
                </Col>
                <Col xs={24} lg={6}>
                  <Card hoverable className='w-full h-full bg-green-100 text-center font-semibold' onClick={feedback} >
                    <MessageOutlined className='text-5xl' />
                    <div className='mt-3'>
                      {constants.Feedback}
                    </div>
                  </Card>
                </Col>
                <Col xs={24} lg={6}>
                  <Card hoverable className='w-full h-full bg-yellow-200 text-center font-semibold' onClick={myNotes} >
                    <ReadOutlined className='text-5xl' />
                    <div className='mt-3'>
                      {constants.MyNotes}
                    </div>
                  </Card>
                </Col>
                <Col span={24}>
                  <div className='p-3'>
                    {graphData.length > 0 ?
                      <>
                        <div className='text-center font-bold relative top-7 z-10'>Latest 3 Test Scores</div>
                        <Row>
                          <Col xs={24} lg={20}>
                            <Chart
                              loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active /></div>}
                              chartType="ColumnChart"
                              options={chartOptions}
                              width="100%"
                              height="280px"
                              data={graphData}
                            />
                          </Col>
                          <Col xs={24} lg={4}>
                            <div className='grid grid-cols-3 lg:grid-cols-1 place-content-center place-items-start gap-2 h-full'>
                              {graphData?.slice(1)?.map(([label, value, color]) => {
                                return <Space key='color-indicator'>
                                  <span className='w-5 h-5 block rounded' style={{ backgroundColor: color }}></span>
                                  {label}
                                </Space>
                              })}
                            </div>
                          </Col>
                        </Row>
                      </>
                      :
                      <>
                        <div className='grid place-content-center place-items-center min-h-[126px]'>
                          <div>You have not attempted any Tests.</div>
                          <div>View the resources in each Chapter to attempt any Test.</div>
                        </div>
                      </>
                    }
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Feedback modal */}
          {isFeedbackModalVisible && feedbackModal()}
          {updateprofileModalVisible && displayUpdateProfileForm()}
        </div>
       </Content>
       <Footers />
     </Layout>
  )
}

export default Dashboard
