import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Space,
  Row,
  Col,
  Divider,
  Card,
  Image
} from 'antd';
import { COOKIE, getCookie } from "../../../../services/cookie";
import {SnippetsOutlined, InfoCircleOutlined } from '@ant-design/icons';

const FeedbackMenus = () => {
  const navigate = useNavigate();
  const menuIconInfoFeedbackData = [
    {
      icon: <SnippetsOutlined className="text-4xl" />,
      additionalClass: '',
      title: 'Chapter Feedback',
      path: '/chapter-feedback'
    },
    {
      icon: <InfoCircleOutlined className="text-4xl" />,
      additionalClass: '',
      title: 'Issues Reported',
      path: '/issue-feedback'
    }
  ]


  return (
    <div className='cursorChange'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className='p-4 min-h-screen'>
            <div className='tracking-wider !font-bold text-lg !text-cyan-700 mt-2 mb-3 text-center font-sans bg-white py-1'>FEEDBACKS
            </div>
            <Card title="FEEDBACK DATA" className='mainflashCard' hoverable>
              <div className='flex flex-wrap !gap-9'>
                {menuIconInfoFeedbackData.map((icon) => {
                  return (
                    <Card className='iconBackground w-48' hoverable>
                      <div className="flex flex-col items-center imgWithoutpreview !cursor-pointer" key={icon} onClick={() => navigate(`${icon.path}`)}>
                        {icon.icon}
                        <span className={`iconTitle mt-3 font-semibold text-base ${icon.additionalClass}`}>{icon.title}</span>
                      </div>
                    </Card>
                  )
                })}
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default FeedbackMenus
