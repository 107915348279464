import React,{useState,useEffect} from 'react'
import {useLocation,useNavigate,Link} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
import {
			Table,
			Space,
			Row,
			Col,
			Image,
			Breadcrumb,
			Tooltip,
			Button,
			Card,
			Spin,
			Input,
			Select,
			message
} from 'antd';
const {Search} = Input;
import { getCustomisedBreadcrumbs, success } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { fetchAdminApi } from '../../../services/api';

function GetchapterwiseImage() {
	const [messageApi, contextHolder] = message.useMessage();
	const navigate = useNavigate();
	const location = useLocation();
	let {chapterId} = location.state || {};
	const [chapterimages,setChapterimages] = useState([]);
	const [allchapters,setallChapters] = useState([]);
	const [imageChapterchanged, setImagechapterChanged] = useState(false);
	const [chapterid, setChapterId] = useState(chapterId);
	const [inputVal, setInputval] = useState('');
	const [imagecount,setImagecount] = useState('');
	const [filtereddata,setFiltereddata] = useState(chapterimages);
	const [zoomedImagepath,setZoomedimagepath] = useState('');
	const [imageedit, setImageedit] = useState(false);
	const [imageEditcancel, setImageeditcancel] = useState(false);
	const [imageAdd, setImageadd] = useState(false);
	const [imageAddcancel, setImageaddcancel] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [imgloaded,setImageloaded] = useState(false);

	const newCrumb = [<Link key={0} to="/image-list" className="font-semibold">Images</Link>,<Link key={2} to={`/get-chapter-images/${chapterId}`} className="font-semibold !text-black">Image List</Link>]

	useEffect(() => {
		getAllTheChapters();
		afterVariousOpsgetChapterid();
	}, [])
	useEffect(() => {
		setIsLoading(true);
		getchapterrelatedImages();
	}, [chapterid]);
	const getchapterrelatedImages = () => {
		fetchAdminApi(`/get-image?chapter_id=${chapterid}`, 'get').then((res) => {
				if (res?.data && res?.data.length > 0) {
					setChapterimages(res.data);
					setFiltereddata(res.data);
					setImagecount(res.message === 'Failed to retrieve list' ? 0 : res.data.length);
					setIsLoading(false);
				} else {
					setChapterimages([]);
					setFiltereddata([]);
					setImagecount(0);
					setIsLoading(false);
				}
			})
	}

	useEffect(() => {
		getChangedchapterId();
	}, [imageAdd, imageAddcancel, imageedit, imageEditcancel, imageChapterchanged])

	const getAllTheChapters = () => {
		fetchAdminApi(`/chapters-list`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				const chaptertypeOps = res?.data.map((each, index) => {
					return {
						value: index + 1,
						label: each.chapter
					}
				})
				let stringifiedChapters = JSON.stringify(chaptertypeOps);
				localStorage.setItem('chapterDetails', stringifiedChapters);
				const chapters = JSON.parse(localStorage.getItem('chapterDetails'));
				if (chapters && chapters != '') {
					setallChapters(chapters);
				}
			} else {
				setallChapters([]);
			}
		})
	}
	const afterVariousOpsgetChapterid = ()=>{
		if (localStorage.getItem('imageUploadedsuccess')) {
			success('success', constants.image_added_success, 'flashSuccess', messageApi);
			const getId = localStorage.getItem('imageChapterId');
			setChapterId(getId)
			localStorage.removeItem('imageUploadedsuccess');
			setImageadd(true);
		}

		if (localStorage.getItem('imageNotAdded')) {
			const getId = localStorage.getItem('imagechapterid');
			setChapterId(getId);
			localStorage.removeItem('imageNotAdded');
			setImageaddcancel(true);
		}

		if (localStorage.getItem('imageEdited')) {
			success('success', constants.image_edited_success, 'flashSuccess', messageApi);
			const getId = localStorage.getItem('imgChapterId');
			setChapterId(getId);
			localStorage.removeItem('imageEdited');
			setImageedit(true);
		}

		if (localStorage.getItem('imageNotEdited')) {
			const getId = localStorage.getItem('imageChapterid');
			setChapterId(getId);
			localStorage.removeItem('imageNotEdited');
			setImageeditcancel(true);
		}

		if (localStorage.getItem('chapterEdited')) {
			const getId = localStorage.getItem('imgChapterchangeId');
			setChapterId(getId);
			localStorage.removeItem('chapterEdited');
			setImagechapterChanged(true);
		}
	}
	const handleChapterChange = (value)=>{
			setChapterId(value);
	}
	const redirectToAddImagepage = ()=>{
		navigate(`/add-image/${chapterid}`, {
						state: {
							data: [{
								chaptervalue: chapterid,
								allChapters: allchapters
							}]
						}
					});
	}
	const getChangedchapterId = ()=>{
		let filteredImageafterimageAdd;
		let filteredImageafterimageaddCancel;
		let filteredImageafterimageEdit;
		let filteredImagesafterimageeditCancel;
		let filteredImagesafterImageChapterchange;
		if (imageAdd) {
			if (chapterid !== '') {
				if (chapterimages && chapterimages.length > 0) {
					filteredImageafterimageAdd = chapterimages.filter((each) => {
						return (each.chapter_id == chapterid)
					})
					setFiltereddata(filteredImageafterimageAdd);
					setImagecount(filteredImageafterimageAdd.length)
					setImageadd(false);
				}
			}
		}
		if (imageAddcancel) {
			if (chapterid !== '') {
				if (chapterimages && chapterimages.length > 0) {
					filteredImageafterimageaddCancel = chapterimages.filter((each) => {
						return (each.chapter_id == chapterid)
					})
					setFiltereddata(filteredImageafterimageaddCancel);
					setImagecount(filteredImageafterimageaddCancel.length)
					setImageaddcancel(false);
				}
			}
		}
		if (imageedit) {
			if (chapterid != '') {
				if (chapterimages && chapterimages.length > 0) {
					filteredImageafterimageEdit = chapterimages.filter((each) => {
						return (each.chapter_id == chapterid)
					})
					setFiltereddata(filteredImageafterimageEdit);
					setImagecount(filteredImageafterimageEdit.length)
					setImageedit(false);
				}
			}
		}
		if (imageEditcancel) {
			if (chapterid !== '') {
				if (chapterimages && chapterimages.length > 0) {
					filteredImagesafterimageeditCancel = chapterimages.filter((each) => {
						return (each.chapter_id == chapterid)
					})
					setFiltereddata(filteredImagesafterimageeditCancel);
					setImagecount(filteredImagesafterimageeditCancel.length)
					setImageeditcancel(false);
				}
			}
		}

		if (imageChapterchanged) {
			if (chapterid != '') {
				if (chapterimages && chapterimages.length > 0) {
					filteredImagesafterImageChapterchange = chapterimages.filter((each) => {
						return (each.chapter_id == chapterid)
					})
					setFiltereddata(filteredImagesafterImageChapterchange);
					setImagecount(filteredImagesafterImageChapterchange.length)
					setImagechapterChanged(false);
				}
			}
		}
	}
	const fetchResponse = async (record) => {
		setImageloaded(true);
		const data = await fetchAdminApi(`/preview-image/${record.resource_file_id}`, 'get');
		const response = await data;
		if(data.code === 200){
			setImageloaded(false);
			setZoomedimagepath(imgloaded?<div>Loading...</div>:response.data[0].resource_name);
		}
	};
	const tabColumns = [
		{
			title: "Chapter",
			dataIndex: "chapter_id",
			key: "chapter_id",
			align: "center",
			width:"10%",
			sorter: (a, b) => a.chapter_id - b.chapter_id,
		},
		{
			title: <div className='fontSizewithoutLineheight'>Img #</div>,
			dataIndex: "resource_file_id",
			key: "resource_file_id",
			align: "center",
			width: "10%",
			sorter: (a, b) => a.resource_file_id - b.resource_file_id,
			render: (resource_file_id) => {
				return (
					<div className='font-medium'> #{resource_file_id}</div>
				)
			}
		},
		{
			title: <div className='fontSizewithoutLineheight'>Image</div>,
			dataIndex: "image_path",
			key: "image_path",
			align: "center",
			width: "10%",
			render: (image_path,record) => {
			return(
				<Image 
				src={image_path}
				alt = "image"
				onClick={()=>fetchResponse(record)}
				preview={{ src:imgloaded? <div>Loading....</div>: zoomedImagepath}}
				>
				</Image>
			)
		}
		},
		{
			title: <div className='fontSizewithoutLineheight'>Caption</div>,
			dataIndex: "caption",
			key: "caption",
			width: "45%",
			render: (caption,record) => (
				<Tooltip placement="topLeft" title={caption}>
				<Link className='linkColor text-sm' to={`/editImage/${record.chapter_id}`} state={{chapterId:record.chapter_id,entireRecord:record}}>{caption}</Link>
				</Tooltip>
			),
		},
		{
		title: <div className='fontSizewithoutLineheight'>Image Name</div>,
		dataIndex: "resource_name",
		key: "resource_name",
			render: (resource_name) => {
				return (
					<div className='font-medium'> {resource_name}</div>
				)
			}
	}
	]

	return (
		<div>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className = 'p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.flashCardpageCrumbs,newCrumb)} className='bg-neutral-100 shadow-lg p-3 mb-3'/>
						</div>
						<Card>
							<Space className='flex-space'>
								<Select 
									value={chapterid}
									onChange={handleChapterChange}
									className='w-80'
								>
									<Option value=""> --Chapter-- </Option> 
									{
											allchapters.map((each) => {
													return <Option key={each.value} value = {
															each.value.toString()
													}> {each.label} </Option>
											})
									}
								</Select>
								<Search 
									placeholder="input search text"
									value={inputVal}
									enterButton
									className='searchBtn w-80'
									onChange = {
										(e) => {
											setInputval(e.target.value);
										}
									}
									onSearch={value=>{
										if (inputVal !== '') {
											const filteredText = filtereddata.filter(record => {
												return record.caption.toLowerCase().includes(inputVal.toLowerCase());
											});
											setFiltereddata(filteredText);
										}else{
											setFiltereddata(chapterimages);
										}
										setInputval(value);
									}}
								/>
								<Button type='primary' className='font-semibold text-center addFlashbtn w-32' onClick={redirectToAddImagepage}><PlusOutlined />Add Image</Button>
							</Space>
							{contextHolder}
						</Card>
						<div className='font-semibold mb-3 text-sm pl-3 mt-3'>{imagecount} {constants.recordCount}</div>
						<Table 
							bordered
							columns = {tabColumns}
							dataSource={filtereddata}
							pagination={false}
							title = {
									() => <div className ="font-bold tracking-wide text-lg pl-3 flashTableTitle">IMAGES</div>
							}
							size = "middle"
							className = "mt-3"
							locale={{emptyText:constants.emptyTableText}}
							scroll={{x: 768}}
							loading={{ indicator: <div> <Spin /> </div>, spinning: isLoading }}
						>
						</Table>
					</div>
				</Col>
			</Row>
		</div>
	)
}
export default GetchapterwiseImage
