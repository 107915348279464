import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Breadcrumb, Card, Space, Select, Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getCustomisedBreadcrumbs, selectFilterOption } from '../../../functions/functions';
import { useNavigate,Link} from 'react-router-dom';
import constants from '../../../constants/constants';
import { fetchAdminApi } from '../../../services/api';

const {Search} = Input;
const UniversityListing = ()=> {
	const newCrumb = [<Link key={1} to="/university-list" className="!text-black font-semibold">{constants.Universities}</Link>]
	const [universityList, setUniversityList] = useState([]);
	const [filteredUnivesityList, setFilteredUnivesityList] = useState([]);
	const [usStateList, setUsStateList] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const navigate = useNavigate();
	useEffect(() => {
		getUniversitylistdata();
		getUsStateList();
	}, []);

	const getUniversitylistdata = (state=null) => {
		let url ="/get-university-data";
		if(state !== null){
			url = `/get-university-data?state=${state}`
		}
		fetchAdminApi(url, 'get').then((res) => {
			if (res?.code === 200) {
				setUniversityList(res.data);
				setFilteredUnivesityList(res.data);
			} else {
				setUniversityList([]);
				setFilteredUnivesityList([]);
			}
		})
	};

	const getUsStateList = () => {
		fetchAdminApi(`/get-us-states`, 'get').then((res) => {
			if (res?.code === 200) {
				setUsStateList(res.data);
			} else {
				setUniversityList([]);
			}
		})
	};

	const columns = [
		{
			title: <div className='text-base'>{constants.stateTitle}</div>,
			dataIndex: "region_code",
			key: "region_code",
			sorter: (a, b) => a.region_code.localeCompare(b.region_code),
		},
		{
			title: <div className='text-base'>{constants.Location}</div>,
			dataIndex: "city",
			key: "city",
			sorter: (a, b) => a.city.localeCompare(b.city),
		},
		{
			title: <div className='text-base'>{constants.universityTitle}</div>,
			dataIndex: "university_name",
			key: "university_name",
			render:(university_name,record)=>{
				return <span className='linkColor cursor-pointer' onClick={()=>redirectToAddEditUniversity(record?.university_id)}>{university_name}</span>
			},
			sorter: (a, b) => a.university_name.localeCompare(b.university_name),
		}
	];

	const redirectToAddEditUniversity = (university_id = null)=>{
		if(university_id !==null){
			navigate(`/edit-university`,{ state: { universityID: university_id } })
		}else{
			navigate(`/add-university`)
		}
	};

	const handleState = (_value,record) => {
		if(record?.key){
			getUniversitylistdata(record?.key);
		}else{
			getUniversitylistdata();
		}
	};

	return (
		<div>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.flashCardpageCrumbs, newCrumb)} className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card>
							<Space className='flex-space'>
								<Select
									onChange={handleState}
									className='w-40'
									showSearch
									allowClear
									filterOption={selectFilterOption}
									placeholder={"--State--"}
									defaultValue={"1"}
								>
									<Option value="1"> --State-- </Option>
									{
										usStateList.map((state) => {
											return <Option key={state.region_name} value={state.region_id}>{state.region_name} ({state.region_code})</Option>
										})
									}
								</Select>
								<Search
									placeholder="input search text"
									value={searchInput}
									enterButton
									className='searchBtn w-80'
									onChange={
										(e) => {
											setSearchInput(e.target.value);
										}
									}
									onSearch={value => {
										if (searchInput !== '') {
											let tempData = universityList.length > 0 ? universityList.filter((searchField) =>
												searchField && searchField.university_name && searchField.university_name.toLowerCase().includes(searchInput.toLowerCase()) ||
												searchField && searchField.city && searchField.city.toLowerCase().includes(searchInput.toLowerCase())
											) : [];
											setFilteredUnivesityList(tempData);
										}
										else {
											setFilteredUnivesityList(universityList);
										}
										setSearchInput(value);
									}}
								/>
								<Button type='primary' className='font-semibold text-center' icon={<PlusOutlined />} onClick={()=>redirectToAddEditUniversity()}>{constants.AddUniversity}</Button>
							</Space>
						</Card>
						<div className='font-semibold mb-3 text-sm pl-3 mt-3'>{filteredUnivesityList.length} {constants.recordCount}</div>
						<Table
							bordered
							className='!table-fixed'
							columns={columns}
							dataSource={filteredUnivesityList}
							pagination={false}
							locale={{ emptyText: constants.emptyTableText }}
							scroll={{ x: 768 }}
							title={
								() => <div className="font-bold tracking-wide text-lg pl-3 flashTableTitle"> {constants.UniversityListing} </div>
							}>
						</Table>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default UniversityListing
