import React, {useState,useEffect} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {
		Table,
		Row,
		Col,
		Breadcrumb,
		Tooltip,
		Spin,
		Select,
		Card,
		Space,
		Button,
		message
} from 'antd';
import {Link,useLocation,useNavigate} from 'react-router-dom';
import {getCustomisedBreadcrumbs,success} from '../../../functions/functions';
import constants from '../../../constants/constants';
import {fetchAdminApi} from '../../../services/api';

function GetchapterwiseFlashcard() {
	const [messageApi, contextHolder] = message.useMessage();
	const navigate = useNavigate();
	const location = useLocation();
	const {chapterID} = location.state || {};
	const [allchapters, setallChapters] = useState([]);
	const [flashcarddata, setFlashcarddata] = useState([]);
	const [chapterId, setchapterId] = useState(chapterID);
	const [flashCount, setflashCount] = useState();
	const [flashAdd, setFlashadd] = useState(false);
	const [flashAddcancel, setFlashaddcancel] = useState(false);
	const [flashEdit, setFlashedit] = useState(false);
	const [flashEditcancel, setFlasheditcancel] = useState(false);
	const [flashchapterchanged, setFlashchapterchanged] = useState(false);
	const [filteredFlashquestions, setFilteredFlashquestions] = useState(flashcarddata);
	const [isLoading, setIsLoading] = useState(false);

	const newCrumb = [<Link key=""
					to = "/flashcard-list" className="font-semibold"> Flashcards </Link>,
					<Link key="" to={`/get-chapter-flashcards/${chapterID}`} className="font-semibold !text-black"> Flashcard List </Link>]

	useEffect(()=>{
		getAllTheChapters();
		afterOpsGetChapterid();
	},[])

	useEffect(() => {
		setIsLoading(true);
		getFlashCardData();
	}, [chapterId])

	const getAllTheChapters = () => {
		fetchAdminApi(`/chapters-list`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				const chaptertypeOps = res?.data.map((each, index) => {
					return {
						value: index + 1,
						label: each.chapter
					}
				});
				let stringifiedChapters = JSON.stringify(chaptertypeOps);
				localStorage.setItem('chapterDetails', stringifiedChapters);
				const chapters = JSON.parse(localStorage.getItem('chapterDetails'));
				if (chapters && chapters != '') {
					setallChapters(chapters);
				}
			} else {
				setallChapters([]);
			}
		})
	}
	const redirectToAddFlashcardpage = () => {
			if (chapterId && chapterId != '') {
					navigate('/addFlashcard', {
							state: {
									data: [{
											id: chapterId,
											chapters: allchapters
									}]
							}
					});
			} else {
					success('info', constants.null_data_msg_flashcard, 'null_data_msg', messageApi);
			}
	}
	const flashColumns = [
			{
					title: <div className='text-base'>Chapter</div>,
					dataIndex: "chapter_id",
					key: "q_type",
					width: 10,
					align: "center",
					sorter: (a, b) => a.chapter_id - b.chapter_id,
			},
			{
				title: <div className='text-base'>Fc #</div>,
				dataIndex: "flashcard_id",
				key: "flashcard_id",
				width: 10,
				align: "center",
				sorter: (a, b) => a.flashcard_id - b.flashcard_id,
				render: (flashcard_id) => {
					return (
						<div className='font-medium'> #{flashcard_id}</div>
					)
				}
			},
			{
					title: <div className='text-base'>Question</div>,
					dataIndex: "question",
					key: "question",
					width: 40,
					ellipsis: true,
					render: (question, record) => {
							return ( 
							<Tooltip title = {question}>
								<Link 
									className = "linkColor text-sm"
									to = {`/edit-flashcard/${record.flashcard_id}`}
									state = {
											{
													flashId: record.flashcard_id,
													entireRecord: record
											}
									}
										> <span>{question}</span>
								</Link> 
							</Tooltip>)
							}
					},
					{
							title: <div className='text-base'>Answer</div>,
							dataIndex: "answer",
							className:'!pl-5',
							key: "answer",
							width: 40,
							ellipsis: true,
							render: (answer) => {
									return (
										<Tooltip title={answer}> <span className="text-sm flash-slider" dangerouslySetInnerHTML={{ __html: answer }}/> </Tooltip>
									)
							}
					}
			]
	const onchapterChange = (value) => {
		setchapterId(value);
	}
	const getFlashCardData = () => {
			fetchAdminApi(`/flashcards-list?chapter_id=${chapterId}`, 'get').then((res) => {
				if(res?.data&&res?.data.length>0){
					setFlashcarddata(res.data);
					setFilteredFlashquestions(res.data);
					setflashCount(res.message === "Failed to retrieve flashcards list." ? 0 : res.total_count);
					setIsLoading(false);
				}else{
					setFlashcarddata([]);
					setFilteredFlashquestions([]);
					setflashCount(0);
					setIsLoading(false);
				}
			})
	}

	useEffect(() => {
		changedflashcardChapterid();
	}, [flashAdd, flashAddcancel, flashEdit, flashEditcancel, flashchapterchanged])

	const afterOpsGetChapterid = ()=>{
		if (localStorage.getItem('flashcardSaved')) {
			success('success', constants.flashcard_added_success, 'flashSuccess', messageApi);
			const getId = localStorage.getItem('flashChapterid');
			setchapterId(getId)
			localStorage.removeItem('flashcardSaved');
			setFlashadd(true);
		}

		if (localStorage.getItem('flashcardnotSaved')) {
			const getId = localStorage.getItem('flashChapterid');
			setchapterId(getId);
			localStorage.removeItem('flashcardnotSaved');
			setFlashaddcancel(true);
		}

		if (localStorage.getItem('flashquestionEdited')) {
			success('success', constants.flashcard_edited_success, 'flashSuccess', messageApi);
			const getId = localStorage.getItem('flashChapterId');
			setchapterId(getId);
			localStorage.removeItem('flashquestionEdited');
			setFlashedit(true);
		}

		if (localStorage.getItem('flashquestionNotEdited')) {
			const getId = localStorage.getItem('flashChapterId');
			setchapterId(getId);
			localStorage.removeItem('flashquestionNotEdited');
			setFlasheditcancel(true);
		}

		if (localStorage.getItem('flashcardchapterEdited')) {
			const getId = localStorage.getItem('flashChapterchangeId');
			setchapterId(getId);
			localStorage.removeItem('flashcardchapterEdited');
			setFlashchapterchanged(true);
		}
	}
	const changedflashcardChapterid = ()=>{
		let filteredQuestionsafterflashAdd;
		let filteredQuestionsafterflashaddCancel;
		let filteredQuestionsafterflashEdit;
		let filteredQuestionsafterflasheditCancel;
		let filteredFlashcardsafterFlashChapterchange;
		if (flashAdd) {
			if (chapterId !== '') {
				if (flashcarddata && flashcarddata.length > 0) {
					filteredQuestionsafterflashAdd = flashcarddata.filter((each) => {
						return (each.chapter_id == chapterId)
					})
					setFilteredFlashquestions(filteredQuestionsafterflashAdd);
					setflashCount(filteredQuestionsafterflashAdd.length)
					setFlashadd(false);
				}
			}
		}
		if (flashAddcancel) {
			if (chapterId !== '') {
				if (flashcarddata && flashcarddata.length > 0) {
					filteredQuestionsafterflashaddCancel = flashcarddata.filter((each) => {
						return (each.chapter_id == chapterId)
					})
					setFilteredFlashquestions(filteredQuestionsafterflashaddCancel);
					setflashCount(filteredQuestionsafterflashaddCancel.length)
					setFlashaddcancel(false);
				}

			}
		}
		if (flashEdit) {
			if (chapterId !== '') {
				if (flashcarddata && flashcarddata.length > 0) {
					filteredQuestionsafterflashEdit = flashcarddata.filter((each) => {
						return (each.chapter_id == chapterId)
					})
					setFilteredFlashquestions(filteredQuestionsafterflashEdit);
					setflashCount(filteredQuestionsafterflashEdit.length)
					setFlashedit(false);
				}
			}
		}
		if (flashEditcancel) {
			if (chapterId !== '') {
				if (flashcarddata && flashcarddata.length > 0) {
					filteredQuestionsafterflasheditCancel = flashcarddata.filter((each) => {
						return (each.chapter_id == chapterId)
					})
					setFilteredFlashquestions(filteredQuestionsafterflasheditCancel);
					setflashCount(filteredQuestionsafterflasheditCancel.length);
					setFlasheditcancel(false);
				}
			}
		}

		if (flashchapterchanged) {
			if (chapterId !== '') {
				if (flashcarddata && flashcarddata.length > 0) {
					filteredFlashcardsafterFlashChapterchange = flashcarddata.filter((each) => {
						return (each.chapter_id == chapterId)
					})
					setFilteredFlashquestions(filteredFlashcardsafterFlashChapterchange);
					setflashCount(filteredFlashcardsafterFlashChapterchange.length)
					setFlashchapterchanged(false);
				}
			}
		}
	}

	return ( 
		<div>
			<Row>
				<Col xs = {24} sm={24} md = { 24} lg = {24}>
					<div className ='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb 
								items = {getCustomisedBreadcrumbs(constants.flashCardpageCrumbs, newCrumb)}
								className = 'bg-neutral-100 shadow-md p-3 mb-4 mx-0'
							/>
						</div> 
						<Card>
							<Space className = 'flex-space'>
								<Select 
									value = {chapterId}
									onChange = {onchapterChange}
									className='w-80'
								>
								<Option value=""> --Chapter-- </Option> 
								{
										allchapters.map((each) => {
												return <Option key={each.value} value={each.value.toString()}>{each.label}</Option>
										})
								}
								</Select> 
								<Button 
									type = 'primary'
									className = 'font-semibold text-center addFlashbtn !w-36'
									onClick = {redirectToAddFlashcardpage}
									>
									<PlusOutlined /> Add Flashcard 
								</Button> 
							</Space> 
							{contextHolder} 
						</Card> 
						<div className = 'font-semibold my-3 text-sm pl-5'> {flashCount} 
							<span className='ml-2'>{constants.recordCount}</span> 
						</div> 
						<Table 
							bordered 
							sticky 
							columns = {flashColumns}
							dataSource={filteredFlashquestions}
							pagination = {false}
							scroll = {{x: 768}}
							title = {
							() => <div className = "font-bold tracking-wide text-lg pl-3 flashTableTitle"> FLASHCARDS </div>
							}
							size = "middle"
							className = "mt-3"
							locale = {{emptyText: constants.emptyTableText}}
							loading={{ indicator: <div> <Spin /> </div>, spinning: isLoading }}
						>
						</Table> 
					</div> 
				</Col> 
			</Row> 
		</div>
					)
					}
export default GetchapterwiseFlashcard;
