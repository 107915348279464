import React, {useState,useEffect} from 'react';
import {Table,Row,Col,Breadcrumb,Tooltip,message} from 'antd';
import {Link} from 'react-router-dom';
import {getCustomisedBreadcrumbs,success} from '../../../functions/functions';
import constants from '../../../constants/constants';
import {fetchAdminApi} from '../../../services/api';

function FlashcardList() {
	const [messageApi, contextHolder] = message.useMessage();
	const [chapterwiseFlashcarddata, setChapterwiseFlashcarddata] = useState([])

	const newCrumb = [<Link key={0} to="/flashcard-list" className="font-semibold !text-black"> Flashcards </Link>]

	useEffect(() => {
			getchapterrelatedFlashcarddata();
			if (localStorage.getItem('flashcardSaved')) {
					success('success', constants.question_added_success, 'imageuploadError', messageApi);
					localStorage.removeItem('flashcardSaved');
			}
		localStorage.removeItem('flashChapterid');
		localStorage.removeItem('flashChapterId');
		localStorage.removeItem('chapterDetails');
	}, [])
	const getchapterrelatedFlashcarddata = () => {
			fetchAdminApi(`/flashcards-count`, 'get').then((res) => {
				if(res?.data&&res?.data.length>0){
					setChapterwiseFlashcarddata(res.data);
				}else{
					setChapterwiseFlashcarddata([]);
				}
			})
	}
	const flashtabColumns = [{
					title: <div className='text-base'>Chapter</div>,
					dataIndex: "chapter_id",
					key: "chapter_id",
					align: "center",
					width: '10%',
					sorter: (a, b) => a.chapter_id - b.chapter_id,
			},
			{
					title: <div className="text-base">Title (No. of flashcards)</div>,
					dataIndex: "chapter_name",
					key: "chapter_name",
					width: '80%',
					render: (chapter_name, record) => {
							return (
									<Tooltip title = {chapter_name}>
										<Link 
										to = {`/get-chapter-flashcards/${record.chapter_id}`}
										state = {
												{
													chapterID: record.chapter_id,
													entireRec: record
												}
										}
										className='linkColor'>
											<span className = "mr-2 text-sm"> {chapter_name}</span>
											<span className='italic font-semibold text-sm'>({record.total_count})</span>
										</Link>  
									</Tooltip>
							)
					}
			},
	]

	return( 
		<div>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className = 'p-4 min-h-screen bg-gray-100'>
							<div>
								<Breadcrumb items = {getCustomisedBreadcrumbs(constants.flashCardpageCrumbs,newCrumb)}
								className = 'bg-neutral-100 shadow-md p-3 mb-3'/>
							</div>
							{contextHolder} 
							<Table 
								bordered 
								columns = {flashtabColumns}
								dataSource = {chapterwiseFlashcarddata}
								pagination = {false}
								locale = {{emptyText: constants.emptyTableText}}
								scroll = {{ x: 768}}
								title = {
								() => <div className = "font-bold tracking-wide text-lg pl-3 flashTableTitle"> FLASHCARD LIST </div>
								}>
							</Table> 
					</div>  
				</Col>  
			</Row>  
		</div>
				)
	}
export default FlashcardList
