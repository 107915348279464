import React, { useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import {
	Space,
	Row,
	Col,
	Divider,
	Card,
	Image
} from 'antd';
import { COOKIE, getCookie} from "../../../../services/cookie";
import { QuestionOutlined, CopyOutlined, FileImageOutlined,FileTextOutlined, BankOutlined } from '@ant-design/icons';
import constants from '../../../../constants/constants';

function MasterMenus() {
	const navigate = useNavigate();
	useEffect(() => {
		let userId = getCookie(COOKIE.UserId);
		if (!userId) {
			navigate('/admin-login');
		}
	}, []);
	const menuIconInfoMasterdata = [
		{
			icon: <QuestionOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Questions',
			path: '/question-list'
		},
		{
			icon: <CopyOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Flashcards',
			path: '/flashcard-list'
		},
		{
			icon: <FileImageOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Images',
			path: '/image-list'
		},
		{
			icon: <FileTextOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Study Guides',
			path: '/studyGuide-list'
		},
		{
			icon: <BankOutlined className="text-4xl" />,
			additionalClass: '',
			title: constants.Universities,
			path: '/university-list'
		}
	]

	return (
		<div className='cursorChange'>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen'>
						<div className='tracking-wider !font-bold text-lg !text-cyan-700 mt-2 mb-3 text-center font-sans bg-white py-1'>MASTERS
						</div>
						<Card title="MASTER DATA" className='mainflashCard' hoverable>
							<div className='flex flex-wrap !gap-9'>
								{menuIconInfoMasterdata.map((icon)=>{
									return(
										<Card className='iconBackground w-36' hoverable>
											<div className="flex flex-col items-center imgWithoutpreview !cursor-pointer" key={icon} onClick={() => navigate(`${icon.path}`)}>
													{icon.icon}
												<span className={`iconTitle mt-3 font-semibold text-base ${icon.additionalClass}`}>{icon.title}</span>
											</div>
										</Card>
									)
								})}
							</div>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}
export default MasterMenus
