import React, { useState, useEffect } from "react";
import { List, Button, Space, Divider, Card, Skeleton, Popconfirm, message, Select, Form, Result, Spin, Tooltip, Steps } from 'antd';
import {  ArrowLeftOutlined,FileSearchOutlined,  } from '@ant-design/icons';
import constants from "../../../constants/constants";
import {  fetchApi } from "../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { getColorClass } from "../../../functions/functions";
import { Chart } from "react-google-charts";

const TestResults = (props) => {
  const location = useLocation();
	const navigate = useNavigate();
	const [correctAnswers, setCorrectAnswers] = useState(0);
	const [totalPoints,setTotalPoints]=useState(0);
  const current = new Date();
	const date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;
  const graphOption = {
		colors: ["#2F8548", "#B91919"],
		backgroundColor: { fill: 'transparent' },
		chartArea: { width: "70%" },
	};
	useEffect(() => {
    getTestResults()
	}, []);
	const getTestResults=()=>{
		fetchApi(`/test-score/${location?.state?.scoreCardId}`, "get",)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					let correctTotal = res?.data?.q_correct  ? res?.data?.q_correct  : 0;
					setCorrectAnswers(correctTotal);
					let totalPoints=res?.data?.points  ? res?.data?.points  : 0;
					setTotalPoints(totalPoints);
				} else {
					setCorrectAnswers(0);
					setTotalPoints(100);
				}
			});
	}
	return (
		<div >
			<div
				style={{ backgroundColor: getColorClass(location?.state?.chapter_id, "colorcode") }}
				className='text-white w-12 lg:w-36 roboto-slab font-bold text-4xl flex justify-center items-end pb-3.5 absolute right-0 top-0 h-36'
			>
				{location?.state?.chapter_id}
			</div>
			<div className='container mx-auto'>
				<div className='flex justify-between items-center'>
					<div style={{ color: getColorClass(location?.state?.chapter_id, "colorcode") }} className='text-base lg:text-2xl underline underline-offset-4 font-extrabold uppercase w-4/5 lg:w-auto'>{location?.state?.chapter_name}</div>
				</div>
				<Divider className="my-1" />
				<div className={`flex justify-between items-center my-3 px-5`}>
					<div className='font-bold text-xl'>Test - Attempt #{location?.state?.attempt}</div>
					<div className="w-1/2">
						<Steps
							current={2}
							items={[
								{
									title: 'Instructions',
								},
								{
									title: 'Take a test',
								},
								{
									title: 'Result',
								},
							]}
						/>
					</div>
				</div>
					<div>
						<Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
							<div class="grid grid-cols-1 lg:grid-cols-2 divide-x">
								<div>
									<div className='text-green-700 text-xl font-semibold'>You scored {correctAnswers}/{totalPoints} </div>
									<div className="font-semibold">{"Date: " + date}</div>
									<Chart
										width="100%"
										height="300px"
										chartType="PieChart"
										// loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
										data={[
											["Answered", "Total"],
											["Correct", Number(correctAnswers)],
											["Incorrect", Number(totalPoints) - Number(Number(correctAnswers))],
										]}
										options={graphOption}
									/>
								</div>
								<div className="grid place-content-center place-items-center">
									<div className="grid gap-3 lg:w-56">
										<Button
											className='rounded-lg bg-blue-700 text-white text-left' block
											size="large" icon={<FileSearchOutlined />}	
											onClick={()=>{
												let state={
													chapter_id:location?.state?.chapter_id,
													attempt:location?.state?.attempt,
													chapter_name:location?.state?.chapter_name,
													score_card_id:location?.state?.scoreCardId,
													show_result:"f"
												}
												navigate('/review-test',{state, replace: true});
											}}
										>{constants.ReviewTest}</Button>
										<Button onClick={() => {
											const state = {
												chapter_id: location?.state?.chapter_id
											};
											navigate('/chapter-resources', { state, replace: true });
										}}
											className='rounded-lg bg-blue-700 text-white text-left' block
											size="large" icon={<ArrowLeftOutlined />}	>{constants.BackToChapter}</Button>
										<Button onClick={() => navigate('/dashboard', { replace: true })} icon={<ArrowLeftOutlined />} className='rounded-lg bg-blue-700 text-white text-left' block
											size="large">{constants.Dashboard}</Button>
									</div>
								</div>
							</div>
						</Card>
					</div>
			</div>
		</div>
	);
};
export default TestResults;
