import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Divider, Tooltip, Typography, Button, message, Image } from 'antd';
import constants from '../../../constants/constants';
import { getColorClass } from '../../../functions/functions';
import {  ArrowLeftOutlined } from '@ant-design/icons';
import { fetchApi } from '../../../services/api';
const { Meta } = Card;
const VideoResources = () => {
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    getVideoList();
  }, []);

  const getVideoList = () => {
    fetchApi(`/video-list`, 'get').then((res) => {
			if (res?.code === 200) {
				setVideoList(res?.data);
			}else{
        setVideoList([]);
        message.error(res?.message);
      }
		})
  };

  const renderVideo = (id,title) => {
    const state = {
      videoId: id,
      videoName: title
    };
    navigate('/video', { state });
  };

  return (
    <div>
      <div className='container mx-auto'>
        <div className='flex justify-between items-center'>
          <div style={{ color: getColorClass("1", "colorcode") }} className='text-2xl underline underline-offset-4 font-extrabold uppercase'>{constants.Videos}</div>
          <Button onClick={() => navigate('/dashboard')} icon={<ArrowLeftOutlined />} className='rounded-lg bg-blue-700 text-white'>{constants.BckDashbd}</Button>
        </div>
        <Divider className='my-2' />
        {
          videoList.length > 0 ?
            videoList.map((val, index) => (
              <div className='' key={index}>
                <div style={{ backgroundColor: getColorClass("1", "colorcode") }} className={`flex justify-between items-center mt-3 py-2.5 px-5`}>
                  <div className='text-white font-extrabold text-xl'>{val.name}</div>
                </div>
                <Card style={{ borderColor: getColorClass("1", "colorcode") }} className={`rounded-none border`} >
                  <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3'>
                    {
                      val?.videoList?.map((videoDetails, index) => (
                        <Card
                          className='border-black border rounded-none p-1 grid place-content-center items-center overflow-hidden'
                          key={index}
                          onClick={() => renderVideo(videoDetails?.resource_file_id, videoDetails?.title)}
                          cover={<Image
                            className='cursor-pointer'
                            preview={false}
                            src={videoDetails?.image}
                            alt={videoDetails?.title}
                          />}
                        >
                          <Tooltip title={videoDetails?.title}><Meta className="bg-gray-300 p-1 absolute bottom-1 left-0 w-full" title={videoDetails?.title} /></Tooltip>
                        </Card>
                      ))
                    }
                  </div>
                </Card>
              </div>
            ))
            :
            []
        }
      </div>
    </div>
  )
}

export default VideoResources;