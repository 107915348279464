import React,{useState,useEffect} from 'react';
import {
	Table,
	Row,
	Col,
	Breadcrumb,
	Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import { getCustomisedBreadcrumbs } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { fetchAdminApi } from '../../../services/api';

function QuestionList() {
	const [chapterwiseQuestiondata,setChapterwiseQuestiondata] = useState([])
	const newCrumb = [<Link key=""
		to="/question-list" className="font-semibold !text-black"> Questions </Link>]

	useEffect(() => {
		getchapterrelatedQuestionddata();
	}, [])

	const getchapterrelatedQuestionddata = () => {
		fetchAdminApi(`/questions-count`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				setChapterwiseQuestiondata(res.data);
			} else {
				setChapterwiseQuestiondata([]);
			}
		})
	}

	const questiontabColumns = [
		{
		title: <div className='text-base'>Chapter</div>,
		dataIndex: "chapter_id",
		key: "chapter_id",
		align: "center",
		width: '10%',
		sorter: (a, b) => a.chapter_id - b.chapter_id,
	},
	{
		title: <div className="text-base">Title</div>,
		dataIndex: "chapter_name",
		key: "chapter_name",
		width: '40%',
		render: (chapter_name, record) => {
			return (
				<Tooltip title={chapter_name}>
					<Link
					 to={`/get-chapter-questions/${record.chapter_id}`}
						state={
						  {
						    chapterID: record.chapter_id,
								qID: null,
						    entireRec: record
						  }
						}
						className='linkColor'>
						<span className="mr-2 text-sm"> {chapter_name}</span>
					</Link>
				</Tooltip>
			)
		}
	},
	{
		title: <div className='text-base'>Single select</div>,
		dataIndex: "single_select",
		key: "single_select",
		align: "center",
		width: '10%',
	},
	{
		title: <div className='text-base'>Image question</div>,
		dataIndex: "image_quiz",
		key: "image_quiz",
		align: "center",
		width: '10%',
	},
	{
		title: <div className='text-base'>Total</div>,
		dataIndex: "total_count",
		key: "total_count",
		align: "center",
		width: '10%',
	},
	]

	return (
		<div>
		 <Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.flashCardpageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Table
							bordered
							columns={questiontabColumns}
							dataSource={chapterwiseQuestiondata}
							pagination={false}
							locale={{ emptyText: constants.emptyTableText }}
							scroll={{ x: 768 }}
							title={
								() => <div className="font-bold tracking-wide text-lg pl-3 flashTableTitle"> QUESTION LIST </div>
							}>
						</Table>
					</div>  
			</Col>
		 </Row>
		</div>
	)
}

export default QuestionList;
