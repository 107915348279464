import React, { useState, useEffect, useRef } from "react";
import {
	Input,
	Form,
	Button,
	message,
	Card,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { fetchAdminApi } from "../../../../services/api";
import constants from "../../../../constants/constants";
import Modal from "antd/lib/modal/Modal";
import { useNavigate } from 'react-router-dom';
import { COOKIE, getCookie, createCookie  } from "../../../../services/cookie";
import Headers from "../Header";

const Login = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [isLoggedIn,setIsLoggedIn]=useState(false);
	const [userName,setUserName]=useState("");
	const [password,setPassword]=useState("");
	const [isLoggedInModal,setIsLoggedInModal]=useState(false);
	const [form] = Form.useForm();
	const myRef = useRef();
	useEffect(() => {
		let userId = getCookie(COOKIE.UserId);
		if (userId) {
			navigate('/');
		}
	}, []);
	useEffect(() =>{
		if(myRef && myRef.current){
			const { input } = myRef.current;
			input.focus();
		}
	});
	const handleUsername=(e)=>{
		setUserName(e.target.value);
	};

	const handlePassword=(e)=>{
		setPassword(e.target.value);
	};
	//Executes on click of login button
	const onFinish = () => {
		if(userName&&password){
			let agent = navigator.userAgent;
			setLoading(true);
			let payload = null;
			payload = {
				username: userName,
				password: password,
				// browser:agent,
			};
			if(isLoggedIn===true){
				payload.allow_login= 1;
			}
			fetchAdminApi("/login", "post", payload)
				.then((res) => {
					setLoading(false);
					if (res && res.code && res.code === 200) {
						setIsLoggedIn(false);
						createCookie(COOKIE.UserId, res.data.user_id, null, "/");
						createCookie(COOKIE.Token,res.token,null,"/");
						localStorage.setItem('userType',res.data.user_type);
						localStorage.setItem('userName', res.data.login_username);
						navigate(res.data.user_type == 0 ? '/' :'/');
					} else {
						if(res&&res.message==="User already Logged-in"){
							setIsLoggedInModal(true);
							setIsLoggedIn(true);
						}else{
							message.error(
								res.message,
							);
						}
					}
				})
				.catch(() => {
					setLoading(false);
					message.error("Failed to login");
				});
		}
	};
	return (
		<div>
			<Headers/>
			<div className="grid place-content-center place-items-center min-h-[82vh]" >
				<Card hoverable className="w-96 addQcard border-2" title={constants.LoginHeader}>
					<Form layout="vertical" form={form} onFinish={onFinish} autoComplete="off" className='LoginForm'>
									<div>
										<Form.Item
											label={constants.Username}
											name="userName"
											rules={[
												{
													required: true,
													message: constants.LoginUserNameValid,
												},
											]}
										>
											<Input autoFocus  placeholder={constants.Username} onChange={handleUsername}/>
										</Form.Item>
										<Form.Item
											label={constants.Password}
											name="password"
											rules={[
												{
													required: true,
													message: constants.LoginPasswordValid,
												},
											]}
										>
											<Input.Password
												placeholder={constants.Password}
												iconRender={(visible) =>
													visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
												}
												onChange={handlePassword}
											/>
										</Form.Item>
									</div>
									<Form.Item>
										<Button
											type="primary"
											block
											htmlType="submit"
											loading={loading}
											className="font-bold"
										>
											{constants.LoginBTN}
										</Button>
									</Form.Item>
								</Form>
								</Card>
							</div>
			{/* <Footer/> */}
			<Modal
				title="Login"
				visible={isLoggedInModal}
				width={510}
				onCancel={()=>{setIsLoggedInModal(false);}}
				className="already-logged-modal"
				footer={[
					<Input
						ref={myRef}
						key="submit"
						type="button" 
						value="Use Here"
						className="input-btn-purple mt-2" 
						htmlType="submit"
						onClick={()=>{onFinish();}}
					/>,
					<Button key="close" onClick={()=>{setIsLoggedInModal(false);}} className="mt-2">
						{constants.CancelBTN}
					</Button>
				]}
			>
				<p>{constants.LoginRestriction}</p>
				<p>{constants.LoginRestrictionPart2}</p>
			</Modal>
		</div>
	);
};
export default Login;
