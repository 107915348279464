import React, { useEffect, useState, useRef } from 'react';
import { message, Spin, Button, Divider } from 'antd';
import constants from '../../../constants/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { getColorClass } from '../../../functions/functions';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchVideoApi } from '../../../services/api';
import { COOKIE, getCookie } from '../../../services/cookie';

const VideoComponent = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const [videoSrc, setVideoSrc] = useState(null);
	const videoRef = useRef(null); // Use ref to access the video element

	useEffect(() => {
		fetchVideoStream();
		return () => {
			// Cleanup URL on component unmount
			if (videoSrc) {
				URL.revokeObjectURL(videoSrc);
			}
		};
	}, []);

	const fetchVideoStream = async () => {
		try {
			const blob = await fetchVideoApi(`/get-video?id=${location?.state?.videoId}&stud_id=${getCookie(COOKIE.UserId)}`, "GET");
	
			// Ensure blob is a valid Blob before creating an Object URL
			if (blob instanceof Blob) {
				const url = URL.createObjectURL(blob);
				setVideoSrc(url); // Update state with the new URL
	
				const video = videoRef.current; // Access video element via ref
				video.src = url;
	
				// Set up event handlers for video
				video.onloadeddata = () => {
					setLoading(false);
				};
	
				video.onended = () => {
					URL.revokeObjectURL(url); // Revoke URL after video ends
				};
	
				video.onerror = () => {
					// Handle any error while loading video
					setLoading(false);
					message.error('Reload the page.');
					URL.revokeObjectURL(url); // Revoke URL in case of error
				};
			} else {
				throw new Error('Response is not a valid Blob');
			}
		} catch (error) {
			setLoading(false);
			console.error('Error fetching video:', error);
		}
	};
	return (
		<div className='container mx-auto'>
			<div className='flex justify-between items-center flex-wrap'>
				<div style={{ color: getColorClass("1", "colorcode") }} className='text-2xl underline underline-offset-4 font-extrabold uppercase'>{location.state?.videoName}</div>
				<Button onClick={() => navigate('/video-resources')} icon={<ArrowLeftOutlined />} className='rounded-lg bg-blue-700 text-white'>{constants.BckVideos}</Button>
			</div>
			<Divider className='my-2' />
			<Spin spinning={loading} size="large">
				<video
					ref={videoRef} // Use ref to get the video element
					controls
					width="80%"
					className="border-solid border border-slate-700 p-3 mx-auto"
					controlsList="nodownload"
					autoPlay
				>
					Your video.
				</video>
			</Spin>
		</div>
	);
};

export default VideoComponent;
