import React,{useState,useEffect} from 'react';
import { Table, Row, Col, Breadcrumb, Tooltip, message,Upload,Button} from 'antd';
import { getCustomisedBreadcrumbs,success} from '../../../functions/functions';
import { Link } from 'react-router-dom';
import constants from '../../../constants/constants';
import { fetchAdminApi } from '../../../services/api';
import { UploadOutlined, SaveOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { COOKIE, getCookie } from '../../../../src/services/cookie/index';

function StudyGuideList() {
	const [studyguidelistData,setStudyguidelistdata] = useState([]);
	const [messageApi, contextHolder] = message.useMessage();
	const [fileList, setFileList] = useState([]);
	const [showList,setShowlist] = useState(false);
	const [editableRowIndex, setEditableRowIndex] = useState(null);
	const newCrumb = [<Link key={1} to="/studyGuide-list" className="!text-black font-semibold">Study Guides</Link>]

	const getStudyguidelistdata = () => {
		fetchAdminApi(`/study-guide-list`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				setStudyguidelistdata(res.data);
			} else {
				setStudyguidelistdata([]);
			}
		})
	}
	useEffect(()=>{
		getStudyguidelistdata();
	},[])
	const handleUpload = (id,resourceId) => {
		const formData = new FormData();
		if (resourceId === null){
			fileList.forEach((file) => {
				formData.append('file', file.originFileObj);
			});
			formData.append('updated_by', 1);
			formData.append('resource_type', 0);
			formData.append('chapter_id', id);
			formData.append('details','');
		}else{
			fileList.forEach((file) => {
				formData.append('file', file.originFileObj);
			});
			formData.append('updated_by', 1);
			formData.append('resource_type', 0);
			formData.append('chapter_id', id);
			formData.append('resource_id', resourceId);
			formData.append('details', '');
		}
		fetch(`${constants.AdminApiUrl}/upload-study-guide`, {
			method: 'POST',
			body: formData,
			headers: {
				Authorization: `Bearer ${getCookie(COOKIE.Token)}`
			},
		})
			.then((res) => res.json())
			.then((res) => {
				if(res.code === 200){
					success('success', constants.studyGuide_upload_success, 'flashSuccess', messageApi);
					setShowlist(false);
					fetchAdminApi(`/study-guide-list`, 'get').then((res) => {
						if (res?.data && res?.data.length > 0) {
							setStudyguidelistdata(res.data);
						} else {
							setStudyguidelistdata([]);
						}
					})
				}else{
					success('error', constants.studyGuide_upload_failure, 'flashSuccess', messageApi);
				}
				setFileList([]);
				setEditableRowIndex(null);
			})
			.catch(() => {
				message.error(' Studyguide upload failed.');
			})
			.finally(() => {
			});
	};
	const handleCustomRequest = (file, index,onSuccess) => {
		setTimeout(() => {
			setEditableRowIndex(index);
			success('success', constants.studyGuide_select_success, 'flashSuccess', messageApi);
			setShowlist(true);
		}, 1000);
	};
	const removeFile = (file, index)=>{
		setEditableRowIndex(null);
	}
	const handleChange = (info, index) => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);
		setFileList(fileList);
	};
	
	const studyguidetabColumns = [
		{
		title: <div className='text-base'>Chapter</div>,
		dataIndex: "chapter_id",
		key: "chapter_id",
		align: "center",
		width: '10%',
		sorter: (a, b) => a.chapter_id - b.chapter_id,
		},
		{
			title: <div className='text-base'>Title</div>,
			dataIndex: "chapter_name",
			key: "chapter_name",
			width: '30%',
		},
		{
			title: <div className='text-base'>File</div>,
			key: "title",
			width: '30%',
			editable: true,
			render:(record)=>{
				return(
					<Tooltip title={`Download ${record.title}`}>
						<div
							className='linkColor cursor-pointer'
							onClick={(e) => {
								fetch(constants.AdminApiUrl + `/download-pdf/${record.resource_file_id}`)
									.then((response) => response.blob())
									.then((blob) => {
										const url = URL.createObjectURL(new Blob([blob]));
										const link = document.createElement('a');
										link.href = url;
										link.download = record.title;
										document.body.appendChild(link);
										link.click();
										URL.revokeObjectURL(url);
										link.remove();
									});
							 }}
							 >
							{record.title}
						</div>
					</Tooltip>
				)
			}
		},
		{
			title: <div className='text-base'>Upload date</div>,
			dataIndex: "uploaded_date",
			key: "uploaded_date",
			width: '10%',
			align:'center'
		},
		{
		title: <div className="text-base">Upload</div>,
		key: "chapter_name",
		align: "center",
		editable: true,
		className:'sss',
		width: '10%',
		 render: (_, record, index) => (
				<Upload
				 maxCount={1}
				 fileList={editableRowIndex === index ? fileList : []}
				 customRequest={({ file }) => handleCustomRequest(file, index)}
				 onChange={(info) => handleChange(info, index)}
				 showUploadList={{
					 showPreviewIcon: false,
					 showRemoveIcon: true,
					 showDownloadIcon: false,
					 showUploadList: false, // Hide the default upload list
					 renderItem: (file) => (
						 <span style={{ marginLeft: 8 }}>{file.name}</span>
					 ),
				 }}
				 onRemove={({ file }) => removeFile(file, index)}
				 beforeUpload={(file) => {
					 const isPDF = file.type === 'application/pdf';
					 if (!isPDF) {
						 success('error', `${file.name} is not a pdf file`, 'flashSuccess', messageApi);
					 }
					 return isPDF && !editableRowIndex;
				 }}
				 className='uploadComponent'
				>
					<Button icon={<UploadOutlined />} disabled={editableRowIndex !== null}>
						Upload
					</Button>
				</Upload>
			),
	},
		{
			title: <div className='text-base'>Action</div>,
			width: '10%',
			key:'record',
			align: 'center',
			render: (_, record, index) => (
				<div className='flex'>
					<Button
				  type="primary"
					icon={<SaveOutlined />}
					onClick={() =>handleUpload(record.chapter_id, record.resource_file_id)}
					disabled={editableRowIndex !== index || fileList.length === 0}
				>
					Save
				</Button>
					<Button
						type="primary"
						className='ml-2'
						icon={<CloseSquareOutlined />}
						onClick={() => removeFile()}
						disabled={editableRowIndex !== index || fileList.length === 0}
					>
						Cancel
					</Button>
				</div>
			
			),
		},
	]

	return (
		<div>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.flashCardpageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						{contextHolder}
							<Table
								bordered
								className='!table-fixed'
								columns={studyguidetabColumns}
								dataSource={studyguidelistData}
								pagination={false}
								rowKey={(record) => record.chapter_id}
								locale={{ emptyText: constants.emptyTableText }}
								scroll={{ x:768}}
								rowClassName={(record, index) => (editableRowIndex === index ? 'editable-row' : '')}
								title={
									() => <div className="font-bold tracking-wide text-lg pl-3 flashTableTitle"> STUDY GUIDE LIST </div>
								}>
							</Table>
					</div>
				</Col>
			</Row>  
		</div>
	)
}

export default StudyGuideList
