import React from 'react';
import {Layout} from 'antd';
import {getTitleDetails} from '../../../functions/functions';
const {Header} = Layout;

const Headers = () => {
	return (
		<>
			<Header className= "h-24 p-0 flex justify-start items-center shadow-lg addQheader admin-header">
				<div>{getTitleDetails()}</div>
			</Header>
		</>
	)
}
export default Headers;
