import React,{useEffect,useState} from 'react';
import constants from '../../../../constants/constants';
import { Chart } from "react-google-charts";
import {
	Row,
	Col,
	Card,
	Avatar
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { HomeOutlined, RightSquareFilled } from '@ant-design/icons';
import { fetchAdminApi } from '../../../../services/api/index';
import { COOKIE, getCookie } from "../../../../services/cookie";

const AdminDashboard = () => {
	const navigate = useNavigate();
	const [stats,setStats] = useState();
	const [feedbackdata, setFeedbackData] = useState([]);
	const [feedbackchartdata, setFeedbackChartData] = useState([]);
	const [registrationcount,setRegistrationCount] = useState();
	const [username,setUsername] = useState('');

	useEffect(() => {
		if (localStorage.getItem('userName')) {
			setUsername(localStorage.getItem('userName'));
		}
		const fetchData = async () => {
			let userId = getCookie(COOKIE.UserId);
			if (userId) {
				try {
					const response = await fetchAdminApi(`/admin-dashboard/${userId}`,'get');
					if (response && response?.data) {
						setStats(response?.data);
						setFeedbackData(response?.data?.feedback);
						setRegistrationCount(response?.data?.registration_count);
						if (response && response?.data.feedback.length > 0) {
							let chartHeader = [['Resource', 'Feedbacks', { role: 'annotation' }]];
							for (let index = 0; index < response?.data?.feedback.length; index++) {
								if (response?.data.feedback[index].count) {
									let all = [];
									all.push(`${(response?.data.feedback[index].status_name)}`, Number(response?.data.feedback[index].count), response?.data.feedback[index].count);
									chartHeader.push(all);
								}
								setFeedbackChartData(chartHeader);
							}
						}
					} else {
						setStats(null);
						setFeedbackData([]);
						setRegistrationCount(0);
					}
				} catch (error) {
					console.error('API error:', error);
				}
			} else {
				navigate('/admin-login');
				console.error('UserId not found in the cookie.');
			}
		};
		fetchData();
		localStorage.removeItem('flashChapterId');
	}, []);

	const options = {
		title: constants.dashboardChartTitle,
		titleTextStyle: {
			fontSize: 19,
			bold: 'true',
			color: '#8a1253',
		},
		vAxis: {
			title: constants.feedbackVerticalAxisLabel,
			textStyle: {
				fontSize: 14,
				color: '#000',
				bold: 'true'
			},
			titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
		},
		bar: { groupWidth: "50%" },
		bars: "horizontal",
		is3D: true,
		colors: ['#506f86'],
		animation: {
			duration: 1000,
			easing: 'linear',
			startup: true
		},
		legend: { position: 'top' },
		'hAxis': {
			title: constants.feedbackHorizontalAxisLabel,
			gridlines: { count: -10 }, minorGridlines: 'NULL', textStyle: { fontSize: 14, color: 'black', bold: 'true' }, format: '####', titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
			minValue: 2,
			viewWindow: {
				min: 0
			},
		},
		chartArea: {right:100,bottom:120},
		tooltip: { isHtml: true },
	};

	const cardArr = [
		{
			title:'Total Registrations',
			value: registrationcount,
			colorClass:'firstCard',
			pageUrl:'/total-registrations',
			block:1,
		},
		{
			title: 'Total Tests Taken',
			value: stats?.tests_count,
			colorClass:'secondCard',
			pageUrl:'/total-tests-taken',
			block: 2,
		},
		{
			title: 'Total Tutorials Taken',
			value: stats?.tutorials_count,
			colorClass:'thirdCard',
			pageUrl: '/total-tutorials-taken',
			block: 3,
		},
		{
			title: 'Total Flashcards Taken',
			value: stats?.flashcards_count,
			colorClass:'fourthCard',
			pageUrl:'/total-flashcards-taken',
			block: 4,
		}
	]

	const getDataAndRedirect = (block) => {
		  if(block == 2 ){
				sessionStorage.setItem("selectedSection", 0)
				navigate('/total-tests-taken');
			}else if(block == 3){
				sessionStorage.setItem("selectedSection", 1)
				navigate('/total-tutorials-taken');
			}else if (block == 4){
				sessionStorage.setItem("selectedSection", 6)
				navigate('/total-flashcards-taken');
			}else{
				navigate('/total-registrations');
			}
	}

	return (
		<div className='cursorChange bg-slate-200'>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen'>
						<Card
							title={<div className='flex items-center py-4'>
							<Avatar
								shape='square'
								style={{
									backgroundColor: '#cf7979',
								}}
								size={33}
								icon={<HomeOutlined className='font-extrabold' style={{ fontSize: '22px' }} />}
									/>
								<div style={{ color:'#247291'}} className='tracking-wide font-sans font-bold text-3xl text-slate-200 ml-4'>{`Welcome back, ${username}!`}</div>
								</div>
								}
						>
							<div className="text-xl bg-slate-100 italics font-serif font-bold mb-5">{constants.dashboardCardSectionTitle}</div>
								<Row gutter={{
									xs: 8,
									sm: 16,
									md: 24,
									lg: 32,
								}}
								className='py-9'>
								{
									cardArr.map((each,index)=>{
										return (
											<Col span={6}>
												<Card key={index} className={`[&:not(:first-child)]:ml-5 w-full h-48 text-center glassBackground relative`} hoverable>
													<a onClick={() => getDataAndRedirect(each.block)} className='cursor-pointer'>
														<div className={`text-xl font-serif font-semibold text-black`}>{each.title}</div> 
														<div className='text-base font-bold my-1'>(Current Year)</div>
														<div style={{color:`${each.countColor}`}} className={`text-5xl font-bold mt-2`}>{each.value}</div>
													</a>
													<div onClick={()=>getDataAndRedirect(each.block)} className='absolute right-1.5 bottom-1.5 cursor-pointer'><RightSquareFilled style={{ fontSize:'28px', color: '#247291' }}/></div>
												</Card>
										</Col>
									)})
								}
								</Row>
							<div className="text-xl bg-slate-100 italics font-serif font-bold mb-5 mt-5">{constants.dashboardChartSectionTitle}</div>
							<Card hoverable>
								<Chart
									chartType="BarChart"
									className='w-full'
									width={'100%'}
									height={'600px'}
									loader={<div>{constants.loadingChartMessage}</div>}
									data={feedbackchartdata}
									options={options}
								/>
							</Card>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default AdminDashboard
