import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className='h-screen grid place-content-center place-items-center'>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button className="rounded-lg bg-blue-700 !text-white" icon={<ArrowLeftOutlined />} onClick={() => navigate('/dashboard')}>Back Home</Button>}
      />
    </div>
  );
};
export default PageNotFound;