import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Avatar, Space } from "antd";
import { useLocation } from 'react-router-dom';
import constants from '../../../constants/constants';
import { getColorClass } from '../../../functions/functions';
import { COOKIE, getCookie } from '../../../services/cookie';
const { Header } = Layout;
const { SubMenu } = Menu;
const StudentHeader = () => {
	const navigate = useNavigate();
	const [mobileMenu, setMobileMenu] = useState(false);
	let location = useLocation();
	let user=getCookie(COOKIE.FirstName);
	const showMobileMenu = () => {
		setMobileMenu(true);
	};
	const closeMobileMenu = () => {
		setMobileMenu(false);
	};
	const items = [
		{
			label: (
			<Space>
				<Avatar className='bg-rose-800 text-white'>U</Avatar>
					<span className='flex items-center'>Uma Thurman</span>
			</Space>),
			key: 'profile',
		},
	];
	return (
		<>
			<div className={`absolute h-24 w-full z-0 opacity-75 bg-gradient-to-b ${getColorClass(location?.state?.chapter_id ? location?.state?.chapter_id : "1","gradientclass")}`}></div>
			<Header id='top' className='!leading-4 !bg-white mt-4 z-10 px-2 lg:px-0'> 
				<div className='flex justify-between items-center h-full container mx-auto'>
					<Space direction='vertical' className='cursor-pointer' size={0} onClick={() => navigate('/dashboard')}> 
						<span className='block lg:hidden text-xl roboto-slab font-normal'>FAPSLH</span>
						<span className='hidden lg:block text-xl roboto-slab font-normal'>{constants.BookName}</span>
						<span className='text-rose-800 font-bold text-xs lg:text-base'>{constants.Author}</span> 
					</Space> 
					{/* <Menu mode="horizontal" disabledOverflow={true} items={items} itemBorderRadius={0} subMenuItemBorderRadius={0}/>  */}
					{getCookie(COOKIE.FirstName) ?
						<Space>
							<Avatar className='bg-rose-800 text-white'>{user?.substring(0, 1)}</Avatar>
							<span className='flex items-center'>{getCookie(COOKIE.FirstName) + " " + getCookie(COOKIE.LastName)}</span>
						</Space> : null}
				</div>
			</Header>
		</>
	)
}

export default StudentHeader;