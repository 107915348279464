/*
File Name: MarkedForLaterTutorial.js
Author: Mevito Gonsalves
Modified On: 27/09/2023
Description: Answering the marked for later and incorrect answered questions.
API's used:
1)
*/
import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, Radio, Space, Input, Divider, Modal, message, Spin, Alert, Progress, Steps } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import $ from "jquery";
import constants from "../../../constants/constants";
import { fetchApi } from "../../../services/api";
import { COOKIE, getCookie } from "../../../services/cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { getColorClass } from "../../../functions/functions";
import { Chart } from "react-google-charts";
import ReactRouterPrompt from "react-router-prompt";
import { featuredImageUrl } from "../../../globalFunctions/GlobalFunctions";

const MarkedForLaterTutorial = (props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [listQuestions, setQuestions] = useState([]);
	const [questionIndex, setQuestionIndex] = useState(0);
	const [refresh, setRefresh] = useState(false);
	const [scoreCardId, setScoreCardId] = useState(null);
	const [nextAndPreviousLoading, setNextAndPreviousLoading] = useState(false);
	const [completeTestLoading, setCompleteTestLoading] = useState(false);
	const [selectedSequenceOption, setSelectedSequenceOption] = useState("");
	//sessionStorage
	const [questionOfDayDetails, setQuestionOfDayDetails] = useState({});
	const [mouseUp, setMouseUp] = useState(0);
	const [mouseDown, setMouseDown] = useState(0);
	const [selectedId, setSelectedId] = useState(null);
	const [onMouseDownId, setonMouseDownId] = useState(null);
	const [browserTime, setbrowserTime] = useState("");
	const [serverTime, setServerTime] = useState("");
	const [showResullt, setShowResult] = useState(false);
	const [isDirty,setIsDirty]=useState(false);
	const [globalPreview,setGlobalPreview]=useState(false);
	const setVisiblePreview=(val)=>{
		setGlobalPreview(val);
	}

	const current = new Date();
	const date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;
	const graphOption = {
		colors: ["#2F8548", "#B91919"],
		backgroundColor: { fill: 'transparent' },
		chartArea: { width: "70%" },
	};

	useEffect(() => {
		setIsDirty(true);
		$(document).keydown(function (event) {
			if (event.keyCode === 123 || event.ctrlKey && event.shiftKey && event.keyCode === 73 || event.ctrlKey && event.keyCode === 67) {
				return false;
			}
		});
		document.addEventListener('copy', disableCopy);
		document.addEventListener('contextmenu', disableContextMenu);
		let listQuestionsFromSession = JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		if (listQuestionsFromSession && listQuestionsFromSession.length > 0) {
			fetchFromLocalStorage();
		} else {
			fetchStartTestData();
		}
	}, []);
	useEffect(() => {
		return () => {
			setIsDirty(false)
			$(document).off('keydown');
			document.removeEventListener('contextmenu', disableContextMenu);
			document.removeEventListener('copy', disableCopy);
			let testCount = sessionStorage.getItem("totalTestsTaken");
			let viewedQod = sessionStorage.getItem("viewedQOD");
			let crashTest = sessionStorage.getItem("crash");
			let feedbackpopupshow = sessionStorage.getItem("feedbackShown");
			sessionStorage.clear();
			sessionStorage.setItem("UserIdSession",getCookie(COOKIE.UserId));
			sessionStorage.setItem("crash", crashTest);
			sessionStorage.setItem("totalTestsTaken", testCount);
			sessionStorage.setItem("viewedQOD", viewedQod);
			sessionStorage.setItem("feedbackShown", feedbackpopupshow);
		};
	}, []);
	function disableCopy(e) {
		e.preventDefault();
	}
	function disableContextMenu(e) {
		e.preventDefault();
	}
	//To fetch the Questions to answer the test of the category type.
	const fetchStartTestData = () => {
		if (!location?.state?.chapter_id) {
			navigate('/dashboard', { replace: true });
		} else {
			setNextAndPreviousLoading(true);
			let userId = getCookie(COOKIE.UserId);
			let payload = {
				stud_id: userId,
				chapter_id: location?.state?.chapter_id,
			};
			if (location?.state?.is_marked_later) {
				payload.is_marked_later = true
			}
			if (location?.state?.is_ans_incorrect) {
				payload.is_ans_incorrect = true;
			}
			fetchApi(`/start-review-tutorial`, "post", payload).then((res) => {
				setNextAndPreviousLoading(false);
				if (res && res.code && res.code === 200) {
					setServerTime(res.current_date ? res.current_date : "");
					let browerDateTime = new Date();
					setbrowserTime(browerDateTime.toString());
					if (res.data && res.data.questions && res.data.questions.length > 0) {
						for (let i = 0; i < res.data.questions.length; i++) {
							res.data.questions[i].selectedAnswer = [];
							res.data.questions[i].matchAns = false;
							let matchAns = true;
							res.data.questions[i].checkAnswer = "false";
							let correct_option = [];
							let correctOptionConverted = [];
							for (let j = 0; j < res.data.questions[i].correct_option?.length; j++) {
								let converted = Number(res.data.questions[i].correct_option[j]);
								correct_option.push(converted);
								correctOptionConverted.push(constants.OptionstoDisplay[converted]);
							}
							res.data.questions[i].correct_option = correct_option;
							res.data.questions[i].correct_option_converted = correctOptionConverted;
						}
						if (res.data && res.data.scorecard_id) {
							sessionStorage.setItem('scoreCardId', res.data.scorecard_id);
							setScoreCardId(res.data.scorecard_id);
						}
						sessionStorage.setItem("listQuestions", JSON.stringify(res.data.questions));
						sessionStorage.setItem("questionIndex", 0);
						sessionStorage.setItem("testType", "tutorial");
						let listQuestionsFromSession = JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
						setQuestions(listQuestionsFromSession);
					}
				} else {
					message.error(res.message);
					navigate('/chapter-resources', { replace: true });
				}
			});
		}
	};
	const fetchFromLocalStorage = () => {
		let listQuestionsFromSession = JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		let questionIndexFromSession = sessionStorage.getItem("questionIndex");
		setQuestions(listQuestionsFromSession);
		setScoreCardId(Number(sessionStorage.getItem("scoreCardId")));
		setQuestionIndex(Number(questionIndexFromSession));
		setShowResult(sessionStorage.getItem("showResult") === "t" ? true : false);
	};
	//Executes on click of Next button.
	const handleNextQuestion = (btnClickType) => {
		if (listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0) {
			var match = true;
			if (match === true) {
				let payload = {};
				var today = new Date();
				var dd = String(today.getDate()).padStart(2, '0');
				var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = today.getFullYear();
				today = yyyy + '-' + mm + '-' + dd;
				if (listQuestions[questionIndex].q_type === "1") {
					var sequence = questionIndex + 1;
					payload.test_data = {
						seq: sequence,
						q_id: Number(listQuestions[questionIndex].question_id),
						user_ans: listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 ? listQuestions[questionIndex].selectedAnswer : [],
						corr_ans: listQuestions[questionIndex].correct_option && listQuestions[questionIndex].correct_option.length > 0 ? listQuestions[questionIndex].correct_option : [],
					};
				}
				if (location?.state?.is_marked_later) {
					payload.is_answered=true;
				}else{
					if(listQuestions[questionIndex].matchAns===true){
						payload.is_answered=true;
					}else{
						payload.is_answered=false;
					}
				}
				payload.test_status = 2;
				payload.question_id=Number(listQuestions[questionIndex].question_id);
				payload.scorecard_id=scoreCardId;
				if (location?.state?.is_marked_later) {
					payload.is_marked_later = true;
					payload.type="is_marked_later";
				}
				if (location?.state?.is_ans_incorrect) {
					payload.is_ans_incorrect = true;
					payload.type="is_ans_incorrect";
				}
				let url = ``;
				url += `/update-review-tutorial`;
				nextQuestionApi(payload, url, btnClickType);
			} else {
					// message.error(constants.SelectOptionOnly);
					message.error({
						content:constants.SelectOptionOnly,
						style:{ marginTop : '30vh' }
					});
				}
		} else {
			// message.error(constants.SelectOptionOnly);
			message.error({
				content:constants.SelectOptionOnly,
				style:{ marginTop : '30vh' }
			});
		}
	};
	const nextQuestionApi = (payload, url, btnClickType) => {
		setNextAndPreviousLoading(true);
		fetchApi(url, "put", payload)
			.then((res) => {
				setServerTime(res.current_date ? res.current_date : "");
				let browerDateTime = new Date();
				setbrowserTime(browerDateTime.toString());
				setNextAndPreviousLoading(false);
				if (res && res.code && res.code === 200) {
					setNextAndPreviousLoading(false);
					if (btnClickType === "PREVIOUS") {
						sessionStorage.setItem("questionIndex", questionIndex - 1);
						setQuestionIndex(questionIndex - 1);
					} else {
						sessionStorage.setItem("questionIndex", questionIndex + 1);
						setQuestionIndex(questionIndex + 1);
					}
				} else {
					setNextAndPreviousLoading(false);
					sessionStorage.setItem("quitTest", "true");
					setNextAndPreviousLoading(false);
					if (res.error_message === "Invalid token") {
						message.error(constants.LoggedInAnotherBrowser);
					} else {
						if (res.code === 600) {
							message.error(res.message);
						} else {
							message.error("Failed");
						}
					}
				}
			});
	};
	//Executes on click of Previous button.
	// const handlePreviousQuestion=()=>{
	// 	setNextAndPreviousLoading(true);
	// 	setColumns(constants.columnsOfDragAndDrop);
	// 	sessionStorage.setItem("questionIndex",questionIndex-1);
	// 	setQuestionIndex(questionIndex-1);
	// 	setNextAndPreviousLoading(false);
	// };
	const calculatePercentage = (count, total) => {
		return (count / total) * 100;
	}
	const handlePreviousQuestion = () => {
		if (listQuestions && listQuestions.length > 0) {
			if (listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 || listQuestions[questionIndex].markedForLater === true) {
				handleNextQuestion("PREVIOUS");
			} else {
				setNextAndPreviousLoading(true);
				sessionStorage.setItem("questionIndex", questionIndex - 1);
				setQuestionIndex(questionIndex - 1);
				setNextAndPreviousLoading(false);
			}
		}
	};
	//Function executes on click of Return to tutorial button
	const completeTestApi = (isCrashed) => {
		if (listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0) {
			var match = true;
			if (match === true) {
				let payload = {};
				if (listQuestions[questionIndex].q_type === "1") {
					var sequence = questionIndex + 1;
					payload.test_data = {
						seq: sequence,
						q_id: Number(listQuestions[questionIndex].question_id),
						user_ans: listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 ? listQuestions[questionIndex].selectedAnswer : [],
						corr_ans: listQuestions[questionIndex].correct_option && listQuestions[questionIndex].correct_option.length > 0 ? listQuestions[questionIndex].correct_option : [],
					};
				}
				if (location?.state?.is_marked_later) {
					payload.is_answered=true;
				}else{
					if(listQuestions[questionIndex].matchAns===true){
						payload.is_answered=true;
					}else{
						payload.is_answered=false;
					}
				}
				if (location?.state?.is_marked_later) {
					payload.is_marked_later = true
					payload.type="is_marked_later";
				} else {
					payload.is_marked_later = false
				}
				if (location?.state?.is_ans_incorrect) {
					payload.is_ans_incorrect = true;
					payload.type="is_ans_incorrect";
				}
				payload.test_status = 4;
				payload.question_id=Number(listQuestions[questionIndex].question_id);
				payload.scorecard_id=scoreCardId;
				const date = new Date();
				payload.dt_completed = date;
				let url = ``;
				url += `/update-review-tutorial`;
				submitTutorialApi(payload, url);
			} else {
				if(isCrashed==="f"){
					message.error({
						content:constants.SelectOptionOnly,
						style:{ marginTop : '30vh' }
					});
				}
			}
		} else {
			if(isCrashed==="f"){
				message.error({
					content:constants.SelectOptionOnly,
					style:{ marginTop : '30vh' }
				});
			}
		}
	};
	const submitTutorialApi = (payload, url) => {
		setCompleteTestLoading(true);
		fetchApi(url, "put", payload)
			.then((res) => {
				if (res && res.code && res.code === 200) {
					setServerTime(res.current_date ? res.current_date : "");
					let browerDateTime = new Date();
					setbrowserTime(browerDateTime.toString());
					setCompleteTestLoading(false);
					const state = {
						chapter_id: location?.state?.chapter_id
					};
					navigate('/chapter-resources', { state, replace: true });
				} else {
					setCompleteTestLoading(false);
					sessionStorage.setItem("quitTest", "true");
					setNextAndPreviousLoading(false);
					if (res.error_message === "Invalid token") {
						message.error(constants.LoggedInAnotherBrowser);
					} else {
						if (res.code === 600) {
							message.error(res.message);
						} else {
							message.error("Failed");
						}
					}
				}
				setCompleteTestLoading(false);
			});
	};
	//On check function for single select question options.
	const handleSingleSelectAnswer = e => {
		if (e.target.value) {
			if (listQuestions && listQuestions.length > 0) {
				listQuestions[questionIndex].selectedAnswer = [e.target.value];
				let tempCorrectOption = listQuestions[questionIndex].answers.filter((item) => { return item.corr_opt === "t" });
				listQuestions[questionIndex].correct_option = [tempCorrectOption[0].answer_option_id];
				listQuestions[questionIndex].matchAns = (listQuestions[questionIndex].selectedAnswer.length == listQuestions[questionIndex].correct_option.length) && listQuestions[questionIndex].selectedAnswer.every(function (element, index) {
					return element === listQuestions[questionIndex].correct_option[index];
				});
				sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				setQuestions(listQuestions);
				setRefresh(!refresh);
			}
		}
	};
	//Function to set the coordinate of selected text on Mouse down for highlighting the text
	const handleMouseDown = (e, id) => {
		setonMouseDownId(Number(id));
		setSelectedSequenceOption("");
		if (e.changedTouches && e.changedTouches[0] && e.changedTouches[0].pageX) {
			setMouseDown(e.changedTouches[0].pageX);
		} else {
			setMouseDown(e.pageX);
		}
		setRefresh(!refresh);
	};
	//Function to set the coordinate of selected text on Mouse up for highlighting the text
	const handleMouseUp = (e, id) => {
		setSelectedId(Number(id));
		setSelectedSequenceOption("");
		if (e.changedTouches && e.changedTouches[0] && e.changedTouches[0].pageX) {
			setMouseUp(e.changedTouches[0].pageX);
		} else {
			setMouseUp(e.pageX);
		}
		setRefresh(!refresh);
	};
	//Functions to get classes for correct incorrect options
	const checkAnswerDisplay = (type) => {
		if (listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0) {
			listQuestions[questionIndex].checkAnswer = "true";
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
			setQuestions(listQuestions);
			setRefresh(!refresh);
		} else {
			// message.error(constants.SelectOptionOnly);
			message.error({
				content:constants.SelectOptionOnly,
				style:{ marginTop : '30vh' }
			});
		}
	};
	const getCorrectIncorrectClass = (value) => {
		if (listQuestions && listQuestions.length > 0) {
			if (Number(value) === Number(listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].correct_option[0])) {
				return 'border border-green-600'
			} else if (Number(value) !== Number(listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].correct_option[0]) && Number(value) === Number(listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].selectedAnswer[0])) {
				return 'border border-red-600'
			} else {
				return ''
			}
		}
	}
	const getCorrectIncorrectIcon = (value) => {
		if (listQuestions && listQuestions.length > 0) {
			if (Number(value) === Number(listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].correct_option[0])) {
				return <CheckOutlined className="text-green-600 pr-2" />
			} else if (Number(value) !== Number(listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].correct_option[0]) && Number(value) === Number(listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].selectedAnswer[0])) {
				return <CloseOutlined className="text-red-600 pr-2" />
			} else {
				return ''
			}
		}
	}
	// Function to append alphabets to the selected option
	const appendAlphabet = (option) => {
		const alphabet = String.fromCharCode(65 + option);
		return alphabet;
	};
	const singleSelect = (
		<div>
			<div>
				<p className="text-2xl font-normal mb-2" id="99" onTouchStart={(e) => { handleMouseDown(e, 99); }} onTouchEnd={(e) => { handleMouseUp(e, 99); }} onMouseDown={(e) => { handleMouseDown(e, 99); }} onMouseUp={(e) => { handleMouseUp(e, 99); }} dangerouslySetInnerHTML={{ __html: listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].question_text ? listQuestions[questionIndex].question_text : "" }}></p>
				<div className="text-center">{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].image_name && (featuredImageUrl(listQuestions[questionIndex].image_name, listQuestions[questionIndex].orig_img,globalPreview,setVisiblePreview,listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].question_text ? listQuestions[questionIndex].question_text : ""))}</div>
			</div>
			<Radio.Group
				className="w-full radio-test"
				onChange={handleSingleSelectAnswer}
				value={listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].selectedAnswer && listQuestions[questionIndex].selectedAnswer.length > 0 && listQuestions[questionIndex].selectedAnswer[0]}
			>
				{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].checkAnswer && listQuestions[questionIndex].checkAnswer === "true" ?
					(<div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
						{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].answers.map((option, index) => {
							return <Radio
								value={option.answer_option_id}
								id={option.answer_option_id}
								className={getCorrectIncorrectClass(option.answer_option_id) + " bg-slate-200 p-2 rounded-lg !text-black pl-10 relative items-center"}
								disabled
							>
								<span className="absolute left-0 font-semibold border border-transparent border-r-slate-300 w-8 text-center grid place-content-center h-full top-0">{appendAlphabet(index)}</span>
								<Space>
									<span>{getCorrectIncorrectIcon(option.answer_option_id)}</span>
									<p id={option.option_id} dangerouslySetInnerHTML={{ __html: option.option_text }}></p>
								</Space>
							</Radio>
						})
						}
					</div>)
					:
					(<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
						{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].answers.map((option, index) => {
							return <Radio
								value={option.answer_option_id}
								id={option.answer_option_id}
								className="bg-slate-200 p-2 rounded-lg pl-10 relative items-center"
							>
								<span className="absolute left-0 font-semibold border border-transparent border-r-slate-300 w-8 text-center grid place-content-center h-full top-0">{appendAlphabet(index)}</span>
								<p id={option.option_id} dangerouslySetInnerHTML={{ __html: option.option_text }}></p>
							</Radio>
						})
						}
					</div>)}
			</Radio.Group>
		</div>
	);
  // const imageSelect = (
	// 	<div className="grid gap-2">
	// 		<div className="font-normal text-xl">{listQuestions && listQuestions.length > 0 ?
	// 			<span>Q: {questionIndex + 1}/{listQuestions && listQuestions.length}</span>
	// 			: ""}</div>
	// 		<div className="lg:w-3/4 mx-auto grid gap-3">
	// 			<div>
	// 				<p className="text-2xl font-normal mb-2" id="99" onTouchStart={(e) => { handleMouseDown(e, 99); }} onTouchEnd={(e) => { handleMouseUp(e, 99); }} onMouseDown={(e) => { handleMouseDown(e, 99); }} onMouseUp={(e) => { handleMouseUp(e, 99); }} dangerouslySetInnerHTML={{ __html: listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].question_text ? listQuestions[questionIndex].question_text : "" }}></p>
	// 				<div className="text-center">{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].image_name && (featuredImageUrl(listQuestions[questionIndex].image_name, listQuestions[questionIndex].orig_img))}</div>
	// 			</div>
  //       {listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].checkAnswer && listQuestions[questionIndex].checkAnswer === "true" ?
	// 			(<div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
	// 				{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex]?.answers?.map((option, index) => {
	// 					return <div className="flex items-center">
	// 						<span className="relative font-semibold border border-transparent border-r-slate-300 w-8 text-center">{appendAlphabet(index)}</span>
	// 						<Select className="!w-full" value={option?.selectedOption ? option.selectedOption[0] : null} onChange={(e) => onSelectOptionChange(e, index)}>
	// 							{option?.other_opt1?.map((optionInner) => {
	// 								return <Option value={optionInner.answer_option_id}><p id={optionInner.answer_option_id} dangerouslySetInnerHTML={{ __html: optionInner.option_text }}></p></Option>
	// 							})}
	// 						</Select></div>
	// 				})
	// 				}
	// 			</div>):
  //       (<div>
	// 				<div className="grid grid-cols-2 gap-16 font-bold mb-3">
	// 					<span>You answered</span>
	// 					<span>Correct answer</span>
	// 				</div>
	// 				{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex]?.answers?.map((option, index) => {
	// 					return <Row gutter={[24, 0]} className="mb-3">
	// 						<Col span={12}>
	// 							<div className="flex items-center">
	// 								<span className={getCorrectIncorrectColorClass(option.answer_option_id, index) + " font-medium w-8 text-center text-xl"}>{appendAlphabet(index)}</span>
	// 								<span>{getCorrectIncorrectImageIcon(option.answer_option_id, index)}</span>
	// 								<Select className={getCorrectIncorrectDropdownClass(option.answer_option_id, index) + " !w-full rounded-md"} value={option?.selectedOption ? option.selectedOption[0] : null} onChange={(e) => onSelectOptionChange(e, index)} disabled>
	// 									{option?.other_opt1?.map((optionInner) => {
	// 										return <Option value={optionInner.answer_option_id}><p className="!text-black" id={optionInner.answer_option_id} dangerouslySetInnerHTML={{ __html: optionInner.option_text }}></p></Option>
	// 									})}
	// 								</Select>
	// 							</div>
	// 						</Col>
	// 						{
	// 							listQuestions && listQuestions.length > 0 ?
	// 								Number(listQuestions
	// 									&& listQuestions.length > 0
	// 									&& listQuestions[questionIndex].user_ans[index]) ===
	// 									Number(listQuestions
	// 										&& listQuestions.length > 0
	// 										&& listQuestions[questionIndex].corr_ans[index])
	// 									?
	// 									null :
	// 									<Col span={12}>
	// 										<div className="flex items-center">
	// 											<span><CheckOutlined className="text-green-600 pr-2" /></span>
	// 											<Select className={"border border-green-600 !w-full rounded-md"} value={option?.answer_option_id ? option.answer_option_id : null} disabled>
	// 												{listQuestions[questionIndex]?.answers?.map((optionIn) => {
	// 													return <Option value={optionIn.answer_option_id}><p className="!text-black" id={optionIn.answer_option_id} dangerouslySetInnerHTML={{ __html: optionIn.option_text }}></p></Option>
	// 												})}
	// 											</Select>
	// 										</div>
	// 									</Col>
	// 								: ""
	// 						}
	// 						{/* {getCorrectDropdown(option.answer_option_id,index)} */}
	// 					</Row>
	// 				})
	// 				}
	// 			</div>)}
	// 		</div>
	// 	</div>
	// );
	//Back button popup functions
	function delayPromise(ms = 1000) {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
			completeTestApi("t");
		});
	}
	return (
		<div>
			<div
				style={{ backgroundColor: getColorClass(location?.state?.chapter_id, "colorcode") }}
				className='text-white w-12 lg:w-36 roboto-slab font-bold text-4xl flex justify-center items-end pb-3.5 absolute right-0 top-0 h-36'
			>
				{location?.state?.chapter_id}
			</div>
			<div className='container mx-auto'>
				<div className='flex justify-between items-center'>
					<div style={{ color: getColorClass(location?.state?.chapter_id, "colorcode") }} className='text-base lg:text-2xl w-4/5 lg:w-auto underline underline-offset-4 font-extrabold uppercase'>{location?.state?.chapter_name}</div>
				</div>
				<Divider className='my-2' />
				<div className='flex flex-wrap justify-between items-center my-3 px-5'>
					<div className='font-bold text-xl'>Tutorial {location?.state?.is_marked_later === true ? "- Marked For Later" : location?.state?.is_ans_incorrect === true ? "- Incorrect Answered" : "#" + location?.state?.attempt} </div>
					{showResullt === true ?
						<div className="w-1/2">
							{/* <Steps
								current={2}
								items={[
									{
										title: 'Instructions',
									},
									{
										title: 'Take a tutorial',
									},
									{
										title: 'Result',
									},
								]}
							/> */}
						</div>
						: null}
				</div>
				{showResullt === false ?
					<div className='px-5'>
						<Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
							<div className="grid gap-2">
								<div className="font-normal text-xl">{listQuestions && listQuestions.length > 0 && <span>{questionIndex + 1}/{listQuestions && listQuestions.length}</span>}</div>
								<div className="w-full lg:w-3/4 mx-auto grid gap-3">
									<Spin spinning={nextAndPreviousLoading} size="large" >
										{singleSelect}
									</Spin>
									{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].q_type && listQuestions[questionIndex].q_type !== "1" && listQuestions[questionIndex].checkAnswer === "true" ? (<div>
										{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].matchAns !== null &&
											listQuestions[questionIndex].matchAns === true ?
											<Alert message="Correct" type="success" showIcon /> : <Alert message="Incorrect" type="error" showIcon />}
									</div>) : ""}
									{listQuestions &&
										listQuestions.length > 0 && listQuestions[questionIndex].q_type && listQuestions[questionIndex].q_type !== "1" && listQuestions[questionIndex].checkAnswer === "true"
										? (
											<div>
												<Card bordered={false} className="ans-display">
													<Row gutter={[0, 12]}>
														<Col span={24}><b>Your Answer: </b> {
															listQuestions &&
																listQuestions.length > 0 &&
																listQuestions[questionIndex].user_answer_converted &&
																listQuestions[questionIndex].user_answer_converted.length > 0 ?
																listQuestions[questionIndex].user_answer_converted.join(", ") : ""}</Col>
														<br />
														<Col span={24}><b>Correct Answer: </b> {listQuestions &&
															listQuestions.length > 0 &&
															listQuestions[questionIndex].correct_option_converted &&
															listQuestions[questionIndex].correct_option_converted.length > 0 ?
															listQuestions[questionIndex].correct_option_converted[0].includes("/") ? listQuestions[questionIndex].correct_option_converted[0].replaceAll("/", ", ") :
																listQuestions[questionIndex].correct_option_converted.join(", ")
															: listQuestions &&
															listQuestions.length > 0 &&
															listQuestions[questionIndex].correct_option_converted && listQuestions[questionIndex].correct_option_converted}</Col>
													</Row>
												</Card></div>)
										: ""
									}
									{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].checkAnswer && listQuestions[questionIndex].checkAnswer === "true" && listQuestions[questionIndex].matchAns !== null &&
										listQuestions[questionIndex].matchAns === false ?
										<div>
											<Card title="Explanation" size="small" className="border-slate-200">
												{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].explanation ? <div dangerouslySetInnerHTML={{ __html: listQuestions[questionIndex].explanation }}></div> : ""}
											</Card>
										</div> : ""
									}
									<div>
										<Space wrap>
											{questionIndex > 0 &&
												<Button type="primary" className="!bg-green-700 hover:!bg-green-600" onClick={() => { handlePreviousQuestion(); }} disabled={nextAndPreviousLoading} ><ArrowLeftOutlined />{constants.Previous}</Button>}
											{listQuestions && questionIndex < listQuestions.length - 1 && listQuestions[questionIndex].checkAnswer && listQuestions[questionIndex].checkAnswer === "true" &&
												(<Button type="primary" className="!bg-green-700 hover:!bg-green-600" onClick={() => { handleNextQuestion(); }} disabled={nextAndPreviousLoading}>{constants.Next}<ArrowRightOutlined /></Button>
												)}
											{listQuestions && listQuestions.length > 0 && listQuestions[questionIndex].checkAnswer === "false" && (<div>
												<Button type="primary" className="!bg-green-700 hover:!bg-green-600" onClick={() => { checkAnswerDisplay(); }}>Check</Button>
											</div>)}
											{listQuestions &&
												listQuestions.length > 0 &&
												questionIndex === listQuestions.length - 1 &&
												listQuestions[questionIndex].checkAnswer &&
												listQuestions[questionIndex].checkAnswer === "true" ?
												<Space>
													<Button type="primary"
														onClick={() => {
															// setShowResult(true);
															sessionStorage.setItem("showResult", "t");
															setIsDirty(false);
															completeTestApi("f");
														}}
														// loading={completeTestLoading}
                            >
														Finish
													</Button>
                          <div className='text-green-700 text font-semibold'>You answered {listQuestions.filter(x => x.matchAns === true).length}/{listQuestions.length} questions correctly</div>
												</Space>
												: null}
											{listQuestions && listQuestions.length > 0 && questionOfDayDetails && questionOfDayDetails.type === "qod" && listQuestions[questionIndex].checkAnswer === "true" && (
												<Button type="primary" danger icon={<CloseOutlined />} onClick={() => { saveQuestionOfTheDay("CLOSE"); }} loading={completeTestLoading}>Close</Button>
											)}
										</Space>
									</div>
								</div>
								<div className='mt-2 bg-white p-3 rounded-lg shadow-lg'>
									<Progress className='!mb-0' percent={calculatePercentage(questionIndex + 1, listQuestions && listQuestions.length)} showInfo={false} />
								</div>
							</div>
						</Card>
					</div> :
					<div className="px-5">
						<Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
							<div class="grid grid-cols-1 lg:grid-cols-2 divide-x">
								<div>
									<div className='text-green-700 text-xl font-semibold'>You answered {listQuestions.filter(x => x.matchAns === true).length}/{listQuestions.length} questions correctly</div>
									<div className="font-semibold">{"Date: " + date}</div>
									<Chart
										width="100%"
										height="300px"
										chartType="PieChart"
										// loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
										data={[
											["Answered", "Total"],
											["Correct", Number(listQuestions.filter(x => x.matchAns === true).length)],
											["Incorrect", Number(listQuestions.length) - Number(Number(listQuestions.filter(x => x.matchAns === true).length))],
										]}
										options={graphOption}
									/>
								</div>
								<div className="grid place-content-center place-items-center">
									<div className="grid gap-3 lg:w-56">
										{location?.state?.is_marked_later === true || location?.state?.is_ans_incorrect === true ? null :
											<Button
												onClick={() => {
													sessionStorage.clear();
													sessionStorage.setItem("UserIdSession",getCookie(COOKIE.UserId));
													let state = {
														chapter_id: location?.state?.chapter_id,
														attempt: location?.state?.attempt,
														chapter_name: location?.state?.chapter_name
													}
													navigate('/tutorial-review', { state, replace: true })
												}
												}
												className='rounded-lg bg-blue-700 text-white text-left'
												icon={<ArrowLeftOutlined />}
												block
												size="large"
											>
												{constants.ReviewAnswers}
											</Button>}
										<Button
											onClick={() => {
												const state = {
													chapter_id: location?.state?.chapter_id
												};
												navigate('/chapter-resources', { state, replace: true });
											}}
											className='rounded-lg bg-blue-700 text-white text-left'
											icon={<ArrowLeftOutlined />}
											block
											size="large"
										>
											{constants.BackToChapter}
										</Button>
										<Button
											onClick={() => navigate('/dashboard', { replace: true })}
											icon={<ArrowLeftOutlined />}
											className='rounded-lg bg-blue-700 text-white text-left'
											block
											size="large"
										>
											{constants.Dashboard}
										</Button>
									</div>
								</div>
							</div>
						</Card>
					</div>
				}
				<div>
					<div style={{ visibility: "hidden" }}>
						<b>Server time:</b> {serverTime ? serverTime : ""}{"           "}
						<b>Browser time:</b> {browserTime ? browserTime : ""}
					</div>
				</div>
				{/* {categoryDetails && categoryDetails.type === "tutor" || topicDetails && topicDetails.type === "tutor" || customDetails && customDetails.type === "tutor" || markForLaterAndAnswerIncorrectDetails && markForLaterAndAnswerIncorrectDetails.isMarkedLater === true || markForLaterAndAnswerIncorrectDetails && markForLaterAndAnswerIncorrectDetails.isAnsIncorrect === true ?
					Prompt
					: null} */}
			</div>
			<ReactRouterPrompt 
				when={isDirty}
				beforeConfirm={async () => {
          await delayPromise();
        }}
			>
				{({ isActive, onConfirm, onCancel }) =>
						isActive && (
							<Modal 
								open={isActive}
								footer={null}
								closeIcon={null}
							>
								<div>
									<p>{constants.TutorialBackNavigationMessage}</p>
								<div className="text-right mt-3">
										<Space>
											<Button className="bg-blue-700 text-white" onClick={onCancel}>
												Cancel
											</Button>
											<Button type="submit" className="bg-green-700 hover:bg-green-600 text-white" onClick={onConfirm}>
												Ok
											</Button>
										</Space>
									</div>
								</div>
							</Modal>
						)
					}
			</ReactRouterPrompt>
		</div>
	);
};
export default MarkedForLaterTutorial;
