import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spin, Divider, Select, Card, Space, Button, Progress, Tooltip, message, Carousel, Steps, Badge, Col, List, Skeleton } from 'antd';
import { getColorClass } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { fetchApi } from '../../../services/api';
import ReactCardFlip from 'react-card-flip';
import {
	PlayCircleOutlined,
	RetweetOutlined,
	FlagOutlined,
	BlockOutlined,
	ArrowLeftOutlined,
	ArrowRightOutlined,
	CloseOutlined,
	FlagFilled,
	CheckCircleOutlined,
	CopyOutlined,
	UnorderedListOutlined
} from '@ant-design/icons';
import { Chart } from "react-google-charts";
import { COOKIE, getCookie } from '../../../services/cookie';
const Flashcards = () => {
	const navigate = useNavigate();
	const userId = getCookie(COOKIE.UserId);
	// Slide
	const carouselRef = React.useRef();
	const carouselRefQnA = React.useRef();
	const [carouselEffect, setCarouselEffect] = useState("fade");
	const isFlippedref = useRef(false);
	const location = useLocation();
	const [scoreCardId,setScoreCardId] = useState(location?.state?.score_card_id );
	const [scoreCardIdMFL] = useState(location?.state?.mark_for_later_score_card_id);
	const [chapterId] = useState(location?.state?.chapter_id);
	const [flashDisplay,setFlashDisplay] = useState(location?.state?.start_with);
	const [flashCardTittle, setFlashcardTittle] = useState("");
	const [flashCardQnAListData, setFlashCardQnAListData] = useState([]);
	const [startWith, setStartWith] = useState("Question");
	const [progressPercent, setProgressPercent] = useState(0);
	const [viewedList, setViewedList] = useState([]);
	const [browserTime, setbrowserTime] = useState("");
	const [serverTime, setServerTime] = useState("");
	// Card Flip
	const [isFlipped, setisFlipped] = useState(false);
	const [playFlashCard, setFlashCard] = useState(false);
	const [seconds, setSeconds] = useState(1);
	const [nextDisable, setNextDisable] = useState(false);
	const [prevDisable, setPrevDisable] = useState(false);
	const [reviewedCardCount, setReviewedCardCount] = useState(1);
	const [dateFirstCompleted, setDateFirstCompleted] = useState("");
	const [reviewedList, setReviewedList] = useState([]);
	const [initialCarousalSlide,setInitialCarousalSlide] = useState(location?.state?.reviewedCount ? location?.state?.reviewedCount : 0);
	const [counter, setCounter] = useState(location?.state?.reviewedCount ? location?.state?.reviewedCount : 0);
	const [displayFlashcard, setDisplayFlashcard] = useState([]);
	// const [isReload,setIsReload]=useState(false);
	const [flashCardMarked, setFlashCardMarked] = useState([]);
	const [flashcardTestListData, setFlashcardTestListData] = useState([]);
	const [flashcardTestJSON, setFlashcardTestJSON] = useState([]);
	const [showResult, setShowResult] = useState(false);
	const [isListView,setIsListView]=useState(false);
	// const [disable, setDisable] = useState(false);
	//curent date
	const current = new Date();
	const date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;
	const [listReload,setListReload]=useState(false);
	const buttonRef = useRef(null);
	useEffect(() => {
		if (location?.state?.start_with === "test-yourself") {
			getFlashcardTestList()
		} else {
			getFlashcardList();
		}
	}, []);
	useEffect(() => {
		document.addEventListener('keydown', event => {
			if (event.code === 'Space') {
				// Remove focus from the button
				buttonRef?.current?.blur();
				isFlippedref.current = !isFlippedref.current;
				handleFlip();
			} else if (event.code === "ArrowRight") {
				handleNext();
			} else if (event.code === "ArrowLeft") {
				handlePrevious();
			}
		});
		//Refresh page
		document.addEventListener('copy', disableCopy);
		return () => {
			document.removeEventListener('copy', disableCopy);
		};
	}, [listReload]);
	function disableCopy(e) {
		e.preventDefault();
	}
	useEffect(() => {
		if (playFlashCard === true) {
			const interval = setInterval(() => {
				setSeconds((second) => (second === constants.SecondsPerSlideNum ? 1 : second + 1));
			}, 1000);
			if (seconds === (constants.SecondsPerSlideNum / 2) || seconds === (constants.SecondsPerSlideNum - 2)) {
				setisFlipped(true);
			}
			if (seconds === constants.SecondsPerSlideNum && !nextDisable) {
				handleNext();
			}
			return () => clearInterval(interval);
		}
	}, [playFlashCard, seconds]);
	//To get display data
	function getDisplayFlashList() {
		switch (flashDisplay) {
			case 'All':
				return flashCardQnAListData;
			case 'Marked':
				return flashCardMarked;
			case 'test-yourself':
				return flashcardTestListData;
			default:
				return flashCardQnAListData;
		}
	}
	const getFlashcardTestList = () => {
		setShowResult(false);
		let payload = {
			stud_id: userId,
			chapter_id: chapterId
		};
		setFlashcardTittle(location?.state?.chapter_name);
		fetchApi(`/flashcards-test-yourself `, "post", payload).then((res) => {
			if (res?.code === 200 && res?.data) {
				setFlashcardTestListData(res?.data?.test_flashcards);
				setScoreCardId(res?.score_card_id);
				let flashcardTestJSON = [];
				for (let index = 0; index < res?.data?.test_flashcards?.length; index++) {
					const element = res?.data?.test_flashcards?.[index];
					let tempobj = {
						flashcard_id : element?.flashcard_id,
						is_answered : false,
						is_correct : null
					}
					flashcardTestJSON.push(tempobj);
				};
				setFlashcardTestJSON(flashcardTestJSON);
			} else {
				setFlashcardTestListData([]);
			}
		});
	}
	const onChangeCarouselref = (a, b, c) => {
		setisFlipped(false);
		let markValueArrayAdd = getDisplayFlashList();
		let tempLength = markValueArrayAdd.length;
		setNavigationButtonDisable(b, tempLength);
		setDisplayFlashcard(flashDisplay === "All" ? flashCardQnAListData : flashDisplay === "test-yourself" ? flashcardTestListData : flashCardMarked);
		let count = b + 1;
		let progressPercentTemp = (count / tempLength) * 100;
		setProgressPercent(progressPercentTemp);
		setCounter(b);
		setSeconds(1);
		reviewedCardCountset(count, tempLength);
		let tempArrayView = viewedList;
		if (flashDisplay !== 'Marked' && flashDisplay !== "test-yourself") {
			let tempList = flashCardQnAListData;
			let viewItem = tempList[b];
			if (viewItem) {
				let indexMainArray = flashCardQnAListData.indexOf(viewItem);
				flashCardQnAListData[indexMainArray].review = true;
				flashCardQnAListData[indexMainArray].viewed_on = new Date();
				let CheckPresentvalue = viewedList.some(el => el.flashcard_id === viewItem.flashcard_id);
				let viewTempItem = {
					flashcard_id: viewItem.flashcard_id,
					viewed_on: true,
					is_marked: false,
				};
				if (CheckPresentvalue === false) {
					tempArrayView = [...tempArrayView, viewTempItem];
					setViewedList(tempArrayView);
				}
				else {
					let tempIndexValue = tempArrayView.findIndex(el => el.flashcard_id === viewItem.flashcard_id);
					if (tempArrayView[tempIndexValue].viewed_on === null) {
						tempArrayView[tempIndexValue].viewed_on = true;
					}
					setViewedList(tempArrayView);
				}
			}
		}
		if (count === tempLength) {
			setTimeout(() => {
				setFlashCard(false);
			},
				constants.SecondsPerSlide
			);
			setSeconds(1);
		}
		if (flashDisplay !== "test-yourself") {
			updateFlashcard(tempArrayView);
		}
	};
	function reviewedCardCountset(count, tempLength) {
		setReviewedCardCount(count > tempLength ? tempLength : count);
	};
	function getMarkToolTip() {
		return displayFlashcard[counter]?.marked === true ? "Unmark" : "Mark for later";
	};
	function getMarkIcon() {
		switch (displayFlashcard[counter]?.marked) {
			case true:
				return <FlagFilled />;
			case false:
				return <FlagOutlined />;
		}
	}
	function bookmarkFold() {
		switch (displayFlashcard[counter]?.marked) {
			case true:
				return "foldtr";
			case false:
				return "";
		}
	}
	function onMarkedClick(value=null) {
		let item = value !== null ? value : displayFlashcard[counter];
		let markValue = value !== null ? value?.marked : displayFlashcard[counter]?.marked;
		item.marked = !markValue;
		let tempArr = [];
		tempArr = viewedList;
		let tempIndex = tempArr.findIndex(el => el.flashcard_id === item.flashcard_id);
		if(viewedList[tempIndex]?.flashcard_id === item?.flashcard_id){
			tempArr[tempIndex].is_marked = !markValue;
			setViewedList(tempArr);
		}else if(tempIndex===-1 && value){
			let markedObject = {
				flashcard_id: value.flashcard_id,
				viewed_on: true,
				is_marked: true,
			};
			tempArr.push(markedObject);
			setViewedList(tempArr);
		}
		let tempQnA = [];
		tempQnA = flashCardQnAListData;
		let temQnAIndex = tempQnA.findIndex(el => el.flashcard_id === item.flashcard_id);
		flashCardQnAListData[temQnAIndex].marked = !markValue;
		let flashMarkedTempArray = flashCardQnAListData.filter((itemMarked) => itemMarked.marked === true);
		if (flashMarkedTempArray.length === 0 && flashDisplay === 'Marked') {
			setFlashDisplay('All');
		}
		setFlashCardMarked(flashMarkedTempArray);
		updateFlashcard(tempArr);
	}
	const handleErrorMessage = () => {
		if(isFlipped){
			message.error({
				content:constants.FlashcardTYSEM1,
				style:{ marginTop : '30vh' }
			});
		}else{
			message.error({
				content:constants.FlashcardTYSEM2,
				style:{ marginTop : '30vh' }
			});
		}
	}
	const buttonComponant = () => {
		return <div className='flex justify-between items-center mt-2'>
			<Space wrap>
				{
					flashDisplay !== "test-yourself" &&
					<Tooltip placement="top" title={getMarkToolTip()}>
						<Button icon={getMarkIcon()} onClick={() => {onMarkedClick();setListReload(!listReload);}} ref={buttonRef} className='rounded-lg bg-blue-700 text-white'>{constants.Bookmark}</Button>
					</Tooltip>
				}
				{startWith === "Question" || startWith === "Answer" ? <Tooltip placement="top" title={constants.Flip}><Button onClick={handleCardClick} ref={buttonRef} icon={<BlockOutlined />} className='rounded-lg bg-blue-700 text-white'>{constants.Flip}</Button></Tooltip> : null}
				{prevDisable === false ? (<Tooltip placement="top" title={constants.Previous}><Button icon={<ArrowLeftOutlined />}  ref={buttonRef} onClick={handlePrevious} className='rounded-lg bg-green-700 text-white'>{constants.Previous}</Button></Tooltip>) : null}
				{nextDisable === false ? (<Tooltip placement="top" title={constants.Next}><Button icon={<ArrowRightOutlined />}  ref={buttonRef} onClick={handleNext} className='rounded-lg bg-green-700 text-white'>{constants.Next}</Button></Tooltip>) : null}
				{flashDisplay === "test-yourself" && nextDisable ? (<Tooltip placement="top" title={constants.Submit}><Button icon={<ArrowRightOutlined />} ref={buttonRef} onClick={handleErrorMessage} className='rounded-lg bg-green-700 text-white'>{constants.Submit}</Button></Tooltip>) : null}
				<Tooltip placement="top" title={constants.Close}><Button onClick={handleClose} ref={buttonRef} icon={<CloseOutlined />} className='rounded-lg bg-red-700 text-white'>{constants.Close}</Button></Tooltip>
			</Space>
			{
				flashDisplay === "test-yourself" && isFlipped === false ? (
					<div className='pt-1 pb-1.5 px-2 border border-black rounded-lg font-semibold capitalize'>
						<Space split={<Divider type="vertical" />} wrap>
							<Space>
								{constants.Correct} 
								<Badge 
									count={flashcardTestJSON.filter((val) => val?.is_correct === true).length} 
									showZero 
									color="#ffffff" 
									style={{color:'black', fontWeight:'600'}} 
								/>
							</Space>
							<Space>
								{constants.Incorrect} 
								<Badge 
									count={flashcardTestJSON.filter((val) => val?.is_correct === false).length} 
									showZero color="#ffffff" 
									style={{ color: 'black', fontWeight: '600' }} 
								/>
							</Space>
						</Space>
					</div>
				) : null
			}
		</div>
	};
	const getFlashcardList = () => {
		setShowResult(false);
		fetchApi(`/flashcards/${scoreCardId}`, "get").then((res) => {
			if (res.code === 200) {
				setFlashcardTittle(res?.data?.title);
				setViewedList(res?.data?.flashcards.viewed_ids);
				setFlashCardQnAListData(res?.data?.flashcards?.all);
				setDateFirstCompleted(res?.data?.dt_first_completed);
				setFlashCardMarked(res?.data?.flashcards?.flashcards_marked);
				const reviewListTemp = res.data.flashcards.viewed_ids.filter(item => {
					return item.viewed_on !== null;
				});
				setReviewedList(reviewListTemp);
			} else {
				setFlashcardTittle("");
				setFlashCardQnAListData([]);
				setViewedList([]);
				setDateFirstCompleted("");
				setReviewedList([]);
				setFlashCardMarked([]);
			}
		});
	}
	const checkAndUpdateFlashcard = (submit = null) => {
		if(flashcardTestJSON[counter]?.is_answered){
			let answerdArray = flashcardTestJSON.filter((val)=> val?.is_answered === true);
			if (carouselEffect === "fade") {
				setCarouselEffect("scrollx");
			}
			let payload = null;
			payload = {
				flashcard_details: answerdArray,
				flashcards_count: flashcardTestListData?.length
			};
			fetchApi(`/flashcards-test-yourself/${scoreCardId}`, "put", payload).then((res) => {
				if (!res && res.code !== 200) {
					message.error(res.message);
				} else {
					if(submit){
						setShowResult(true);
					}else{
						carouselRef.current.next();
					}
					setServerTime(res.current_date ? res.current_date : "");
					let browerDateTime = new Date();
					setbrowserTime(browerDateTime.toString());
				}
			});
		}else{
			setisFlipped(true);
		}
	};
	const handleNext = () => {
		if(flashDisplay === "test-yourself"){
			if(flashcardTestJSON[counter].is_answered){
				checkAndUpdateFlashcard();
			}else{
				handleErrorMessage();
			}
		}
		else {
		if (startWith === "Question and Answer") {
			carouselRefQnA?.current?.next();
		} 
		else {
			carouselRef?.current?.next();
		}
	}
	};
	const handlePrevious = () => {
		// setCounter(counter - 1);
		if (startWith === "Question and Answer") {
			carouselRefQnA?.current?.prev();
		} else {
			carouselRef?.current?.prev();
		}
	};
	const onChangeStartWith = (value) => {
		if(value==="ListView"){
			setIsListView(true);
		}else{
			setIsListView(false);
			if (value === "Question" || value === "Answer") {
				setisFlipped(false);
			}
		}
		setStartWith(value);

	}
	const updateFlashcard = async (viewedCardList) => {
		if (carouselEffect === "fade") {
			setCarouselEffect("scrollx");
		}
		let payload = null;
		payload = {
			flashcard_details: viewedCardList,
			flashcards_count: flashCardQnAListData?.length
		};
		let url;
		if (flashDisplay === "Marked") {
			url = `/flashcards-mark-to-study/${scoreCardIdMFL}`;
		} else {
			url = `/flashcards/${scoreCardId}`;
		}
		fetchApi(url, "put", payload).then((res) => {
			if (!res && res.code !== 200) {
				message.error(res.message);
			} else {
				setServerTime(res.current_date ? res.current_date : "");
				let browerDateTime = new Date();
				setbrowserTime(browerDateTime.toString());
			}
		});
	};
	const handleCardClick = () => {
		setisFlipped(!isFlipped);
	};
	const handleFlip = () => {
		if (isFlippedref.current === true) {
			setisFlipped(true);
		} else {
			setisFlipped(false);
		}
	};
	const handleClose = () => {
		let state = { chapter_id: chapterId }
		navigate('/chapter-resources', { state });
	};
	//To shuffle flashcards
	const shuffleData = () => {
		if (flashCardQnAListData.length > 1) {
			let tempdata = flashCardQnAListData;
			tempdata.sort(() => 0.5 - Math.random());
			setFlashCardQnAListData(tempdata);
			if (startWith === "Question and Answer") {
				carouselRefQnA.current.goTo(0, false);
			} else {
				carouselRef.current.goTo(0, false);
			}
		}
	};
	const onStopButton = () => {
		setSeconds(1);
		setFlashCard(false);
	};
	const setNavigationButtonDisable = (b, tempLength) => {
		setPrevDisable(b === 0 ? true : false);
		setNextDisable(b === tempLength - 1 ? true : false);
	};
	function returnReviewedMark(item) {
		return (
			<>
				{
					dateFirstCompleted && reviewedList && reviewedList.length === 0 ?
						<span className="absolute rotate-[270deg] left-[-25px] text-gray-400 italic font-normal">Reviewed</span> :
						reviewedList && reviewedList.length > 0 && item && reviewedList.some(el => el.flashcard_id === item.flashcard_id) && <span className="absolute rotate-[270deg] left-[-25px] text-gray-400 italic font-normal">Reviewed </span>}
			</>
		);
	}
	const getUsersAnswer = (answer,flashcard_id,length) => {
		// setDisable(true);
		let tempIndexValue = flashcardTestJSON.findIndex(el => el.flashcard_id === flashcard_id);
		flashcardTestJSON[tempIndexValue].is_correct = answer;
		flashcardTestJSON[tempIndexValue].is_answered = true;
		if(length === counter + 1){
			checkAndUpdateFlashcard("submit");
		}else{
			checkAndUpdateFlashcard();
		}
		// setDisable(false);
	};
	function getListLength(checkValueList){
		return{
			'All':flashCardQnAListData.length,
			'Marked':flashCardMarked.length,
		}[checkValueList];
	}
	const graphOption = {
		colors: ["#2F8548", "#B91919"],
		backgroundColor: { fill: 'transparent' },
		chartArea: { width: "70%" },
	};
	return (
		<Spin size="large" spinning={false}>
			<div
				style={{ backgroundColor: getColorClass(chapterId, "colorcode") }}
				className='text-white w-12 lg:w-36 roboto-slab font-bold text-4xl flex justify-center items-end pb-3.5 absolute right-0 top-[-103px] h-36'
			>
				{chapterId}
			</div>
			<div className='container mx-auto'>
				<div className='flex justify-between items-center'>
					<div style={{ color: getColorClass(chapterId, "colorcode") }} className='text-base lg:text-2xl underline underline-offset-4 font-extrabold uppercase w-4/5 lg:w-auto'>{flashCardTittle}</div>
				</div>
				<Divider className='my-2' />
				<div className={`flex flex-wrap justify-between items-center my-3 px-5`}>
					<div className='font-bold text-xl'>{constants.Flashcards} {flashDisplay === "Marked" ? `- ${constants.MarkForLater}` : flashDisplay === "test-yourself" ? `- ${constants.TestYourSelf}` : ""}</div>
						{
							flashDisplay !== "test-yourself" ?
							< Space wrap>
								{/* {!isListView? */}
								<Select
									value={startWith}
									className='w-72'
									options={[
										{ value: 'Question', label: 'Start with Question' },
										{ value: 'Answer', label: 'Start with Answer' },
										{ value: 'Question and Answer', label: 'Start with Question and Answer' },
										{ value: 'ListView', label: 'List View' },
									]}
									onChange={onChangeStartWith}
								/>
								{/* :null} */}
								{/* {isListView?
									<Tooltip title="Flashcards View"><Button className='rounded-lg bg-blue-700 !text-white' icon={<CopyOutlined className='rotate-90'/>} onClick={()=>setIsListView(false)}></Button></Tooltip>:
									<Tooltip title="List View"><Button className='rounded-lg bg-blue-700 !text-white' icon={<UnorderedListOutlined />} onClick={()=>{setIsListView(true);setInitialCarousalSlide(counter);}}></Button></Tooltip>
								} */}
								</Space>
								:
								<div className="w-1/2">
									<Steps
										current={showResult ? 2 : 1}
										items={[
											{
												title: 'Instructions',
											},
											{
												title: 'Flashcards',
											},
											{
												title: 'Result',
											},
										]}
									/>
								</div>
						}
				</div>
				{showResult === false?
				<>
				{!isListView?
				<div className='px-5'>
					<Card extra={
						<Space>
							{flashDisplay !== "test-yourself" && (
								<>
									<Tooltip placement="top" title={constants.Shuffle}><Button icon={<RetweetOutlined />} onClick={shuffleData} className='rounded-lg bg-blue-700 text-white' disabled={playFlashCard}>{constants.Shuffle}</Button></Tooltip>
									{
										!playFlashCard ? <Tooltip placement="top" title={constants.AutoPlay}><Button icon={<PlayCircleOutlined />} onClick={() => setFlashCard(true)} className='rounded-lg bg-blue-700 text-white'>{constants.AutoPlay}</Button></Tooltip>
											:
											<Tooltip placement="top" title={constants.Stop}><Button icon={<span className="bg-white w-3.5 h-3.5 block relative top-[3px] stop-btn"></span>} onClick={onStopButton} className='rounded-lg bg-blue-700 text-white'>{constants.Stop}</Button></Tooltip>
									}
								</>
							)}
						</Space>
					}
						className='w-full bg-pink-100 px-0 lg:px-5'
						bodyStyle={{ paddingTop: 0 }}
					>
						{startWith === "Question and Answer" ?
							<Carousel className='flash-slider-qna' dots={false} effect={carouselEffect} ref={carouselRefQnA} lazyLoad="progressive" beforeChange={onChangeCarouselref} initialSlide={initialCarousalSlide}>
								{getDisplayFlashList().map((item, index, array) => {
									return (
										<>
											<div key={item}>
												{/* Q+A */}
												<div>
													<Card
														title={
															<Space>
																<span className='text-xl drop-shadow-lg'>Q</span>
																<span className='font-medium'>{reviewedCardCount}/{array.length}</span>
															</Space>
														}
														bodyStyle={{ minHeight: '187px', display: 'grid', placeContent: 'center', placeItems: 'center', paddingLeft:'1.75rem', paddingRight:'1.75rem' }}
														size="small"
														className={'font-normal text-xl bg-sky-50 rounded-none border-b border-x-0 border-t-0 border-black ' + bookmarkFold()}
													>
														<div
															dangerouslySetInnerHTML={{ __html: item.question.replace(/\\r\\n|\\n|\\r/gm, "<br/>") }}
														/>
														{returnReviewedMark(item)}
													</Card>
													<Card
														size="small"
														title={<span className='text-xl drop-shadow-lg'>A</span>}
														bodyStyle={{ minHeight: '187px', display: 'grid', placeContent: 'center', placeItems: 'center', paddingLeft:'1.75rem', paddingRight:'1.75rem' }}
														className='font-normal text-xl bg-sky-50 rounded-none'
													>
														<div
															dangerouslySetInnerHTML={{ __html: item.answer.replace(/\\r\\n|\\n|\\r/gm, "<br/>") }}
														/>
													</Card>
												</div>
											</div>
										</>
									);
								})}
							</Carousel> :
							<Carousel className='flash-slider' dots={false} ref={carouselRef} effect={carouselEffect} lazyLoad="progressive" beforeChange={onChangeCarouselref} initialSlide={initialCarousalSlide}>
								{getDisplayFlashList().map((item, index, array) => {
									return (
										<div key={item.flashcard_id}>
											{startWith === "Question" ?
												<div>
													{/* Q&A */}
													<ReactCardFlip isFlipped={isFlipped} flipDirection="vertical" flipSpeedBackToFront="0.3" flipSpeedFrontToBack="0.3">
														<Card
															title={
																<Space>
																	<span className='text-xl drop-shadow-lg'>Q</span>
																	<span className='font-medium'>{reviewedCardCount}/{array.length}</span>
																</Space>
															}
															onClick={handleCardClick}
															bodyStyle={{ height: '232px', display: 'grid', placeItems: 'center', overflowY:'auto', paddingLeft:'1.75rem', paddingRight:'1.75rem' }}
															size="small"
															className={'font-normal text-xl bg-sky-50 cursor-pointer ' + bookmarkFold()}
														>
															<div
																dangerouslySetInnerHTML={{ __html: item.question.replace(/\\r\\n|\\n|\\r/gm, "<br/>") }}
															/>
															{returnReviewedMark(item)}
														</Card>
														<Card
															title={
																<Space>
																	<span className='text-xl drop-shadow-lg'>A</span>
																	<span className='font-medium'>{reviewedCardCount}/{array.length}</span>
																</Space>
															}
															onClick={handleCardClick}
															bodyStyle={{ height: '232px', display: 'grid', placeItems: 'center', overflowY:'auto', paddingLeft:'1.75rem', paddingRight:'1.75rem' }}
															size="small"
															className={'font-normal text-xl bg-sky-50 cursor-pointer ' + bookmarkFold()}
														>
															<div
																dangerouslySetInnerHTML={{ __html: item.answer.replace(/\\r\\n|\\n|\\r/gm, "<br/>") }}
															/>
															{
																flashDisplay === "test-yourself" && (
																	<div className='text-base'>
																		<Space wrap>
																			{/* <span>I got it:</span> */}
																			<Button 
																				onClick={()=>getUsersAnswer(true,item.flashcard_id,array.length)} 
																				icon={<CheckCircleOutlined />} 
																				className='rounded-lg bg-green-700 text-white'
																			>
																				{constants.Correct}
																			</Button>
																			<Button 
																				onClick={()=>getUsersAnswer(false,item.flashcard_id,array.length)} 
																				icon={<CloseOutlined />} 
																				className='rounded-lg bg-red-700 text-white'
																			>
																				{constants.Incorrect}
																			</Button>
																		</Space>
																	</div>
																)
															}
															{returnReviewedMark(item)}
														</Card>
													</ReactCardFlip>
												</div>
												:
												<div>
													{/* A&Q */}
													<ReactCardFlip isFlipped={isFlipped} flipDirection="vertical" flipSpeedBackToFront="0.3" flipSpeedFrontToBack="0.3">
														<Card
															title={
																<Space>
																	<span className='text-xl drop-shadow-lg'>A</span>
																	<span className='font-medium'>{reviewedCardCount}/{array.length}</span>
																</Space>
															}
															onClick={handleCardClick}
															bodyStyle={{ height: '232px', display: 'grid', placeItems: 'center', overflowY:'auto', paddingLeft:'1.75rem', paddingRight:'1.75rem' }}
															size="small"
															className={'font-normal text-xl bg-sky-50 cursor-pointer ' + bookmarkFold()}
														>
															<div
																dangerouslySetInnerHTML={{ __html: item.answer.replace(/\\r\\n|\\n|\\r/gm, "<br/>") }}
															/>
															{returnReviewedMark(item)}
														</Card>
														<Card
															title={
																<Space>
																	<span className='text-xl drop-shadow-lg'>Q</span>
																	<span className='font-medium'>{reviewedCardCount}/{array.length}</span>
																</Space>
															}
															onClick={handleCardClick}
															bodyStyle={{ height: '232px', display: 'grid', placeItems: 'center', overflowY:'auto', paddingLeft:'1.75rem', paddingRight:'1.75rem' }}
															size="small"
															className={'font-normal text-xl bg-sky-50 cursor-pointer ' + bookmarkFold()}
														>
															<div
																dangerouslySetInnerHTML={{ __html: item.question.replace(/\\r\\n|\\n|\\r/gm, "<br/>") }}
															/>
															{returnReviewedMark(item)}
														</Card>
													</ReactCardFlip>
												</div>
											}
										</div>
									);
								})}
							</Carousel>
						}
						{buttonComponant()}
						<div className='mt-2 bg-white p-3 rounded-lg shadow-lg'>
							<Progress className='!mb-0' percent={progressPercent} showInfo={false} />
						</div>
					</Card>
				</div>:
				<>
					<div className='px-5'>
						<p>Displaying {getListLength(flashDisplay)}/{getDisplayFlashList().length} Flashcards</p>
						<div className="flash-list flash-listQA">
							<List
								dataSource={getDisplayFlashList()}
								renderItem={item => (
									<List.Item className='flash-list'>
										<Skeleton loading={false} active>
											<Card size="small" className="w-full bg-sky-50"
												title={<div>
													<Space size={'large'} className='items-start'>
														<span className='font-normal vertical-divider'>Q</span>
														<span style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html:item.question.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}/>
													</Space>
												{
													dateFirstCompleted&&reviewedList&&reviewedList.length===0?
															<div className="text-center italic text-gray-400">Reviewed</div>:
															reviewedList && reviewedList.length > 0 && item && reviewedList.some(el => el.flashcard_id === item.flashcard_id) && <div className="text-center italic text-gray-400">Reviewed</div>}
												</div>}
												extra={<Button type="text" icon={item.marked===true?<Tooltip placement="left" title="Unmark"><FlagFilled /></Tooltip>:<Tooltip placement="left" title="Mark for later"><FlagOutlined /></Tooltip>} 
													onClick={()=>{onMarkedClick(item);setListReload(!listReload);}}
												></Button>
												}
											>
												<Space size={'large'} className='items-start'>
													<span>A</span>
													<p style={{whiteSpace:'break-spaces'}}
														dangerouslySetInnerHTML={{ __html:item.answer.replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}
													/>
												</Space>                  
												</Card>
											</Skeleton>
										</List.Item>
									)}
							/>
							<br/>
						</div>
					</div>
				</>
				}
				</>:
				<div className="px-5">
				<Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
					<div class="grid grid-cols-1 lg:grid-cols-2 divide-x">
						<div>
							<div className='text-green-700 text-xl font-semibold'>You got {flashcardTestJSON.filter((val)=> val?.is_correct === true).length}/{flashcardTestJSON.length} flashcards correct.</div>
							<div className="font-semibold">{"Date: " + date}</div>
							<Chart
								width="100%"
								height="300px"
								chartType="PieChart"
								// loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
								data={[
									["Answered", "Total"],
									["Correct", Number(flashcardTestJSON.filter((val)=> val?.is_correct === true).length)],
									["Incorrect", flashcardTestJSON.length - Number(flashcardTestJSON.filter((val)=> val?.is_correct === true).length)],
								]}
								options={graphOption}
							/>
						</div>
						<div className="grid place-content-center place-items-center">
							<div className="grid gap-3 lg:w-56">
								<Button
									onClick={() => {
										const state = {
											chapter_id: location?.state?.chapter_id
										};
										navigate('/chapter-resources', { state, replace: true });
									}}
									className='rounded-lg bg-blue-700 text-white text-left'
									icon={<ArrowLeftOutlined />}
									block
									size="large"
								>
									{constants.BackToChapter}
								</Button>
								<Button
									onClick={() => navigate('/dashboard', { replace: true })}
									icon={<ArrowLeftOutlined />}
									className='rounded-lg bg-blue-700 text-white text-left'
									block
									size="large"
								>
									{constants.Dashboard}
								</Button>
							</div>
						</div>
					</div>
				</Card>
			</div>
				}
			</div>
			<div style={{ visibility: "hidden" }}>
				<b>Server time:</b> {serverTime ? serverTime : ""}{"           "}
				<b>Browser time:</b> {browserTime ? browserTime : ""}
			</div>
		</Spin>
	)
}

export default Flashcards;