import constants from '../constants/constants';
import dayjs from 'dayjs';

export const success = (type, msg, cls, msgApi) => {
	msgApi.open({
		type: type,
		content: msg,
		className: cls,
		style: {
			marginTop: '20vh',
		},
	});
};

export const getTitleDetails = () => {
	return (
		<div className ="flex items-center justify-center">
			<div className = "flex flex-col items-start ml-11 title-container">
				<div className="text-neutral-700 text-xl heading">Fundamentals of Anatomy and Physiology of Speech, Language, and Hearing</div>
				<div className='font-semibold text-rose-700 text-base'>Glen M. Tellis, M. Hunter Manasco</div>
			</div>
		</div>
	)
}

export const getCustomisedBreadcrumbs = (mainArr, subArr) => {
	const modifiedArr = mainArr.concat(subArr);
	const BreadcrumbItems = modifiedArr.map((item) => {
		return {
			title: item
		}
	});
	return BreadcrumbItems;
}
//get color code based on chapter Id.
export const getColorClass = (chapterId,colorType) => {
	if(chapterId === "1" || chapterId === "5" || chapterId === "9" ){
		return colorType === "colorcode" ? constants.ColorCodeChp159 : constants.GradientColorClassChp159 ;
	}
	else if(chapterId === "2" || chapterId === "6" || chapterId === "10" ){
		return colorType === "colorcode" ? constants.ColorCodeChp2610 : constants.GradientColorClass2610 ;
	}
	else if(chapterId === "3" || chapterId === "7" || chapterId === "11" ){
		return colorType === "colorcode" ? constants.ColorCodeChp3711 : constants.GradientColorClass3711 ;
	}
	else if(chapterId === "4" || chapterId === "8" || chapterId === "12" ){
		return colorType === "colorcode" ? constants.ColorCodeChp4812 : constants.GradientColorClass4812 ;
	}
};

export const setStartandEnddate = (option, startDate, endDate, setStartD, setEndD, setPeriod, setYear) => {
	setPeriod(option);
	const currentYear = dayjs().year();
	if (option == 0 || option == 2 || option == 3 || option == 4) {
		setYear(currentYear);
	} else {
		const previousYear = currentYear - 1;
		setYear(previousYear);
	}
	if (option == 0) {
		setStartD(dayjs().startOf('year').format('MM/DD/YYYY'));
		setEndD(dayjs().endOf('year').format('MM/DD/YYYY'));
	} else if (option == 1) {
		setStartD(dayjs().startOf('year').subtract(1, 'year').format('MM/DD/YYYY'));
		setEndD(dayjs().endOf('year').subtract(1, 'year').format('MM/DD/YYYY'));
	} else if (option == 2) {
		setStartD(dayjs().startOf('month').format('MM/DD/YYYY'));
		setEndD(dayjs().endOf('month').format('MM/DD/YYYY'));
	} else if (option == 3) {
		setStartD(dayjs().subtract(1, 'month').startOf('month').format('MM/DD/YYYY'));
		setEndD(dayjs().subtract(1, 'month').endOf('month').format('MM/DD/YYYY'));
	} else if (option == 4) {
		setStartD(dayjs().subtract(3, 'month').startOf('month').format('MM/DD/YYYY'));
		setEndD(dayjs().subtract(1, 'month').endOf('month').format('MM/DD/YYYY'));
	} else if (option == 5) {
		setStartD(dayjs().subtract(1, 'y').startOf('month').format('MM/DD/YYYY'));
		setEndD(dayjs().subtract(1, 'y').endOf('month').format('MM/DD/YYYY'));
	} else if (option == 6) {
		setStartD(dayjs().subtract(1, 'y').endOf('year').subtract(3, 'month').startOf('month').format('MM/DD/YYYY'));
		setEndD(dayjs().subtract(1, 'y').endOf('year').subtract(1, 'month').endOf('month').format('MM/DD/YYYY'));
	} else if (option == 7) {
		setStartD(dayjs('01/01/2020').format('MM/DD/YYYY'));
		setEndD(dayjs().endOf('year').format('MM/DD/YYYY'));
	}
}
//get StartDate and EndDate for Period DropDown
export const getStartDateAndEndDate = (option) => {
	if (option == 0) {
		let date = {
			startDate: dayjs().startOf('year'),
			endDate: dayjs().endOf('year')
		}
		return date;
	} else if (option == 1) {
		let date = {
			startDate: dayjs().startOf('year').subtract(1, 'year'),
			endDate: dayjs().endOf('year').subtract(1, 'year')
		}
		return date;
	} else if (option == 2) {
		let date = {
			startDate: dayjs().startOf('month'),
			endDate: dayjs().endOf('month')
		}
		return date;
	} else if (option == 3) {
		let date = {
			startDate: dayjs().subtract(1, 'month').startOf('month'),
			endDate: dayjs().subtract(1, 'month').endOf('month')
		}
		return date;
	} else if (option == 4) {
		let date = {
			startDate: dayjs().subtract(3, 'month').startOf('month'),
			endDate: dayjs().subtract(1, 'month').endOf('month')
		}
		return date;
	} else if (option == 5) {
		let date = {
			startDate: dayjs().subtract(1, 'y').startOf('month'),
			endDate: dayjs().subtract(1, 'y').endOf('month')
		}
		return date;
	} else if (option == 6) {
		let date = {
			startDate: dayjs().subtract(1, 'y').endOf('year').subtract(3, 'month').startOf('month'),
			endDate: dayjs().subtract(1, 'y').endOf('year').subtract(1, 'month').endOf('month')
		}
		return date;
	} else if (option == 7) {
		let date = {
			startDate: dayjs('01/01/2020'),
			endDate: dayjs().endOf('year')
		}
		return date;
	}
}

export const changeDateFormat = (value = '') => {
	const modifiedMonth = value.slice(0,2);
	const modifiedDate = value.slice(3,5);
	const modifiedYear = value.slice(6,10);
	let formattedValue = `${modifiedYear}-${modifiedMonth}-${modifiedDate}`;
	return formattedValue;
}

export const selectFilterOption = (input,option)=>{
	return option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) > -1 && option?.key.toLowerCase()?.indexOf(input?.toLowerCase()) < 1;
};