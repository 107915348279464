import React from "react";
import {
	Row,
	Col,
	Card,
	Button,
	Image
} from "antd";
import {
	HomeOutlined,
} from '@ant-design/icons';
import bookCover from '../../../assets/Tellis_FUNDAP_rev.jpg'
import constants from "../../../constants/constants";
const Logout = () => {
  const redirectToLogin=()=>{
    if(process.env.REACT_APP_ENV==="staging"){
			window.location.href = constants.StagingWebsiteURL;
		}else{
			window.location.href = constants.LiveWebsiteURL;
		}
  }
	return (
		<div className="mx-3">
			<div className="container mx-auto">
				<Row gutter={[8, 8]} className="h-screen">
					<Col lg={12}>
						<div className="grid place-content-center place-items-center h-full">
							<Image src={bookCover} alt="FAPSLH" preview={false} className="!w-4/5 lg:!w-3/5 mx-auto"/>
						</div>
          </Col>
					<Col lg={12}>
						<div className="grid place-content-center place-items-center h-full">
							<Card className="bg-sky-50" title={<span className="text-2xl">{constants.loggedOut}</span>}>
								<div className="text-xl pb-5">
									{constants.logoutdesc}
								</div>
								<div>
									<Button type="primary" className="w-full lg:w-auto" icon={<HomeOutlined />} onClick={() => redirectToLogin()}>Go to Home</Button>
								</div>
							</Card>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};
export default Logout;