import { useState, createContext, useEffect } from "react"
const Context = createContext()

function ContextProvider(props) {
	const [collapsed, setCollapsed] = useState(false)

	function toggleSidebar() {
		setCollapsed(!collapsed)
	}

	useEffect(() => {
		const isEdge = window.navigator.userAgent.indexOf('Edg') > -1;

		if (isEdge) {
			// Apply Edge-specific styles
			document.body.classList.add('edge');
		}
	}, []);

	return (
		<Context.Provider value={{collapsed,toggleSidebar}}>
			{props.children}
		</Context.Provider>
		)
}
export {ContextProvider,Context}
