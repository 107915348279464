import React,{useState} from 'react';
import {
	Input,
	Form,
	Row,
	Col,
	Button,
	message,
	Card,
} from "antd";
import constants from "../../../../constants/constants";
import { success } from '../../../../functions/functions';
import { EyeInvisibleOutlined, EyeTwoTone, InfoCircleOutlined } from "@ant-design/icons";
import { fetchAdminApi } from "../../../../services/api";
import { COOKIE, getCookie, createCookie } from "../../../../services/cookie";

const ResetPassword = () => {
	const [form] = Form.useForm();
	const [newpassword,setNewpassword] = useState('');
	const [messageApi, contextHolder] = message.useMessage();
	const [connfirmedpassword,setConfirmedpassword] = useState('');
	const [formError, setFormError] = useState(false);


	const handleNewpassword = (e) => {
		setNewpassword(e.target.value);
	};

	const handleConfirmedPassword = (e)=>{
		setConfirmedpassword(e.target.value)
	};

	const onFinish = (values)=>{
		console.log(values);
		let userId = getCookie(COOKIE.UserId);
		//let userToken = getCookie(COOKIE.Token);
		fetchAdminApi(`/change-password?new_password=${values.newPassword}&confirm_password=${values.confirmedPassword}&user_id=${userId}`, 'post').then((res) => {
			console.log(res);
			if(res.code === 200){
				success('success', constants.passwordChangeSucess, 'passwordSuccessMessage', messageApi);
				setFormError(false);
				form.resetFields();
			} else if (res && res.message === constants.duplicatedPassword){
				setFormError(true);
				success('error', constants.duplicatedPasswordError, 'passwordSuccessMessage', messageApi)
			}
		})
	}

	const validateNewPassword = (_rule, value, callback) => {
		try {
			if (value) {
				const passwordReg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/;
				if (passwordReg.test(value) === false) {
					throw new Error(constants.invalidPassword);
				}
			}else{
				throw new Error(constants.dataRequired);
			}
			callback();
		} catch (err) {
			callback(err);
		}
	};

	const validateConfirmedPassword = (_rule, value, callback) => {
		try {
			if (value){
				if (value !== newpassword) {
					throw new Error(constants.confirmedPasswordError);
				}
			}else{
				throw new Error(constants.dataRequired);
			}
			callback();
		} catch (err) {
			callback(err);
		}
	};

	const onValuesChange = async (values) => {
		setFormError(false);
		// try {
		// 	//await form.validateFields();
		// 	//setFormError(false);
		// } catch (err) {
		// 	//setFormError(true);
		// }
	}

	return (
		<div>
			{/* <Headers /> */}
			<div className="grid place-content-center place-items-center min-h-[82vh]" >
			<Row>
			<Col>
						<Card hoverable className="w-96 addQcard border-2" title={constants.ChangePswBTN}>
							<Form layout="vertical" form={form} onFinish={onFinish} autoComplete="off" className='resetPassForm' onValuesChange={onValuesChange}>
						<div >
							<Form.Item
								label={constants.NewPassword}
								name="newPassword"
								rules={[
									{
										required: true,
										message:'',
									},
									{validator: validateNewPassword},
								]}
									tooltip={{
										title: constants.passwordValidationInfo,
										icon: <InfoCircleOutlined />,
									}}
							>
								<Input.Password
									placeholder={constants.Password}
									iconRender={(visible) =>
										visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
									}
									onChange={handleNewpassword}
								/>
							</Form.Item>
							<Form.Item
								label={constants.ConfirmNewPassword}
								name="confirmedPassword"
								rules={[
									{
										required: true,
										message: '',
									},
									{ validator: validateConfirmedPassword },
								]}
							>
								<Input.Password
									placeholder={constants.Password}
									iconRender={(visible) =>
										visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
									}
									onChange={handleConfirmedPassword}
								/>
							</Form.Item>
						</div>
						<Form.Item>
							<Button
								type="primary"
								block
								htmlType="submit"
								className='font-bold'
								disabled={formError}
							>
								{constants.ChangePswBTN}
							</Button>
						</Form.Item>
					</Form>
				</Card>
						{contextHolder}
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default ResetPassword
