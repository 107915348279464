import React, { useEffect, useState } from "react";
import { Button, Divider, Card, Space, Steps, Modal, Row, Col } from 'antd';
import { ArrowLeftOutlined, FileDoneOutlined } from '@ant-design/icons';
import constants from "../../../constants/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { getColorClass } from "../../../functions/functions";

const MarkedIncorrectInstructions = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
  }, []);
  //Redirect to start test
  const redirectToStartTutorial = (isImage) => {
    let state = {
      chapter_id: location?.state?.chapter_id,
      attempt: location?.state?.attempt,
      chapter_name: location?.state?.chapter_name
    }
    if (location?.state?.is_marked_later === true) {
      state.is_marked_later = true
    }
    if (location?.state?.is_ans_incorrect === true) {
      state.is_ans_incorrect = true
    }
    if(isImage==="t"){
      navigate('/image-tutorial', { state, replace: true });
    }else{
      navigate('/marked-incorrect-tutorial', { state, replace: true }); 
    }
  }
  return (
    <div >
      <div
        style={{ backgroundColor: getColorClass(location?.state?.chapter_id, "colorcode") }}
        className='text-white w-12 lg:w-36 roboto-slab font-bold text-4xl flex justify-center items-end pb-3.5 absolute right-0 top-0 h-36'
      >
        {location?.state?.chapter_id}
      </div>
      <div className='container mx-auto'>
        <div className='flex justify-between items-center'>
          <div style={{ color: getColorClass(location?.state?.chapter_id, "colorcode") }} className='text-base lg:text-2xl underline underline-offset-4 font-extrabold uppercase w-4/5 lg:w-auto'>{location?.state?.chapter_name}</div>
        </div>
        <Divider className="my-1" />
        <div className={`flex justify-between items-center my-3 px-5`}>
          <div className='font-bold text-xl'>Tutorial {location?.state?.is_marked_later === true ? "- Marked For Later" :"- Incorrect Answers"} </div>
        </div>
        <div>
          {location?.state?.is_marked_later === true ? 
            <>
            <Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
              <div className="text-base">
                <p>Questions that are marked for later review during tests will appear in this Tutorial.</p>
                <p>Once you answer a question correctly, it will not appear in this section again.</p>
                <p>After completion, you will not be able to review this tutorial again.</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 mt-3">
                  <Card title="Single Select" size="small" className="w-full">
                    <div className="text-base">
                      {location?.state?.isSingle===0?
                      <>
                      <p>Currently, there are fewer than 5 questions marked for later review.</p>
                      <p>You can always come back and check again after marking more questions during any test.</p>
                      </>:<>
                      <p>There are marked questions available for review. </p>
                      <p className="mb-2">Ready to start reviewing? </p>
                      <Button className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" icon={<FileDoneOutlined />} onClick={() => redirectToStartTutorial("f")}>{constants.startTutorial}</Button>
                      </>}
                    </div>
                  </Card>
                  <Card title="Image Quiz" size="small" className="w-full">
                    <div className="text-base">
                      {location?.state?.isImage===0?
                      <>
                      <p>Currently, there is  no Image Quiz marked for later review.</p>
                      <p>You can always come back and check again after marking more Image Quiz questions during any test.</p>
                      </>:<>
                      <p>There is marked question available for review.</p>
                      <p className="mb-2">Ready to start reviewing? </p>
                      <Button className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" icon={<FileDoneOutlined />} onClick={() => redirectToStartTutorial("t")}>{constants.startTutorial}</Button>
                      </>}
                    </div>
                  </Card>
                </div>
                <div>
                  <Space wrap className="mt-5">
                    <Button onClick={() => {
                      const state = {
                        chapter_id: location?.state?.chapter_id
                      };
                      navigate('/chapter-resources', { state, replace: true });
                    }}
                      className='rounded-lg bg-blue-700 text-white'
                      icon={<ArrowLeftOutlined />}	>{constants.BackToChapter}</Button>
                  </Space>
                </div>
              </div>
            </Card>
            </>:
            <>
            <Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
              <div className="text-base">
                <p>In this section, you will find questions that you answered incorrectly during the Tests or Tutorials.</p>
                <p>Once you answer a question correctly, it will not appear in this section again.</p>
                <p>After completion, you will not be able to review this Tutorial again.</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 mt-3">
                  <Card title="Single Select" size="small" className="w-full">
                    <div className="text-base">
                      {location?.state?.isSingle===0?
                      <>
                      <p>Currently, there are fewer than 5 questions answered incorrectly.</p>
                      <p>You can always come back and check again after taking any Tests/Tutorials.</p>
                      </>:<>
                      <p>There are incorrectly answered questions available for review.</p>
                      <p className="mb-2">Ready to start reviewing?</p>
                      <Button className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" icon={<FileDoneOutlined />} onClick={() => redirectToStartTutorial("f")}>{constants.startTutorial}</Button>
                      </>}
                    </div>
                  </Card>
                  <Card title="Image Quiz" size="small" className="w-full">
                    <div className="text-base">
                      {location?.state?.isImage===0?
                      <>
                      <p>Currently, there is no Image Quiz answered incorrectly.</p>
                      <p>You can always come back and check again later after taking any Tests/Tutorials.</p>
                      </>:<>
                      <p>There is  incorrectly answered Image question available for review. </p>
                      <p className="mb-2">Ready to start reviewing? </p>
                      <Button className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" icon={<FileDoneOutlined />} onClick={() => redirectToStartTutorial("t")}>{constants.startTutorial}</Button>
                      </>}
                    </div>
                  </Card>
                </div>
                <div>
                  <Space wrap className="mt-5">
                    <Button onClick={() => {
                      const state = {
                        chapter_id: location?.state?.chapter_id
                      };
                      navigate('/chapter-resources', { state, replace: true });
                    }}
                      className='rounded-lg bg-blue-700 text-white'
                      icon={<ArrowLeftOutlined />}	>{constants.BackToChapter}</Button>
                  </Space>
                </div>
              </div>
            </Card>
            </>
          }
        </div>
      </div>
    </div>
  );
};
export default MarkedIncorrectInstructions;
