import axios from "axios";
import constants from "../../constants/constants";
import { COOKIE, deleteCookie, getCookie } from "../cookie";
/**
 * @name fetchAdminApi
 * @description will fet the data based on params supplied
 * @param {string} param
 * @param {string} method
 * @param {object} variables
 */
export const fetchAdminApi = (param = null, method = null, variables = null) =>
	axios({
		method: method,
		url: `${constants.AdminApiUrl}${param}`,
		data: variables,
		headers: {
			Authorization: `Bearer ${getCookie(COOKIE.Token)}`
		},
	})
		.then((res) => {
			return res.data;
		})
		.catch((err)=>{
			let { status } = err.response;
			if (status === 401) {
				console.log("Error", err.response.data);
				if(err.response.data.error_message==="Invalid token"){
					deleteCookie(COOKIE.UserId, "/");
					deleteCookie(COOKIE.Token,"/");
					window.location.href = constants.BaseUrl + "/admin-login";
				}
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} 
		})

//Function to call student API.
export const fetchApi = (param = null, method = null, variables = null) =>
	axios({
		method: method,
		url: `${constants.ApiUrl}${param}`,
		data: variables,
		headers: {
			Authorization: `Bearer ${getCookie(COOKIE.Token)}`
		},
	})
		.then((res) => {
			return res.data;
		})
		.catch((err)=>{
			let { status } = err.response;
			if (status === 401) {
				console.log("Error", err.response.data);
				if(err.response.data.error_message==="Invalid token"){
					deleteCookie(COOKIE.UserId, "/");
					deleteCookie(COOKIE.Token,"/");
          if(process.env.REACT_APP_ENV==="staging"){
            window.location.href = constants.StagingWebsiteURL;
          }else{
            window.location.href = constants.LiveWebsiteURL;
          }
				}
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} 
		})

export const fetchVideoApi = (param = null, method = null, variables = null) =>
	axios({
		method: method,
		url: `${constants.ApiUrl}${param}`,
		data: variables,
		headers: {
			'Content-Type': 'video/mp4',
			Authorization: `Bearer ${getCookie(COOKIE.Token)}`
		},
		responseType: 'blob',
	})
		.then((response) => {
			if (response.status === 200) {
				return response.data; // Return the Blob directly
			} else {
				throw new Error('Failed to fetch video');
			}
		})
		.catch((err) => {
			let { status } = err.response;
			if (status === 401) {
				console.log("Error", err.response.data);
				if (err.response.data.error_message === "Invalid token") {
					deleteCookie(COOKIE.UserId, "/");
					deleteCookie(COOKIE.Token, "/");
					if (process.env.REACT_APP_ENV === "staging") {
						window.location.href = constants.StagingWebsiteURL;
					} else {
						window.location.href = constants.LiveWebsiteURL;
					}
				}
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			}
		})
