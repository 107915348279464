import React, { useState,useEffect } from 'react'
import { Row, Col, Breadcrumb, Card, Space, Select, Input, Button, Form, message } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getCustomisedBreadcrumbs, selectFilterOption } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { fetchAdminApi } from '../../../services/api';

const AddEditUniversity = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const isEditMode = location?.state?.universityID ? true : false;
	const [form] = Form.useForm();
	const newCrumb = [<Link key={0} to="/university-list" className="font-semibold"> {constants.Universities} </Link>, <span className="font-semibold !text-black">{isEditMode?constants.EditUniversity:constants.AddUniversity} </span>]
	const [usStateList, setUsStateList] = useState([]);
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		if(isEditMode){
			getUniversityData(location?.state?.universityID);
		}
		getUsStateList();
	}, []);

	const handleAddEditUniversity = (value) => {
		setLoading(true);
		let url, payload, method;
		const region_code_index = usStateList?.findIndex((val)=>val?.region_id === value?.state);
		if(isEditMode){
			url = `/edit-university/${location?.state?.universityID}`;
			method = "put";
			payload = {
				city:value?.location,
				region_code:usStateList[region_code_index]?.region_code,
				university_name:value?.university,
			}
		}else{
			url = `/add-university-data`;
			method = "post";
			payload = {
				city:value?.location,
				region_code:usStateList[region_code_index]?.region_code,
				university_name:value?.university,
			}
		}
		fetchAdminApi(url, method, payload).then((res) => {
			if (res?.code === 200) {
				setLoading(false);
				message.success(res?.message);
				navigate("/university-list");
			} else {
				message.error(res?.message);
				setLoading(false);
			}
		})
	};
	const getUsStateList = () => {
		fetchAdminApi(`/get-us-states`, 'get').then((res) => {
			if (res?.code === 200) {
				setUsStateList(res.data);
			} else {
				setUsStateList([]);
			}
		})
	};

	const getUniversityData = (id) => {
		fetchAdminApi(`/get-university-info/${id}`, 'get').then((res) => {
			if (res?.code === 200) {
				form.setFieldsValue({
					state:res?.data?.region_id,
					university:res?.data?.university_name,
					location :res?.data?.city,
				})
			} else {
				message.error(res?.error);
			}
		})
	};

	return (
		<div>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.flashCardpageCrumbs, newCrumb)} className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card
							title={isEditMode ?constants.EditUniversity :constants.AddUniversity}
							className='mainflashCard'
							hoverable
						>
							<Form
								form={form}
								onFinish={handleAddEditUniversity}
							>
								<Card hoverable>
									<Form.Item
										label=
										{
											<span
												className="font-semibold text-base caption-item pr-2 imagecardTitle">{constants.stateTitle}</span>
										}
										className='caption-form-item'
										name="state"
										rules={
											[{
												required: true,
												message: constants.mandatoryField
											}
											]
										}
									>
										<Select
											// className='w-28'
											filterOption={selectFilterOption}
											showSearch
											allowClear
										>
											{
												usStateList.map((state) => {
													return <Option key={state.region_name} value={state.region_id}> {state.region_name} ({state.region_code})</Option>
												})
											}
										</Select>
									</Form.Item>
								</Card>
								<Card hoverable className='mt-5'>
									<Form.Item
										label=
										{
											<span
												className="font-semibold text-base caption-item pr-2 imagecardTitle">{constants.Location}</span>
										}
										className='caption-form-item'
										name="location"
										rules={
											[{
												required: true,
												message: constants.mandatoryField
											}
											]
										}
									>
										<Input type='text' ></Input>
									</Form.Item>
								</Card>
								<Card hoverable className='mt-5'>
									<Form.Item
										label=
										{
											<span
												className="font-semibold text-base caption-item pr-2 imagecardTitle">{constants.universityTitle}</span>
										}
										className='caption-form-item'
										name="university"
										rules={
											[{
												required: true,
												message: constants.mandatoryField
											},
											]
										}
									>
										<Input type='text' ></Input>
									</Form.Item>
								</Card>
								<Button htmlType='submit' disabled={loading} type="primary" className='mt-5 font-semibold'>{constants.Submit}</Button>
								<Button type="primary" className='mt-5 ml-3 font-semibold' onClick={()=> navigate("/university-list")}>{constants.Cancel}</Button>
							</Form>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default AddEditUniversity