import React, { useState, useEffect } from "react";
import { List, Button, Space, Divider, Card, Skeleton, Popconfirm, message, Select, Form, Result, Spin, Tooltip, Steps } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, MessageOutlined, FlagOutlined, StrikethroughOutlined, SaveOutlined, FileDoneOutlined } from '@ant-design/icons';
import constants from "../../../constants/constants";
import { fetchApi } from "../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { getColorClass } from "../../../functions/functions";
import { Chart } from "react-google-charts";

const Instructions = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
  }, []);
  //Redirect to start test
  const redirectToStartTest = (attempt) => {
    let state = {
      chapter_id: location?.state?.chapter_id,
      attempt: location?.state?.attempt,
      chapter_name: location?.state?.chapter_name
    }
    navigate('/take-test', { state, replace: true });
  }
  return (
    <div >
      <div
        style={{ backgroundColor: getColorClass(location?.state?.chapter_id, "colorcode") }}
        className='text-white w-12 lg:w-36 roboto-slab font-bold text-4xl flex justify-center items-end pb-3.5 absolute right-0 top-0 h-36'
      >
        {location?.state?.chapter_id}
      </div>
      <div className='container mx-auto'>
        <div className='flex justify-between items-center'>
          <div style={{ color: getColorClass(location?.state?.chapter_id, "colorcode") }} className='text-base lg:text-2xl w-4/5 lg:w-auto underline underline-offset-4 font-extrabold uppercase'>{location?.state?.chapter_name}</div>
        </div>
        <Divider className="my-1" />
        <div className={`flex flex-wrap justify-between items-center my-3 px-5`}>
          <div className='font-bold text-xl'>{constants.Instructions}</div>
          <div className="w-1/2">
            <Steps
              current={0}
              items={[
                {
                  title: 'Instructions',
                },
                {
                  title: 'Take a test',
                },
                {
                  title: 'Result',
                },
              ]}
            />
          </div>
        </div>
        <div>
          <Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
            <div className="text-base">
              <div className="underline font-medium text-xl">About the Test</div>
              <p>This Test consists of 50 questions that fall into two types:</p>
              <ul className="list-disc list-outside ml-4">
                <li><span className="font-semibold">Single Select:</span> For these questions, you are required to choose only one correct answer.</li>
                <li><span className="font-semibold">Image Quiz:</span> In the Image Quiz questions, an image will be presented, possibly with labels. You will need to select the right option for each label or relevant component within the image.</li>
              </ul>
              <div className="underline font-medium text-xl mt-5">Taking the test</div>
              <p>To navigate through the test, follow these steps:</p>
              <p>
                <span className="font-semibold">Select Correct Options: </span>
                After identifying the correct options for each question, click <Button size="small" className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" icon={<ArrowRightOutlined />}>{constants.Next}</Button>
              </p>
              <p>
                <span className="font-semibold">Revisit Previous Questions: </span>
                If you wish to review previous questions, click <Button size="small" className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" icon={<ArrowLeftOutlined />}>{constants.Previous}</Button>
              </p>
              <p><span className="font-semibold">Pause Your Test:</span> To pause your test and return to it later, click <Button size="small" className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" icon={<SaveOutlined />}>
                {constants.Save}
              </Button> You can resume the test at any time.</p>
              <p><span className="font-semibold">Points Display:</span> Keep track of your progress with points displayed alongside each question number.</p>

              <p className="underline font-medium text-xl mt-5">During the test you may:</p>
              <ul className="list-disc list-outside ml-4">
                <li><Button icon={<StrikethroughOutlined />} size="small" className="rounded-lg bg-blue-700 !text-white" />	 select any answer option and click this icon to eliminate it.</li>
                <li><Button size="small" className="rounded-lg bg-blue-700 !text-white" icon={<FlagOutlined />} /> mark the question for later study by selecting this icon.</li>
                <li><Button size="small" className="rounded-lg bg-blue-700 !text-white" icon={<MessageOutlined />} ></Button> write notes. You may review your notes after the test is completed.</li>
              </ul>
              <div className="underline font-medium text-xl mt-5">Completing a test</div>
              <p>Here’s how to finish the test and review your performance:</p>
              <ul className="list-disc list-outside ml-4">
                <li><span className="font-semibold">Submit Test:</span> To conclude the test and view your total score, click “SUBMIT.”</li>
                <li><span className="font-semibold">Review Your Answers:</span> On the Results page, click “Review All” to access your answers. If any of your answers were incorrect, the correct response and an explanation will be provided.</li>
                <li><span className="font-semibold">Completion:</span> After you’ve reviewed all your answers, click “Close” to return to the Chapter Resources page.</li>
              </ul>
              <div className="mt-5">
                <Space wrap>
                  <Button onClick={() => redirectToStartTest()} className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" icon={<FileDoneOutlined />}>{constants.startTest}</Button>
                  <Button onClick={() => {
                    const state = {
                      chapter_id: location?.state?.chapter_id
                    };
                    navigate('/chapter-resources', { state, replace: true });
                  }}
                    className='rounded-lg bg-blue-700 text-white'
                    icon={<ArrowLeftOutlined />}>
                    {constants.BackToChapter}
                  </Button>
                </Space>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default Instructions;
