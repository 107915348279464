import React, { useState, useEffect } from 'react';
import { getCustomisedBreadcrumbs, setStartandEnddate, changeDateFormat } from '../../../../functions/functions';
import constants from '../../../../constants/constants';
import { Chart } from "react-google-charts";
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Table,
	Form,
	DatePicker,
	Select,
	Empty
} from 'antd';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { fetchAdminApi } from '../../../../services/api/index';
const { RangePicker } = DatePicker;

const ResourcesTaken = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const dateFormat = 'MM/DD/YYYY';
	const [resourcetype, setResourcetype] = useState(sessionStorage.getItem("selectedSection"));
	//Test page states(resource = 0)
	const [testdata, setTestData] = useState([]);
	const [testChartdata, setTestChartData] = useState([]);
	const [testcount, setTotalTestCount] = useState();
	const [startdatefortest, setStartDateForTest] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddatefortest, setEndDateForTest] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
	const [periodfortest, setPeriodForTest] = useState(0);

	//Tutorial page states(resource = 1)
	const [tutorialdata, setTutorialData] = useState([]);
	const [tutorialChartdata, setTutorialChartData] = useState([]);
	const [tutorialcount, setTotalTutorialCount] = useState();
	const [startdatefortutorial, setStartDateForTutorial] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddatefortutorial, setEndDateForTutorial] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
	const [periodfortutorial, setPeriodForTutorial] = useState(0);

	//Flashcard page states(resource = 5)
	const [flashcarddata, setFlashcardData] = useState([]);
	const [flashcardChartdata, setFlashcardChartData] = useState([]);
	const [flashcardcount, setTotalFlashcardCount] = useState();
	const [startdateforflashcard, setStartDateForFlashcard] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddateforflashcard, setEndDateForFlashcard] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
	const [periodforflashcard, setPeriodForFlashcard] = useState(0);

	const [newCrumb,setCrumb] =useState( [<Link key={1} className="!text-black font-semibold">{resourcetype == 0 ? constants.totalTestsTakenBreadcrumbLink : resourcetype == 6 ? constants.totalFlashcardsTakenBreadcrumbLink : constants.totalTutorialsTakenBreadcrumbLink}</Link>])
	useEffect(() => {
		if (resourcetype && (startdatefortest && enddatefortest) || (startdatefortutorial && enddatefortutorial) || (startdateforflashcard && enddateforflashcard)) {
			getHorizontalChartData();
		}
	}, [resourcetype, startdatefortest, enddatefortest, startdatefortutorial, enddatefortutorial, startdateforflashcard, enddateforflashcard]);
	const getTestDataAndRedirect = (record, index) => {
		let month = record.month;
		let chosenYear = record.year;
		if (month && chosenYear) {
			let startD = dayjs().year(chosenYear).month(month - 1).startOf('month').format('MM/DD/YYYY');
			let endD = dayjs().year(chosenYear).month(month - 1).endOf('month').format('MM/DD/YYYY');
			let recordData = {};
			recordData.recordObj = record;
			recordData.startdate = startD;
			recordData.enddate = endD;
			recordData.period = undefined;
			navigate('/test-scores', { state: { totalTestObject: recordData } });
		}
	}
	const getTutorialDataAndRedirect = (record, index) => {
		let month = record.month;
		let chosenYear = record.year;
		if (month && chosenYear) {
			let startD = dayjs().year(chosenYear).month(month - 1).startOf('month').format('MM/DD/YYYY');
			let endD = dayjs().year(chosenYear).month(month - 1).endOf('month').format('MM/DD/YYYY');
			let recordData = {};
			recordData.recordObj = record;
			recordData.startdate = startD;
			recordData.enddate = endD;
			recordData.period = undefined;
			navigate('/tutorial-scores', { state: { totalTutorialObject: recordData } });
		}
	}
	const resourceTableColumns = [
		{
			title: constants.monthAndYear,
			key: constants.monthAndYear,
			align: 'left',
			render: (text, record, index) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer' 
						onClick={() => resourcetype == 0 ? getTestDataAndRedirect(record, index) : resourcetype == 6 ? getFlashcardDataAndRedirect(record,index) : getTutorialDataAndRedirect(record,index)}
					>
						{record.month_in_words}	{record.year}
					</div>
				)
			}
		},
		{
			title: resourcetype == 0 ? constants.Tests : resourcetype == 6 ? constants.Flashcards :constants.Tutorials,
			key: resourcetype == 0 ? constants.Tests : resourcetype == 6 ? constants.Flashcards : constants.Tutorials,
			align: "right",
			render: (text, record, index) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer' 
						onClick={() => resourcetype == 0 ? getTestDataAndRedirect(record, index) : resourcetype == 6 ? getFlashcardDataAndRedirect(record) : getTutorialDataAndRedirect(record)}
					>
						{record.total}
					</div>
				)
			}
		}
	]
	const getFlashcardDataAndRedirect = (record, index) => {
		let month = record.month;
		let chosenYear = record.year;
		if (month && chosenYear) {
			let startD = dayjs().year(chosenYear).month(month - 1).startOf('month').format('MM/DD/YYYY');
			let endD = dayjs().year(chosenYear).month(month - 1).endOf('month').format('MM/DD/YYYY');
			let recordData = {};
			recordData.recordObj = record;
			recordData.startdate = startD;
			recordData.enddate = endD;
			recordData.status = 4
			navigate('/flashcard-result', { state: { totalFlashcardObject: recordData }});
		}
	}
	//Onchange set value for rangepicker(Tests)
	const getStartandEnddateForTests = (value, dateString) => {
		setStartDateForTest(dateString[0]);
		setEndDateForTest(dateString[1]);
		setPeriodForTest();
	}
	//Onchange set value for rangepicker(Tutorials)
	const getStartandEnddateForTutorials = (value, dateString) => {
		setStartDateForTutorial(dateString[0]);
		setEndDateForTutorial(dateString[1]);
	}
	//Onchange set value for rangepicker(Flashcards)
	const getStartandEnddateForFlashcards = (value, dateString) => {
		setStartDateForFlashcard(dateString[0]);
		setEndDateForFlashcard(dateString[1]);
	}
	const getHorizontalChartData = () => {
		fetchAdminApi(
			resourcetype == 6 
			? 
			(`/total-flashcards-taken?start_date=${changeDateFormat(startdateforflashcard)}&end_date=${changeDateFormat(enddateforflashcard)}`) 
			:
				(`/total-tests-taken?start_date=${changeDateFormat(resourcetype == 0 ? startdatefortest : startdatefortutorial)}&end_date=${changeDateFormat(resourcetype == 0 ? enddatefortest : enddatefortutorial)}&type=${resourcetype}`)
			, "get") 
		.then(
			(res) => {
				if (res && res?.data.all.length > 0) {
					let chartHeader = [['Month', resourcetype == 0 ? 'Tests' : resourcetype == 6?'Flashcards' :'Tutorials', { role: 'annotation' }]];
					for (let index = 0; index < res?.data?.all.length; index++) {
						if (res?.data.all[index].total) {
							let all = [];
							all.push(`${(res?.data.all[index].month_in_3_words).toUpperCase()} ${res?.data.all[index].year}`, resourcetype == 0 ? Number(res?.data.all[index].total) : resourcetype == 6 ? Number(res?.data.all[index].total) : Number(res?.data.all[index].total), resourcetype == 0 ? res?.data.all[index].total : resourcetype == 6 ? res?.data.all[index].total :res?.data.all[index].total);
							chartHeader.push(all);
						}
					}
					resourcetype == 0 ? setTestChartData(chartHeader) : resourcetype == 6 ? setFlashcardChartData(chartHeader) :setTutorialChartData(chartHeader);
					resourcetype == 0 ? setTotalTestCount(res?.data?.all_total) : resourcetype == 6 ? setTotalFlashcardCount(res?.data?.all_total) : setTotalTutorialCount(res?.data?.all_total);
					resourcetype == 0 ? setTestData(res?.data?.all) : resourcetype == 6 ? setFlashcardData(res?.data?.all) :setTutorialData(res?.data?.all);
				} else {
					setTestData([])
					setTutorialData([]);
					setFlashcardData([]);
					setTotalTestCount(0)
					setTotalTutorialCount(0);
					setTotalFlashcardCount(0);
				}
			})
	}
	const options = {
		title: `Total ${resourcetype == 0 ? 'tests' : resourcetype == 6 ? 'flashcards' : 'tutorials'} taken for the period ${resourcetype == 0 ? startdatefortest : resourcetype == 6 ? startdateforflashcard : startdatefortutorial} - ${resourcetype == 0 ? enddatefortest : resourcetype == 6 ? enddateforflashcard : enddatefortutorial}`,
		titleTextStyle: {
			fontSize: 19,
			bold: 'true',
			color: '#8a1253',
		},
		vAxis: {
			title: constants.monthAndYear,
			textStyle: {
				fontSize: 14,
				color: '#000',
				bold: 'true'
			},
			titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
		},
		bar: { groupWidth: "60%" },
		bars: "horizontal",
		is3D: true,
		colors: resourcetype == 0 ? ['#a696c8'] : resourcetype == 6 ? ['#92a4c0'] : ['#d3a284'] ,
		animation: {
			duration: 1000,
			easing: 'linear',
			startup: true
		},
		legend: { position: 'top' },
		'hAxis': {
			title: resourcetype == 0 ? constants.numberOfTests : resourcetype == 6 ? constants.numberOfFlashcards : constants.numberOfTutorials,
			gridlines: { count: -10 }, minorGridlines: 'NULL', textStyle: { fontSize: 14, color: '#000', bold: 'true' }, format: '####', titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
			minValue:2,
			viewWindow: {
				min: 0
			},
		},
		tooltip: { isHtml: true },
	};

	return (
		<div className="cursorChange">
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card title={resourcetype == 0 ? constants.totalTestsTaken : resourcetype == 6 ? constants.totalFlashcardsTaken : constants.totalTutorialsTaken} className="addQcard" hoverable>
							<Row>
								<Col xs={24} sm={24} md={24} lg={24}>
									<Card className='mb-3'>
										<div><span className="font-bold text-base">{constants.searchCriteriaTitle}</span> <span className='font-bold text-red-700 pl-1 text-base'>Period : </span><span className="font-bold text-green-700 pl-2 text-base">{resourcetype == 0 ? startdatefortest : resourcetype == 6 ? startdateforflashcard : startdatefortutorial} - {resourcetype == 0 ? enddatefortest : resourcetype == 6 ? enddateforflashcard : enddatefortutorial}</span></div>
									</Card>
								</Col>
							</Row>
							<Row>
								<Col xs={24} md={8} lg={8}>
									<Table
										bordered
										pagination={false}
										dataSource={resourcetype == 0 ? testdata : resourcetype == 6 ? flashcarddata : tutorialdata}
										columns={resourceTableColumns}
										className='registrationTable'
										rowKey={(record) => record.month_in_words}
										title={() => { return <div className="font-bold text-base text-white">{resourcetype == 0 ? 'TOTAL TESTS' : resourcetype == 6 ? 'TOTAL FLASHCARDS' : 'TOTAL TUTORIALS'}</div> }}
										size="small"
										summary={() => {
											return (
												<Table.Summary fixed>
													<Table.Summary.Row>
														<Table.Summary.Cell index={0} className="font-bold text-base text-left">{constants.total}</Table.Summary.Cell>
														<Table.Summary.Cell index={1} className="font-bold text-base text-right">{resourcetype == 0 ? testcount : resourcetype == 6 ? flashcardcount : tutorialcount}</Table.Summary.Cell>
													</Table.Summary.Row>
												</Table.Summary>
											)
										}}
									/>
								</Col>
								<Col xs={24} sm={24} md={16} lg={16}>
									<Card hoverable className=" mb-2 ml-3">
										<Form form={form} className='countriesByregistrationFilters'>
											<Row gutter={24}>
												<Col span={12}>
													<Form.Item label="From">
														<RangePicker
															defaultValue=
															{
																[
																	dayjs(resourcetype == 0 ? startdatefortest : resourcetype == 6 ? startdateforflashcard : startdatefortutorial, dateFormat), dayjs(resourcetype == 0 ? enddatefortest : resourcetype == 6 ? enddateforflashcard : enddatefortutorial, dateFormat)
																]
															}
															format={dateFormat}
															className='w-full'
															onChange={resourcetype == 0 ? getStartandEnddateForTests : resourcetype == 6 ? getStartandEnddateForFlashcards : getStartandEnddateForTutorials}
															value= 
															{
																[
																	dayjs(resourcetype == 0 ? startdatefortest : resourcetype == 6 ? startdateforflashcard : startdatefortutorial, dateFormat), dayjs(resourcetype == 0 ? enddatefortest : resourcetype == 6 ? enddateforflashcard : enddatefortutorial, dateFormat)
																]
															}
															allowClear={false}
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item label="Period">
														<Select
															className='w-full rounded-md'
															onChange=
															{
																(value) => 
																setStartandEnddate(value, 
																	resourcetype == 0 ? startdatefortest : resourcetype == 6 ? startdateforflashcard : startdatefortutorial, 
																	resourcetype == 0 ? enddatefortest : resourcetype == 6 ? enddateforflashcard : enddatefortutorial, 
																	resourcetype == 0 ? setStartDateForTest : resourcetype == 6 ? setStartDateForFlashcard : setStartDateForTutorial, 
																	resourcetype == 0 ? setEndDateForTest : resourcetype == 6 ? setEndDateForFlashcard : setEndDateForTutorial, 
																	resourcetype == 0 ? setPeriodForTest : resourcetype == 6 ? setPeriodForFlashcard : setPeriodForTutorial
																	)
															}
															defaultValue={constants.periodOptions[0]}
															value={resourcetype == 0 ? periodfortest : resourcetype == 6 ? periodforflashcard : periodfortutorial}
														>
															{constants.periodOptions.map((option, index) => (
																<Select.Option key={option} value={index}>
																	{option}
																</Select.Option>
															))}
														</Select>
													</Form.Item>
												</Col>
											</Row>
										</Form>
									</Card>
									<div>
										<Card className='ml-5' hoverable>
											{
												(resourcetype == 0 && testcount != 0) || (resourcetype == 1 && tutorialcount != 0) || (resourcetype == 6 && flashcardcount != 0)
												?
													<Chart
														chartType="BarChart"
														className='w-full'
														width={'100%'}
														height={'600px'}
														loader={<div>{constants.loadingChartMessage}</div>}
														data={resourcetype == 0 ? testChartdata : resourcetype == 6 ? flashcardChartdata : tutorialChartdata}
														options={options}
													/>
												:
													<div className=''><Empty description={false}><span className="text-lg text-center font-bold font-sans text-sky-600">{constants.emptyChartDataMessage}</span></Empty></div>
											}
										</Card>
									</div>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default ResourcesTaken
