import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Breadcrumb, Form, Card, Input, Upload, Select, message, Image } from 'antd';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { getCustomisedBreadcrumbs,success } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { fetchAdminApi } from '../../../services/api';
import { PlusOutlined } from '@ant-design/icons';
import { COOKIE, getCookie } from '../../../../src/services/cookie/index';
const { Dragger } = Upload;

function EditImage() {
	const [form] = Form.useForm();
	const [messageApi, contextHolder] = message.useMessage();
	const navigate = useNavigate();
	const location = useLocation() || {};
	const { chapterId, entireRecord } = location.state || {};
	const [chapterid, setChapterid] = useState(chapterId);
	const [resourceid, setresourceId] = useState('');
	const [captionText, setCaptiontext] = useState('');
	const [details, setDetails] = useState('');
	const [imageurl, setImageurl] = useState('');
	const [thumbimageurl, setThumbimageurl] = useState('');
	const [imagename, setImagename] = useState('');
	const [originalImage, setOriginalimage] = useState('');
	const [imageinfo, setImageinfo] = useState([]);
	const [allchapters,setAllchapters] = useState([]);
	const [defaultid, setDefaultId] = useState('');
	const [fileList, setFileList] = useState([{
		uid: '-1',
		status: 'done',
		url: imageurl,
		thumbUrl: thumbimageurl,
		name: imagename,
	}])

	useEffect(() => {
		let userId = getCookie(COOKIE.UserId);
		if (!userId) {
			navigate('/admin-login');
		}
	}, []);

	const newCrumb = [<Link key={0} to="/image-list" className="font-semibold">Images</Link>, <Link key={0} to={`/editImage`} className="font-semibold !text-black">Edit Image</Link>]
	const changeCaptiontext = (e) => {
		setCaptiontext(e.target.value);
	}
	const changeDetails = (e) => {
		setDetails(e.target.value);
	}
	const props = {
		defaultFileList:[...fileList]
	}
	const changeImage = (info) => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);
		setFileList(fileList);
	}
	const getImage = (e) => {
		setImageinfo(e ?.fileList);
		if (Array.isArray(e)) {
			return e;
		}
		return e ?.fileList;
	}
	const getchapters = () => {
		const chapters = JSON.parse(localStorage.getItem('chapterDetails'))
		if (chapters && chapters != '') {
			setAllchapters(chapters)
		}
	}
	useEffect(()=>{
		getImageDetails()
	},[])
	useEffect(() => {
		if (chapterId && chapterId != '') {
			setChapterid(chapterId);
			setresourceId(entireRecord.resource_file_id);
		}
		getchapters();
		if (allchapters && allchapters != '') {
			setDefaultId(allchapters.filter((el) => { return el.value == chapterId }))
		}
	}, [resourceid])
	const getImageDetails = () => {
		if (chapterId && chapterId != '') {
			fetchAdminApi(`/get-image-info?resource_id=${Number(entireRecord.resource_file_id)}`, 'get').then((res) => {
				if (res && res != '') {
					setCaptiontext(res.data ? res.data[0].caption : '');
					setDetails(res.data ? res.data[0].details : '');
					setImageurl(res.data ? res.data[0].resource_name : '');
					setThumbimageurl(res.data ? res.data[0].thumbnail_name : '');
					setImagename(res.data ? res.data[0].image_name : '');
					setOriginalimage(res.data ? res.data[0].original_name : '');
					setFileList((prev) => [{
						uid: '-1',
						status: 'done',
						url: res.data[0].resource_name,
						thumbUrl: res.data[0].thumbnail_name,
						name: res.data[0].image_name
					}]
					)
					form.setFieldsValue({
						caption: res.data[0].caption,
						image: res.data[0].thumbnail_name,
						smallImage: res.data[0].resource_name,
						captionDetails: res.data[0].details != "null" || res.data[0].details != "" ? res.data[0].details:'',
					})
				}
			})
		}
	}
	const changeImageChapterid = (value)=>{
		setChapterid(value);
		setDefaultId(value);
		const chapterEdited = true;
		localStorage.setItem('chapterEdited', chapterEdited);
		localStorage.setItem('imgChapterchangeId', value);
	}
	const submitInfo = async (values) => {
				try {
				const formData = new FormData();
					if (imageinfo.length == 0){
						formData.append('caption', values.caption);
						formData.append('updated_by', 1);
						formData.append('resource_type', 1);
						formData.append('chapter_id', chapterid);
						formData.append('resource_id', resourceid);
						formData.append('details', details);
					}else{
						imageinfo.forEach((file) => {
							formData.append('file', file.originFileObj);
						});
						formData.append('caption', values.caption);
						formData.append('updated_by', 1);
						formData.append('resource_type', 1);
						formData.append('chapter_id', chapterid);
						formData.append('resource_id', resourceid);
						formData.append('details', details);
					}
				
				const response = await fetch(`${constants.AdminApiUrl}/upload-image`, {
					method: 'POST',
					body: formData,
					headers: {
						Authorization: `Bearer ${getCookie(COOKIE.Token)}`
					},
				});
				const data = await response.json();
				if (data.code === 200) {
					const editimageConfirmation = true;
					localStorage.setItem('imageEdited', editimageConfirmation);
					localStorage.setItem('imgChapterId', chapterId);
					navigate(`/get-chapter-images/${chapterid}`, { state: {chapterId: chapterid}});
					form.resetFields();
				
				}else{
					message.error(constants.image_added_failure);
				}
			} catch (error) {}
	}

	const redirectBack = () => {
		const imagenotEdited = true;
		localStorage.setItem('imageNotEdited', imagenotEdited);
		localStorage.setItem('imageChapterid', chapterId);
		navigate(-1);
	}

	return (
		<div className="cursorChange">
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb
								items={getCustomisedBreadcrumbs(constants.flashCardpageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-lg p-3 mb-3' />
						</div>
						<Card 
							className='mainflashCard'
							title={<div className='flex-space'><span className="mr-8">EDIT IMAGE</span>
								<Select
									value={chapterid}
									onChange={changeImageChapterid}
									className='w-80'
								>
									<Option value=""> --Chapter-- </Option>
									{
										allchapters.map((each) => {
											return <Option key={each.value} value={
												each.value.toString()
											}> {each.label} </Option>
										})
									}
								</Select>
							</div>
							}
							extra={<div className="font-medium text-xl text-pink-600">Image  #{resourceid}</div>}
							hoverable>
							<Form
								form={form}
								onFinish={submitInfo}
								onFinishFailed={
									() => {
										message.error(constants.image_added_failure);
									}
								}
							>
								<Card hoverable>
									<Form.Item
										label={<span
										className="font-semibold text-base caption-item pr-2 imagecardTitle">Caption</span>}
										className='caption-form-item'
										name="caption"
										rules={
											[{
												required: true,
												message: 'This field is mandatory!'
											},
											{
												validator: (_, value) =>
													value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error('This field is mandatory!')),
											},
											]
										}
									>
										<Input type='text' style={{ width: '70%' }} value={captionText} onChange={changeCaptiontext}></Input>
									</Form.Item>
								</Card>
								<Card className='mt-5' hoverable>
									<Form.Item
										label={<span
										className="font-semibold text-base caption-item pr-2 imagecardTitle">Details</span>}
										className='caption-form-item'
										name="captionDetails"
										rules={
											[{
												required: false,
												message: ''
											},
											]
										}
									>
										<Input type='text' style={{ width: '70%' }} value={details} onChange={changeDetails}></Input>
									</Form.Item>
								</Card>
								<Card className='mt-5 flex flexCard' hoverable>
									<Form.Item
										getValueFromEvent={getImage}
										action="/upload.do"
										label={<span
											className="font-semibold text-base image-item pr-3 imagecardTitle">Image</span>
										}
										name="image"
										className="image-form-item basis-4/5"
										rules={
											[{
												required: true,
												message: 'This field is mandatory!'
											},
											]
										}
									>
										<Dragger
											name="files"
											action=""
											listType="picture"
											fileList={[...fileList]}
											onChange={changeImage}
											maxCount={1}
											accept=".png,.jpeg,.jpg"
											beforeUpload={
												(file) => {
													const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
													if (!isJpgOrPng) {
														success('error', 'Error!Only allowed extensions are jpg and png...', 'flashSuccess', messageApi);
													}
												}
											} {...props}>
											<p className="ant-upload-drag-icon"><PlusOutlined /></p>
											<p className="ant-upload-text"> {constants.chooseImage} </p>
										</Dragger>
									</Form.Item>
									<Form.Item name="smallImage" className="basis-1/5 ml-3">
										<Image
											src={imageurl}
										/>
									</Form.Item>
								</Card>
								<Card className="flex justify-start items-center mt-2" hoverable>
									<Form.Item name="originalimage"
									>
										<Image
											src={originalImage}
										/>
									</Form.Item>
								</Card>
								<Button htmlType='submit' type="primary" className='mt-5 font-semibold'>Submit</Button>
								<Button type="primary" className='mt-5 ml-3 font-semibold' onClick={redirectBack}>Cancel</Button>
								{contextHolder}
							</Form>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default EditImage
