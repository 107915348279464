import React from 'react';
import {Outlet} from "react-router-dom";
import Header from './Header';
import Siders from './Siders';
import {Layout} from "antd";
const {Content} = Layout;

function MainAdminLayout() {
	return (
		<div>
			<Layout className='commonAdminlayout'>
				<Siders/>
				<Layout>
					<Header/>
					<Content className='contentOverflow'><Outlet/></Content>
				</Layout>
			</Layout>
		</div>
	)
}

export default MainAdminLayout
