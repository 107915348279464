import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import {ContextProvider} from './Context';
import {
  RouterProvider,
} from "react-router-dom";
import routerBrowser from './routes';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<ContextProvider>
		<RouterProvider router={routerBrowser} />
	</ContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
