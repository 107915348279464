import React, { useState, useEffect, } from 'react';
import {
	Row,
	Col,
	Select,
	Breadcrumb,
	Button,
	Image,
	Spin,
	Table,
	Card,
	Tooltip,
	Badge,
	Space,
	message
} from 'antd';
import {
	SearchOutlined,
	PlusOutlined,
	ExportOutlined
} from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import constants from '../../../constants/constants';
import { success, getCustomisedBreadcrumbs } from '../../../functions/functions';
import { fetchAdminApi } from '../../../services/api';

function Getchapterwisequestions() {
	const [messageApi, contextHolder] = message.useMessage();
	const navigate = useNavigate();
	const location = useLocation();
	const { chapterID,qID } = location.state || {};
	const [tableData, settableData] = useState([]);
	const [chapters, setChapters] = useState([]);
	const [searchid, setSearchid] = useState(chapterID);
	const [questionid, setQuestionid] = useState(qID == undefined ? '' : qID);
	const [questiontypevalue, setQuestionTypevalue] = useState(qID);
	const [chapterid, setChapterid] = useState(chapterID);
	const [recordcount, setRecordcount] = useState('');
	const [testedit, setTestedit] = useState(false);
	const [testadd, setTestAdd] = useState(false);
	const [testcancel, setTestCancel] = useState(false);
	const [testcanceladd, setTestcanceladd] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [changedId, setChangedId] = useState(false);

	let newCrumb = [<Link key={0} href="/" className="!text-black font-semibold">Question List</Link>]

	useEffect(() => {
		getAllTheChapters();
		setChapterid(chapterID && chapterID != '' ? chapterID : chapterid);
		setSearchid(chapterID);
		setQuestionTypevalue('');
		window.scrollTo({
			top: '-100px',
			behavior: 'smooth',
		})
	}, [])

	const getAllTheChapters = () => {
		fetchAdminApi(`/chapters-list`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				setChapters(res.data);
			} else {
				setChapters([]);
			}
		})
	}

	const getAllTheQuestions = () => {
		fetchAdminApi(`/questions-list?q_type=${questionid}&chapter_id=${searchid}`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				settableData(res.data);
				setRecordcount(res.total_count != null ? res.total_count : 0);
				setIsLoading(false);
			} else {
				if (res?.code === 400) {
					setRecordcount(0);
					setIsLoading(false);
					settableData([]);
				}
				// settableData([]);
				// 	setRecordcount(0);
				// 	setIsLoading(false);
			}
		})
	}
	

	useEffect(() => {
		getAllTheQuestions();
	}, [questionid, searchid])

	// useEffect(() => {
	// 	getAllTheQuestions();
	// }, [questionid, searchid, chapterid, questiontypevalue])

	useEffect(() => {
		if (localStorage.getItem("newId")) {
			const getId = localStorage.getItem('chapId');
			const getQuestiontype = localStorage.getItem('questionType');
			setChapterid(getId);
			setSearchid(getId);
			setQuestionid(getQuestiontype);
			setQuestionTypevalue(getQuestiontype);
			window.scrollTo(0, 0)
			success('success', constants.question_added_success, 'afterQaddsuccess', messageApi);
			localStorage.removeItem('newId');
			setTestAdd(true);
		}

		if (localStorage.getItem("cancelledClick")) {
			const getId = localStorage.getItem('cancelledId');
			const getQuestiontype = localStorage.getItem('cancelledQuestion');
			setChapterid(getId);
			setSearchid(getId);
			//setQuestionid(getQuestiontype);
			//setQuestionTypevalue(getQuestiontype);
			localStorage.removeItem('cancelledId');
			localStorage.removeItem('cancelledQuestion');
			localStorage.removeItem('cancelledClick');
			setTestCancel(true);
		}

		if (localStorage.getItem("questionEdited")) {
			const getId = localStorage.getItem('editedchapterId');
			const getQuestiontype = localStorage.getItem('editedQtype');
			setChapterid(getId);
			setSearchid(getId);
			// setQuestionid(getQuestiontype);
			// setQuestionTypevalue(getQuestiontype);
			success('success', constants.question_edited_success, 'notificationPosition', messageApi);
			localStorage.removeItem('questionEdited');
			setTestedit(true);
		}

		if (localStorage.getItem("cancelClickAdd")) {
			const getchapterId = localStorage.getItem('chapter_id');
			const getQuestiontype = localStorage.getItem('question_type');
			setChapterid(getchapterId)
			setSearchid(getchapterId);
			setQuestionid(getQuestiontype);
			setQuestionTypevalue(getQuestiontype);
			localStorage.removeItem('cancelClickAdd');
			setTestcanceladd(true);
		}

		if (localStorage.getItem("idChanged")) {
			const getId = localStorage.getItem('changedchapterId');
			setChapterid(getId);
			setSearchid(getId);
			localStorage.removeItem('idChanged');
			setChangedId(true);
		}

	}, [tableData, chapterid, questionid])
	useEffect(() => {
		setIsLoading(true);
	}, [searchid, questionid]);
	useEffect(() => {
		let filteredQuestionsafterEdit;
		let filteredQuestionsafterAdd;
		let filteredQuestionsafterCancel;
		let filteredQuestionsafterCancelAdd;
		let filteredQuestionsafterIdchange;
		if (testedit) {
			// if (chapterid !== '' && questiontypevalue !== '') {
			// 	if (tableData && tableData.length > 0) {
			// 		filteredQuestionsafterEdit = tableData.filter((each) => {
			// 			return (each.chapter_id === chapterid.toString() && each.q_type === questiontypevalue.toString())
			// 		})
			// 		settableData(filteredQuestionsafterEdit)
			// 		setRecordcount(filteredQuestionsafterEdit.length)
			// 	}
			// }
			if (chapterid !== '') {
				if (tableData && tableData.length > 0) {
					filteredQuestionsafterEdit = tableData.filter((each) => {
						return (each.chapter_id === chapterid.toString())
					})
					settableData(filteredQuestionsafterEdit)
					setRecordcount(filteredQuestionsafterEdit.length)
				}
			}
		}

		if (testadd) {
			if (searchid !== '' && questiontypevalue !== '') {
				if (tableData && tableData.length > 0) {
					filteredQuestionsafterAdd = tableData.filter((each) => {
						return (each.chapter_id === searchid.toString() && each.q_type === questiontypevalue.toString())
					})
					settableData(filteredQuestionsafterAdd)
					setRecordcount(filteredQuestionsafterAdd.length);
				}
			}
		}

		if (testcancel) {
			if (chapterid !== '') {
				if (tableData && tableData.length > 0) {
					filteredQuestionsafterCancel = tableData.filter((each) => {
						return (each.chapter_id === chapterid.toString())
					})
					settableData(filteredQuestionsafterCancel);
					setRecordcount(filteredQuestionsafterCancel.length);
				}
			}
		}

		if (testcanceladd) {
			if (searchid !== '' && questiontypevalue !== '') {
				if (tableData && tableData.length > 0) {
					filteredQuestionsafterCancelAdd = tableData.filter((each) => {
						return (each.chapter_id === searchid.toString() && each.q_type === questiontypevalue.toString())
					})
					settableData(filteredQuestionsafterCancelAdd);
					setRecordcount(filteredQuestionsafterCancelAdd.length)
				}
			}
		}

		if (changedId) {
			if (chapterid !== '' && questiontypevalue !== '') {
				if (tableData && tableData.length > 0) {
					filteredQuestionsafterIdchange = tableData.filter((each) => {
						return (each.chapter_id === chapterid.toString() && each.q_type === questiontypevalue.toString())
					})
					settableData(filteredQuestionsafterIdchange);
					setRecordcount(filteredQuestionsafterIdchange.length)
				}
			}
		}

	}, [searchid, questiontypevalue, testedit, testadd, testcancel, testcanceladd, changedId])
	const chaptertypeOps = chapters.map((each, index) => {
		return {
			value: index + 1,
			label: each.chapter
		}
	});

	let stringifiedChapters = JSON.stringify(chaptertypeOps);
	localStorage.setItem('chapterDetails', stringifiedChapters);

	const questiontypeOps = [
		{
			value: '1',
			label: "Single Select"
		},
		{
			value: '2',
			label: "Image Question"
		}
	].map((qType) => {
		return {
			value: qType.value,
			label: qType.label
		}
	})
	const handleChapterChange = (value) => {
		setChapterid(value);
	}
	const handleQuestionChange = (value) => {
		setQuestionTypevalue(value);
	}
	const redirectToAddQuestionPage = () => {
		localStorage.setItem('chapterID', chapterid);
		localStorage.setItem('questionID', questiontypevalue);
		const chapid = localStorage.getItem('chapterID');
		const qid = localStorage.getItem('questionID');
		if (chapid != '' && qid != '') {
			navigate('/addQuestion', {
				state: {
					data: [{
						id: chapterid,
						qType: questiontypevalue,
						clickEvent: true,
						chapters: chaptertypeOps
					}]
				}
			});
		} else {
			success('info', constants.null_chapter_question, 'null_data_msg', messageApi);
		}
	}
	const findRecords = () => {
		let filteredRecords;
		setSearchid(chapterid);
		setQuestionid(questiontypevalue);
		if (searchid === '' && questionid !== '') {
			if (tableData && tableData.length > 0) {
				filteredRecords = tableData.filter((el) => {
					return el.q_type == questionid
				});
			}
		} else if (searchid !== '' && questionid === '') {
			if (tableData && tableData.length > 0) {
				filteredRecords = tableData.filter((el) => {
					return el.chapter_id == searchid
				});
			}
		} else{
			filteredRecords = tableData;
		}
		settableData(filteredRecords);
	}
	const tabColumns = [{
		title: <div className="text-base">Type</div>,
		dataIndex: "q_type",
		key: "q_type",
		width: 40,
		align: "center",
		render: (record) => {
			return (
				<Tooltip title={record === "1" ? "Single Select" : "Image Question"}>
					{record === "1"
						?
						(<Badge count="SS" color="#008B8B" className='single' />)
						:
						(<Badge count="IQ" color="#BDB76B" className='image' />)
					}
				</Tooltip>
			);
		},
	},
	{
		title: <div className='text-base'>Chapter</div>,
		dataIndex: "chapter_id",
		key: "chapter_id",
		sorter: (a, b) => a.chapter_id - b.chapter_id,
		align: "center",
		width: 40,
	},
	{
		title: <div className='text-base'>Qn #</div>,
		dataIndex: "question_id",
		key: "question_id",
		sorter: (a, b) => a.question_id - b.question_id,
		align: "center",
		width: 40,
		render: (question_id) => {
			return (
				<div className='font-medium'> #{question_id}</div>
			)
		}
	},
	{
		title: <div className='text-base'> Image </div>,
		dataIndex: "thumb_image_path",
		key: "thumb_image_path",
		align: "center",
		width: 50,
		render: (thumb_image_path,record) => {
			return (
				record?.thumb_image_path
					?
					<Image src={record.thumb_image_path} alt="image" 
					></Image>
					:
					<div></div>
			)
		}
	},
	{
		title: <div className='text-base'> Question </div>,
		dataIndex: "question_text",
		key: "question_text",
		width: 250,
		align: 'left',
		render: (question_text, record) => (
			<Tooltip placement="topLeft"
				title={question_text}>
				<Link className='linkColor text-sm'
					to={`/editQuestion/${record.question_id}`}
					state={
						{
							questionid: record.question_id,
							entireRecord: record
						}
					}> {question_text}
				</Link>
			</Tooltip>
		),
		ellipsis: {
			showTitle: false,
			color: '#0CAFFF',
		},
	},
	{
		title: <div className='text-base'> Points </div>,
		dataIndex: "points",
		key: "points",
		align: "center",
		width: 50,
	},
	];

	return (
		<div>
			<Row className="grayishBackground">
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="p-4 bg-gray-100">
						<Breadcrumb items={getCustomisedBreadcrumbs(constants.questionPagecrumbs, newCrumb)}
							className='bg-neutral-100 shadow-lg p-3 mb-3' />
						<Card className="mb-3">
							<Space wrap>
								<Select
									onChange={handleChapterChange}
									className='w-[180px] md:w-80'
									value={chapterid}>
									<Option value=""> --Chapter-- </Option>
									{
										chapters.map((each, index) =>
										(<Option key={index + 1}
											value={each.value}>
											{each.chapter}
										</Option>
										))
									}
								</Select>
								<Select
									onChange={handleQuestionChange}
									value={questiontypevalue}
									className='w-[180px]'
								>
									<Option value="">--Question Type--</Option>
									{
										questiontypeOps.map((each, index) => (
											<Option
												key={index}
												value={each.value}
											>{each.label}</Option>
										))
									}
								</Select>
								<Button
									type="primary"
									className='font-semibold bg-cyan-700'
									onClick={findRecords}>
									<SearchOutlined /> Search
								</Button>
								{contextHolder}
								<Button
									type="primary"
									className='font-semibold bg-cyan-700'
									onClick={redirectToAddQuestionPage}>
									<PlusOutlined /> Add
								</Button>
								<Button
									type="primary"
									className='font-semibold bg-cyan-700'>
									<ExportOutlined /> Export
								</Button>
							</Space>
						</Card>
						<div className='font-semibold mb-3 text-sm pl-3'> {recordcount} {constants.recordCount}</div>
						<Table
							columns={tabColumns}
							dataSource={tableData}
							rowKey={record => record.key}
							pagination={false}
							bordered
							scroll={{ x: 768 }}
							locale={{ emptyText: constants.emptyTableText }}
							loading={{ indicator: <div> <Spin /> </div>, spinning: isLoading }} 
							summary={(pageData) => {
							
								let totalPoints = 0;
								pageData.forEach(({points}) => {
									totalPoints += Number(points)
								});
								return(
								<Table.Summary>
									<Table.Summary.Row>
										<Table.Summary.Cell index={0} colSpan={5} className="font-bold text-base">Total Points</Table.Summary.Cell>
											<Table.Summary.Cell index={1} className="font-bold text-base text-center">{totalPoints}</Table.Summary.Cell>
									</Table.Summary.Row>
								</Table.Summary>
								)
							}}	
							/>
					</div>
				</Col>
			</Row>
		</div>
	)
}
export default Getchapterwisequestions;
