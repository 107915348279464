import React,{useEffect} from 'react';
import { FieldTimeOutlined, CopyrightOutlined, UsergroupAddOutlined, FileSearchOutlined, BankOutlined, UnderlineOutlined, StrikethroughOutlined, FormOutlined, FileDoneOutlined, FileSyncOutlined, ContainerOutlined, FilePdfOutlined, TrademarkCircleOutlined, ProfileOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useNavigate,useLocation } from 'react-router-dom';
import {Row,Col,Card,} from 'antd';
import constants from '../../../../constants/constants';

function AnalysisMenus() {
	const navigate = useNavigate();
	let location = useLocation();

	useEffect(() => {
		if (location.pathname == '/analysis-menus' && sessionStorage.getItem("selectedSection")) {
			sessionStorage.clear();
		}
	}, [])

	const getResourceTypeAndRedirect = (resourceType,block,section) => {
		sessionStorage.setItem("selectedSection", resourceType);
		if(block == 1){
			navigate(resourceType == 0 ? '/total-tests-taken' : '/total-tutorials-taken');
		} else if (block == 2){
			navigate(resourceType == 0 ? '/test-scores' : '/tutorial-scores');
		} else if (block == 5){
			navigate(section == 6 ? '/total-flashcards-taken' : '/flashcard-result');
		}else{
			navigate(resourceType == 0 ? '/test-result' :'/tutorial-result');
		}
	}
	const getStudyTypeResourceAndRedirect = (section, block) => {
		if (block == 4) {
			sessionStorage.setItem('block',section);
			navigate(section == 0 ? '/total-study-guide-downloads' : '/study-guide-downloads');
		}
	}

	const menuIconInfoAnalysisdataforRegistrations = [
		{
			icon: <FieldTimeOutlined className="text-4xl"/>,
			additionalClass: '',
			title: 'Period',
			path: '/total-registrations',
		},
		{
			icon: <CopyrightOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Country',
			path: '/registration-by-country'
		},
		{
			icon: <div className="flex"><UnderlineOutlined className="text-4xl" /><StrikethroughOutlined className="text-4xl" /></div>,
			additionalClass: '',
			title: 'US States',
			path: '/registration-in-us-states'
		},
		{
			icon: <BankOutlined className="text-4xl" />,
			additionalClass: '',
			title:'University',
			path: '/registration-by-university'
		},
		{
			icon: <UsergroupAddOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Role',
			path: '/registration-by-roles'
		},
		{
			icon: <FileSearchOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Search',
			path: '/registered-students',
			margin_left:'ml-5'
		}
	]
	const menuIconInfoAnalysisdataforTests = [
		{
			icon: <FileDoneOutlined className="text-4xl" />,
			additionalClass: '',
			block:1,
			title: 'Total Tests Taken',
		},
		{
			icon: <FormOutlined className="text-4xl" />,
			additionalClass: '',
			block:2,
			title:'Tests Taken',
		},
		{
			icon: <TrademarkCircleOutlined className="text-4xl" />,
			additionalClass: '',
			block: 3,
			title: 'Test Result',
		},
	]
	const menuIconInfoAnalysisdataforTutorials = [
		{
			icon: <FileSyncOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Total Tutorials Taken',
			block: 1,
		},
		{
			
			icon: <ContainerOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Tutorials Taken',
			block: 2,
		},
		{
			icon: <TrademarkCircleOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Tutorial Result',
			block:3,
		},
	]
	const menuIconInfoAnalysisdataforStudyGuides = [
		{
			icon: <FilePdfOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Total Study Guide Downloads',
			block: 4,
			section:0,
		},
		{
			icon: <FilePdfOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Study Guide Downloads',
			block: 4,
			section: 1,
		},
	]
	const menuIconInfoAnalysisdataforFlashcards = [
		{
			icon: <ProfileOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Total Flashcards Taken',
			block: 5,
			section: 6,
		},
		{
			icon: <ProfileOutlined className="text-4xl" />,
			additionalClass: '',
			title: 'Flashcard Result',
			block: 5,
			section: 7,
		},
	]

	const menuIconInfoAnalysisdataforVideoandImageData = [
		{
			icon: <PlayCircleOutlined className="text-4xl" />,
			title: constants.TotalVideoViews,
			pathname:"/total-video-views"
		}
	]

	return (
		<div className='cursorChange'>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen'>
						<div className='tracking-wider !font-bold text-lg !text-cyan-700 mt-2 mb-3 text-center font-sans bg-white py-1'>ANALYSIS
						</div>
						<Card title="REGISTRATIONS BY" className='mainflashCard tracking-wider' hoverable>
							<div className='flex flex-wrap !gap-9'>
								{menuIconInfoAnalysisdataforRegistrations.map((icon) => {
									return (
										<Row>
										<Col>
										<Card className='iconBackground w-36' hoverable>
											<div className={`flex flex-col relative items-center imgWithoutpreview !cursor-pointer`} key={icon} onClick={() => navigate(`${icon.path}`)}>
											{icon.icon}
												<span className={`iconTitle mt-3 font-semibold text-base ${icon.additionalClass}`}>{icon.title}</span>
										</div>
										</Card>
											</Col>
										</Row>
									)
								})}
							</div>
						</Card>
						<Card title="TESTS" className='mainflashCard tracking-wider mt-3' hoverable>
							<div className='flex flex-wrap !gap-9' size="large">
								{menuIconInfoAnalysisdataforTests.map((icon) => {
									return (
										<Card className='iconBackground w-48' hoverable>
											<div className={`flex flex-col items-center imgWithoutpreview !cursor-pointer`} key={icon} onClick={() => getResourceTypeAndRedirect(0,icon.block)}>
											{icon.icon}
											<span className={`iconTitle mt-3 font-semibold text-base ${icon.additionalClass}`}>{icon.title}</span>
										</div>
										</Card>
									)
								})}
							</div>
						</Card>
						<Card title="TUTORIALS" className='mainflashCard tracking-wider mt-3' hoverable>
							<div className='flex flex-wrap !gap-9' size="large">
								{menuIconInfoAnalysisdataforTutorials.map((icon) => {
									return (
										<Card className='iconBackground w-50' key='tutorial' hoverable>
											<div className={`flex flex-col items-center imgWithoutpreview !cursor-pointer`} key={icon} onClick={() => getResourceTypeAndRedirect(1, icon.block)}>
												{icon.icon}
												<span className={`iconTitle mt-3 font-semibold text-base ${icon.additionalClass}`}>{icon.title}</span>
											</div>
										</Card>
									)
								})}
							</div>
						</Card>
						<Card title="FLASHCARDS" className='mainflashCard tracking-wider mt-3' hoverable>
							<div className='flex flex-wrap !gap-9' size="large">
								{menuIconInfoAnalysisdataforFlashcards.map((icon) => {
									return (
										<Card className='iconBackground w-50' key='tutorial' hoverable>
											<div className={`flex flex-col items-center imgWithoutpreview !cursor-pointer`} key={icon} onClick={() => getResourceTypeAndRedirect(6,5,icon.section)}>
												{icon.icon}
												<span className={`iconTitle mt-3 font-semibold text-base ${icon.additionalClass}`}>{icon.title}</span>
											</div>
										</Card>
									)
								})}
							</div>
						</Card>
						<Card title="STUDY GUIDES" className='mainflashCard tracking-wider mt-3' hoverable>
							<div className='flex flex-wrap !gap-9' size="large">
								{menuIconInfoAnalysisdataforStudyGuides.map((icon,index) => {
									return (
										<Card className='iconBackground w-50' hoverable key={index}>
											<div className={`flex flex-col items-center imgWithoutpreview !cursor-pointer`} key={icon} onClick={() => getStudyTypeResourceAndRedirect(icon.section, icon.block)}>
												{icon.icon}
												<span className={`iconTitle mt-3 font-semibold text-base ${icon.additionalClass}`}>{icon.title}</span>
											</div>
										</Card>
									)
								})}
							</div>
						</Card>
						<Card title={constants.Videos} className='mainflashCard tracking-wider mt-3 uppercase' hoverable>
							<div className='flex flex-wrap !gap-9' size="large">
								{menuIconInfoAnalysisdataforVideoandImageData.map((data,index) => {
									return (
										<Card className='iconBackground w-50' hoverable key={index}>
											<div className={`flex flex-col items-center imgWithoutpreview !cursor-pointer`} key={index} onClick={() => navigate(data?.pathname,{state:data?.state})}>
												{data.icon}
												<span className={`iconTitle mt-3 font-semibold text-base ${data.additionalClass}`}>{data.title}</span>
											</div>
										</Card>
									)
								})}
							</div>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}
export default AnalysisMenus
