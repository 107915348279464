import React, { useState, useRef, useEffect } from 'react';
import { getCustomisedBreadcrumbs, changeDateFormat } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';
import {
	Breadcrumb,
	Row,
	Button,
	Input,
	Col,
	Card,
	Table,
	Form,
	DatePicker,
	Select,
	FloatButton,
	Tooltip
} from 'antd';
import { fetchAdminApi } from '../../../services/api';
import { COOKIE, getCookie } from '../../../../src/services/cookie/index';

const RegisteredStudents = (props) => {
	const [form] = Form.useForm();
	const { RangePicker } = DatePicker;
	const location = useLocation();
	const { newPeriodData } = location.state || {};
	const { countryRegData } = location.state || {};
	const { universityRegData } = location.state || {};
	const { stateRegData } = location.state || {};
	const { roleRegData } = location.state || {};
	const { allUniversityRegData } = location.state || {};
	const [current, setCurrent] = useState(1);

	const getStartDate = (mainObj) => {
		if (location.state) {
			return mainObj.startdate;
		} else {
			return null;
		}
	}
	const getEndDate = (mainObj) => {
		if (location.state) {
			return mainObj.enddate;
		} else {
			return null;
		}
	}
	const [startdate, setStartdate] = useState(newPeriodData ? getStartDate(newPeriodData) : countryRegData ? getStartDate(countryRegData) : universityRegData ? getStartDate(universityRegData) : roleRegData ? getStartDate(roleRegData) : stateRegData ? getStartDate(stateRegData) : allUniversityRegData ? getStartDate(allUniversityRegData) : '');
	const [enddate, setEnddate] = useState(newPeriodData ? getEndDate(newPeriodData) : countryRegData ? getEndDate(countryRegData) : universityRegData ? getEndDate(universityRegData) : roleRegData ? getEndDate(roleRegData) : stateRegData ? getEndDate(stateRegData) : allUniversityRegData ? getEndDate(allUniversityRegData) : '');
	const [statevalue, setStatevalue] = useState('');
	const [registeredstudents, setRegisteredStudents] = useState([]);
	const [totalStudentCount, setTotalStudentcount] = useState();
	const [usertype, setUserType] = useState();

	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [universities, setUniversities] = useState([]);
	// const [countryid, setCountryid] = useState(countryRegData ? countryRegData?.country : stateRegData ? stateRegData?.country : universityRegData ? universityRegData?.country : allUniversityRegData ? allUniversityRegData?.country : '');
	const [countryvalue, setCountryvalue] = useState('');
	const [stateid, setStateid] = useState(stateRegData ? stateRegData.state : universityRegData ? universityRegData?.state : allUniversityRegData ? allUniversityRegData?.state : '');
	const [universityid, setUniversityid] = useState(universityRegData ? universityRegData?.university : allUniversityRegData ? allUniversityRegData?.university : '');
	const [countrycode, setCountrycode] = useState(countryRegData ? countryRegData?.countryCode : stateRegData ? stateRegData?.countryCode : universityRegData ? universityRegData?.countryCode : allUniversityRegData ? allUniversityRegData?.countryCode : '');
	const [statecode, setStatecode] = useState(stateRegData ? stateRegData?.stateCode : universityRegData ? universityRegData?.stateCode : allUniversityRegData ? allUniversityRegData?.stateCode : '');
	const [noparam, setNoparam] = useState();
	const [countryIsUs, setCountryIsUs] = useState();
	const [roleCode, setRoleCode] = useState('');
	const [changeduniversitydetails, setChangeduniversityDetails] = useState([]);
	const [studentname, setStudentname] = useState('');
	const [studentnamepresent, setStudentnamepresent] = useState(false);
	const [codes, setCodes] = useState({ country: '', state: '', university: '' });
	const [studentIdentity, setStudentIdentity] = useState('');
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formdisplay, setFormDisplayed] = useState(false);
	const [formValues, setFormValues] = useState({});
	const useToggle = (initialState) => {
		const [toggleValue, setToggleValue] = useState(initialState);
		const toggler = () => { setToggleValue(!toggleValue) };
		return [toggleValue, toggler]
	};
	const [toggle, setToggle] = useToggle();
	const [newCrumb, setNewCrumb] = useState([<Link className="!text-black font-semibold">Registered Students</Link>]);

	const [rolesArr] = useState([
		{
			status: 'Student',
			value: '0',
		},
		{
			status: 'Professional',
			value: '1',
		},
		{
			status: 'Faculty',
			value: '2',
		},
		{
			status: 'Other',
			value: '3',
		},
		{
			status: 'Unspecified',
			value: '4',
		}
	]);

	const onShowSizeChange = (current, pageSize) => {
		console.log(current, pageSize);
	};


	const onChange = (page) => {
		setCurrent(page);
		fetchAdminApi(`/listing-studentinfo?limit=10&page=${page - 1}`, "get").then((res) => {
			if (res) {
				console.log(res);
			}
		});
	};

	const pagination = {
		showSizeChanger: true,
		onShowSizeChange: onShowSizeChange,
		onChange: onChange,
		pageSizeOptions: ["10", "20", "50", "100"],
		defaultPageSize: 10,
	};
	const dateFormat = 'MM/DD/YYYY';
	useEffect(() => {
		getAllCountries();
		getAllStates();
		getAllUniversities();
		if (localStorage.getItem('userType')) {
			const user = localStorage.getItem('userType');
			setUserType(user);
		}
	}, [])

	useEffect(() => {
		getFilteredData();
		if (location.state) {
			fetchRegisteredStudents();
		}
	}, [])

	const getFilteredData = () => {
		if (location.state) {
			if (newPeriodData) {
				setNoparam(false);
				form.setFieldsValue({ country: '', fromandtoDate: [dayjs(newPeriodData?.startdate, dateFormat), dayjs(newPeriodData?.enddate, dateFormat)] });
				setNewCrumb([<Link key={0} to="/total-registrations" className="font-semibold">
					Total Registrations</Link>, <a key={1} to='' className="!text-black font-semibold">Registered Students</a>])
			} else if (countryRegData) {
				setNoparam(false);
				form.setFieldsValue({ country: countryRegData.country, fromandtoDate: [dayjs(countryRegData?.startdate, dateFormat), dayjs(countryRegData?.enddate, dateFormat)] });
				countryRegData.country == '238' ? setCountryIsUs(true) : setCountryIsUs(false);
				setCodes({ ...codes, country: countryRegData?.countryCode })
				setNewCrumb([<Link key={0} to="/registration-by-country" className="font-semibold">
					Registrations by Countries</Link>, <a key={1} className="!text-black font-semibold">Registered Students</a>])
			} else if (stateRegData) {
				setNoparam(false);
				stateRegData.country == '238' ? setCountryIsUs(true) : setCountryIsUs(false);
				form.setFieldsValue({ country: stateRegData.country, state: stateRegData.state, fromandtoDate: [dayjs(stateRegData?.startdate, dateFormat), dayjs(stateRegData?.enddate, dateFormat)] });
				setCodes({ ...codes, country: stateRegData?.countryCode, state: stateRegData?.stateCode })
				setNewCrumb([<Link key={0} to="/registration-in-us-states" className="font-semibold">
					Registrations by US States</Link>, <a key={1} className="!text-black font-semibold">Registered Students</a>])
			} else if (universityRegData) {
				setNoparam(false);
				form.setFieldsValue({ country: universityRegData.country, state: universityRegData.state, university: Number(universityRegData.university), fromandtoDate: [dayjs(universityRegData?.startdate, dateFormat), dayjs(universityRegData?.enddate, dateFormat)], role: (universityRegData.occupation).toString() });
				setCodes({ country: universityRegData?.countryCode, state: universityRegData?.stateCode, university: universityRegData?.universityName })
				setRoleCode(universityRegData.roletitle);
				universityRegData.country == '238' ? setCountryIsUs(true) : setCountryIsUs(false);
				setNewCrumb([<Link key={0} to="/registration-by-university" className="font-semibold">
					Top 10 University Registrations</Link>, <a key={1} className="!text-black font-semibold">Registered Students</a>])
			} else if (roleRegData) {
				console.log(roleRegData);
				setNoparam(false);
				setRoleCode(roleRegData.roleName);
				form.setFieldsValue({ role: roleRegData.occupation != null?(roleRegData.occupation).toString():'4', fromandtoDate: [dayjs(roleRegData?.startdate, dateFormat), dayjs(roleRegData?.enddate, dateFormat)] });
				setNewCrumb([<Link key={0} to="/registration-by-roles" className="font-semibold">
					Registrations by Roles</Link>, <a key={1} className="!text-black font-semibold">Registered Students</a>])
			} else if (allUniversityRegData) {
				setNoparam(false);
				allUniversityRegData.country == '238' ? setCountryIsUs(true) : setCountryIsUs(false);
				form.setFieldsValue({ country: allUniversityRegData.country, state: allUniversityRegData.state, university: Number(allUniversityRegData.university), role: (allUniversityRegData.occupation).toString(), fromandtoDate: [dayjs(allUniversityRegData?.startdate, dateFormat), dayjs(allUniversityRegData?.enddate, dateFormat)] });
				setCodes({ country: allUniversityRegData?.countryCode, state: allUniversityRegData?.stateCode, university: allUniversityRegData?.universityName })
				setRoleCode(allUniversityRegData.roletitle);
				setNewCrumb([<Link key={0} to="/registration-by-university" className="font-semibold">
					Top 10 University Registrations</Link>, <a key={1} className="!text-black font-semibold">Registered Students</a>])
			}
		} else {
			setNoparam(true);
			setTotalStudentcount(0);
			setCountryIsUs(false);
			form.setFieldsValue({ country: '', state: '', university: '', role: '', fromandtoDate: '', studentName: '' });
			setNewCrumb([<a key={0} className="!text-black font-semibold">Registered Students</a>])
		}
	}

	useEffect(() => {
		if (universityRegData || allUniversityRegData || stateRegData) {
			Object.entries(universities).forEach(([key, value]) => {
				if (key == statecode) {
					let obj = {};
					obj[universityRegData?.state] = value;
					setChangeduniversityDetails(obj);
				} else if (value === null) {
					setChangeduniversityDetails(null);
				}
			});
		}
	}, [universities])

	const fetchRegisteredStudents = () => {
		const formValues = form.getFieldValue();
		setFormValues(formValues);
		setFormDisplayed(true);
		let url = `/listing-studentinfo?`;
		if (formValues.fromandtoDate) {
			url += `&start_date=${changeDateFormat(startdate)}&end_date=${changeDateFormat(enddate)}`;
		}
		if (countryRegData) {
			url += `&country_id=${formValues.country}`;
		}
		if (stateRegData) {
			url += `&country_id=${formValues.country}&state_id=${formValues.state}`;
		}
		if (universityRegData) {
			url += `&country_id=${formValues.country}&state_id=${formValues.state}&university_id=${formValues.university}&occupation=${formValues.role}`;
		}
		if (roleRegData) {
			url += `&occupation=${formValues.role}`;
		}
		if (allUniversityRegData) {
			url += `&country_id=${formValues.country}&state_id=${formValues.state}&university_id=${formValues.university}&occupation=${formValues.role}`;
		}
		setTabledata(url);
	};

	const getAllCountries = () => {
		fetchAdminApi(`/admin-countries`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				res?.data.forEach(function (country, i) {
					if (country.country_id == "238") {
						res?.data.splice(i, 1);
						res?.data.unshift(country);
						return res?.data;
					}
				});
				setCountries(res?.data);
			} else {
				setCountries([]);
			}
		})
	}
	const getAllStates = () => {
		fetchAdminApi(`/admin-states?country_code=US`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				setStates(res?.data);
				if (statecode) {
					const selectedState = res?.data.filter((each) => (each.region_code == statecode));
					setStatecode(selectedState[0].region_name);
				} else {
					setStatecode('');
				}
			} else {
				setStates([]);
			}
		})
	}
	const getAllUniversities = () => {
		fetchAdminApi(`/admin-universities?country_code=US`, "get").then(
			(res) => {
				if (res && res?.data) {
					setUniversities(res?.data);
					Object.entries(res.data).forEach(([key, value]) => {
						if (key == "Alaska") {
							for (let i = 0; i < value?.universities.length; i++) {
								if (value?.universities?.region_code === undefined) {
									value?.universities?.splice(i, 1);
								}
							}
						}
					});
				} else {
					setUniversities(null);
				}
			}
		);
	};
	const submitEnteredvalues = (values) => {
		let listingUrl = `/listing-studentinfo?`
		if (values) {
			if (values.country) {
				listingUrl += `&country_id=${values.country}`;
			}
			if (values.fromandtoDate) {
				listingUrl = listingUrl;
			}
			if (values.state) {
				listingUrl += `&state_id=${values.state}`;
			}
			if (values.university) {
				if (isNaN(values.university) == true) {
					listingUrl += `&university_id='0'`;
				} else {
					listingUrl += `&university_id=${values.university}`;
				}
			}
			if (values.studentName) {
				setStudentIdentity(values.studentName);
				listingUrl += `&name=${values.studentName}`;
			}
			if (values.role) {
				if (values.role == "4") {
					listingUrl += `&occupation=${null}`;
				} else {
					listingUrl += `&occupation=${values.role}`;
				}
			}
			if (values.fromandtoDate) {
				listingUrl += `&start_date=${changeDateFormat(startdate)}&end_date=${changeDateFormat(enddate)}`;
			}
		}

		setTabledata(listingUrl);
		const isEmpty = Object.values(values).every(x => x === undefined || x === '');
		if (isEmpty) {
			setNoparam(true);
		} else {
			setNoparam(false);
		}

		setFormSubmitted(true);
		setFormValues(values);
	}

	const setTabledata = (url) => {
		fetchAdminApi(url, "get").then((res) => {
			if (res?.data && res?.data.length > 0) {
				setRegisteredStudents(res?.data);
				setTotalStudentcount(res?.data.length);
			} else {
				setRegisteredStudents([]);
				setTotalStudentcount(0);
			}
		});
	}
	const getInfoAndRedirect = (record, index) => {
		let studentDetails = {};
		studentDetails.studentId = record.stud_id;
		sessionStorage.setItem("studentFromRegisteredStudentPage", JSON.stringify(studentDetails));
		window.open(constants.BaseUrl + '/view-student');
	}

	const registeredStudentsTabColumns = [
		{
			title: 'Name',
			key: 'Name',
			render: (text, record, index) => {
				return (
					usertype == 1 ?
						<>
							<span>{record.stud_firstname.charAt(0).toUpperCase() + '.'}</span><span className='ml-2'>{record.stud_lastname.charAt(0).toUpperCase() + '.'}</span></>
						:
						<div className='linkColor font-bold cursor-pointer' onClick={() => getInfoAndRedirect(record, index)}><span>{record.stud_firstname.charAt(0).toUpperCase() + record.stud_firstname.slice(1)}</span><span className='ml-2'>{record.stud_lastname.charAt(0).toUpperCase() + '.'}</span></div>
				)
			},
			align: 'center',
		},
		{
			title: 'Country',
			key: 'Country',
			dataIndex: 'country_code',
		},
		{
			title: 'State',
			key: 'State',
			dataIndex: 'region_code',
		},
		{
			title: 'University',
			key: 'University',
			render: (record) => {
				return (
					record.university_name == '' || record.university_name == ', ' ? record.other_university
						: record.university_name
				)
			}
		},
		{
			title: 'Role',
			key: 'Role',
		//	dataIndex: 'role',
			align: 'center',
			render: (record) => {
				return (
					<span>{record.role
						== 'S' ? <Tooltip title="Student"><span>S</span></Tooltip> : record.role
							== 'P' ? <Tooltip title="Professional"><span>P</span></Tooltip> : record.role
								== 'F' ? <Tooltip title="Faculty"><span>F</span></Tooltip> : record.role
									== '0' ? <Tooltip title="Other"><span>O</span></Tooltip> : ''}</span>
				)
			}
		},
		{
			title: 'Results',
			key: 'Results',
			children: [
				{
					title: 'Tests',
					key: 'Tests',
					align: 'center',
					render: (record) => {
						return record.tests_taken ? record.tests_taken : 0;
					}
				},
				{
					title: 'Tutorials',
					key: 'Tutorials',
					align: 'center',
					width: 25,
					render: (record) => {
						return record.tutorials_taken ? record.tutorials_taken : 0;
					}
				},
				{
					title: 'Flashcards',
					key: 'Flashcards',
					align: 'center',
					width: 25,
					render: (record) => {
						return record.flashcards_taken ? record.flashcards_taken : 0;
					}
				},
			],
		},
		{
			title: 'Registered On',
			key: 'Registered On',
			align: 'center',
			render: (dt_created, record) => {
				return (
					<span>{dayjs(record.dt_created).format('MM/DD/YYYY')}</span>
				)
			}
		},
	]
	const getStartandEnddate = (value, dateString) => {
		setStartdate(dateString[0]);
		setEnddate(dateString[1]);
	}
	const filterOption = (input, option) =>
		(option?.children.toLowerCase().includes(input.toLowerCase()));

	const getStatevalue = (value, option, e) => {
		//setStateid(value);
		setCodes({ ...codes, state: option?.children })
		setStatevalue(value);
		Object.entries(universities).forEach(([key, value]) => {
			if (key === option?.children) {
				let obj = {};
				obj[e] = value;
				setChangeduniversityDetails(obj);
			} else if (value === null) {
				setChangeduniversityDetails(null);
			}
		});
	}
	const getCountryValue = (value, option) => {
		setCodes({ ...codes, country: option?.children })
		if (value == '238') {
			setCountryIsUs(true);
		} else {
			setCountryIsUs(false);
		}
	}
	const getUniversityvalue = (value, option) => {
		setCodes({ ...codes, university: option?.children })
	}
	const getRolevalue = (value, option) => {
		setRoleCode(option?.children);
	}
	useEffect(()=>{
		if(codes){
			if(codes.country){
				setCountrycode(codes.country);
			}
		}

	},[codes])
	const displaySearchCriteria = (values) => {
		let searchCriteriaArr = [];
		if (values) {
			if (values.role) {
				let roleName = "";
				if (values.role === "0") {
					roleName = 'Student'
				} else if (values.role === "1") {
					roleName = 'Professional'
				} else if (values.role === "2") {
					roleName = 'Faculty'
				} else if (values.role === "3") {
					roleName = 'Other'
				} else if (values.role === "4") {
					roleName = 'Unspecified'
				}
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>Role : <span className="pr-2 text-green-700">{roleName}</span></span></>)
			}
			if (values.university) {
				let universtyName = '';
				const selectUni = Object.entries(universities).map(([key, value]) => (
					value &&
					value.universities &&
					value.universities.length > 0 &&
					value.universities.filter((universe) => (universe.university_id == values.university))
				)
				)
				if (selectUni.length > 0) {
					const selectedUniversity = selectUni.filter((obj) => (obj[0]))[0];
					universtyName = selectedUniversity[0].university_name;
				}
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>University : <span className="pr-2 text-green-700">{universtyName}</span></span></>)
			}
			if (values.country) {
				let countryName = "";
				countries.map((country) => {
					if (country?.country_id === values.country) {
						countryName = country.country_name;
					}
				});
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>Country : <span className="pr-2 text-green-700">{countryName}</span></span></>)
			}
			if (values.state) {
				let regionName = "";
				states.map((state) => {
					if (state?.region_id === values.state) {
						regionName = state.region_name;
					}
				});
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>State : <span className="pr-2 text-green-700">{regionName}</span></span></>)
			}
			if (values.studentName) {
				searchCriteriaArr.push(<><span className='text-red-700 pl-2'>Name : <span className="pr-2 text-green-700">{values.studentName}</span></span></>)
			}
				if (values.fromandtoDate) {
					searchCriteriaArr.push(<><span className='text-red-700 pl-2'>Period : <span className="pr-2 text-green-700">{`${startdate} - ${enddate}`}</span></span></>)
				}
			return searchCriteriaArr;
		}
	};
	const getStudentname = (e) => {
		setStudentname(e.target.value);
		if (e.target.value != '') {
			setStudentnamepresent(true);
		} else {
			setStudentnamepresent(false);
		}
	}
	const resetFormFields = () => {
		form.resetFields();
	}

	return (
		<div className="cursorChange">
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card title="REGISTERED STUDENTS" hoverable className="addQcard" extra={
							<FloatButton
								type="primary"
								className='float-searchBtn'
								onClick={setToggle}
								icon={<SearchOutlined />}
								size="small"
								tooltip={<div>Click to search registered students using various parameters</div>}
								style={{
									top:180,
									right: 50,
								}}
							> Toggle State
							</FloatButton>
						}
						>
							{toggle && (
								<Card className='mb-4 neomorphicShadow' hoverable>
									<Form form={form} className='filterForm countriesByregistrationFilters' onFinish={submitEnteredvalues} layout={'vertical'}>
										<Row className='mt-4' gutter={24}>
											<Col span={8}>
												<Form.Item label="Country" name="country" rules={[
													{
														required: false,
														message: '',
													},
												]}
													className="mr-2"
												>
													<Select className='rounded-md' showSearch filterOption={filterOption} onChange={getCountryValue} placeholder="Country">
														{countries.map((country, index) => (
															<Select.Option key={country.country_code} value={country.country_id}>
																{country.country_name}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											{
												countryIsUs
													?
													<>
														<Col span={8}>
															<Form.Item label="State" name="state" className="mr-2">
																<Select className='rounded-md mr-2' placeholder="State" onChange={getStatevalue}>
																	{states.map((state, index) => (
																		<Select.Option key={state.region_code} value={state.region_id}>
																			{state.region_name}
																		</Select.Option>
																	))}
																</Select>
															</Form.Item>
														</Col>
														<Col span={8}>
															<Form.Item label="University" name="university">
																<Select className='rounded-md' placeholder="University" onChange={getUniversityvalue}>
																	{changeduniversitydetails
																		?
																		Object.entries(changeduniversitydetails).map(([key, value]) => (
																			<>
																				{value &&
																					value.universities &&
																					value.universities.length > 0 &&
																					value.universities.map((universe) => (
																						<Option
																							key={universe.university_id}
																							value={universe.university_id}
																							universityN={universe.university_name}
																							region={universe.region_id}
																						>
																							{universe.university_name + (universe.city ? "," + universe.city : "")}
																						</Option>
																					))}
																			</>
																		))
																		:
																		universities &&
																		Object.entries(universities).map(([key, value]) => (
																			<>
																				{value &&
																					value.universities &&
																					value.universities.length > 0 &&
																					value.universities.map((universe) => (
																						<Option
																							key={universe.university_id}
																							value={universe.university_id}
																							universityN={universe.university_name}
																							region={universe.region_id}
																						>
																							{universe.university_name + (universe.city ? "," + universe.city : "")}
																						</Option>
																					))}
																			</>
																		))
																	}
																</Select>
															</Form.Item>
														</Col>
													</>
													:
													<div></div>
											}
										</Row>
										<Row className='mt-2' gutter={24}>
											<Col span={8}>
												<Form.Item label="Role" className="mr-2" name="role">
													<Select placeholder="Role" className='rounded-md' onChange={getRolevalue}>
														{rolesArr.map((role) => (
															<Select.Option key={role.status} value={role.value}>
																{role.status}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item label="Name" className="mr-2" name='studentName'>
													<Input className='rounded-md' placeholder="Name" value={studentIdentity} onChange={getStudentname}></Input>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item label="Period" name="fromandtoDate" rules={[
													{
														required: false,
														message: '',
													},
												]}>
													<RangePicker
														className='w-full'
														// defaultValue={location.state ? [dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]:[null,null]}
														//value={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
														format={dateFormat}
														//value={dateArr}
														onChange={getStartandEnddate}
														changeOnBlur
														allowClear={false}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Form.Item className='relative'>
													<div style={{ position: 'absolute', right: '0px' }}>
														<Button htmlType='submit' type='primary' className='font-semibold text-sm'>SEARCH</Button>
														<Button type='primary' className='font-semibold text-sm ml-3' onClick={resetFormFields}>RESET</Button>
													</div>
												</Form.Item>
											</Col>
										</Row>
									</Form>
								</Card>
							)}
							{
								noparam ?
									<span className="font-bold ml-2">SEARCH CRITERIA : </span>
									:
									<Card className="criteriaCard mb-3" hoverable>
										<div className="font-bold text-base">
											<span className="font-bold ml-2">SEARCH CRITERIA - </span>
											{(formSubmitted || formdisplay) && formValues
												?
												displaySearchCriteria(formValues)
												:
												''
											}
										</div>
									</Card>
							}
							<div className="text-base font-bold mb-3 ml-3">{totalStudentCount} record(s) found.</div>
							<Table
								bordered
								columns={registeredStudentsTabColumns}
								dataSource={registeredstudents}
								pagination={pagination}
								className='registeredStudsTable'
								locale={{ emptyText: constants.emptyTableText }}
								scroll={{ x: 768 }}
							></Table>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default RegisteredStudents
