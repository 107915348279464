import { Select, Input, Form, Space, Button, Divider, Card, Image, Spin, Tooltip, Skeleton } from 'antd';
import { ZoomOutOutlined, ZoomInOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchApi } from '../../../services/api';
import { getColorClass } from '../../../functions/functions';
import { COOKIE, getCookie } from '../../../services/cookie';
import constants from '../../../constants/constants';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';
const { Option } = Select;
const { Search } = Input;
const { Meta } = Card;
const ImageResources = () => {
	const [form] = Form.useForm();
	const location = useLocation();
	const navigate = useNavigate();
	const userId = getCookie(COOKIE.UserId);
	const [chapterListing, setChapterListing] = useState([]);
	const [chapterId, setChapterId] = useState(sessionStorage.getItem("chapterId") ? sessionStorage.getItem("chapterId") : location?.state?.chapter_id);
	const [previewImg, setPreviewImg] = useState([]);
	const [current, setCurrent] = useState(0);
	const [visibleImage, setVisibleImage] = useState(false);
	const [loader, setLoader] = useState(false);
	const [filterImage, setFilterImage] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [imageData, setimageData] = useState([]);
	const [imageSearchedList, setImageSearchedList] = useState([]);
	const getChapterListing = () => {
		fetchApi(`/list-chapters`, 'get').then((res) => {
			if (res?.code === 200) {
				let chapters = res?.data
				// adding all chapter obj
				let newObj = {
					chapter: "All Chapters",
					chapter_id: "all"
				}
				chapters.unshift(newObj);
				setChapterListing(chapters);
				form.setFieldsValue({ chapter: chapterId });
			} else {
				setChapterListing([]);
			}
		})
	};
	const getImageList = () => {
		setLoader(true);
		let url;
		if (chapterId === "all") {
			url = "/dashboard/get-image";
		} else {
			url = `/dashboard/get-image?chapter_id=${chapterId}`;
		}
		fetchApi(url, 'get').then((res) => {
			if (res?.code === 200) {
				setFilterImage(res?.data);
				setimageData(res?.data);
				let getfilteredimageids = filterSearchedImageIds(res?.data);
				setImageSearchedList(getfilteredimageids);
				setSearchValue("");
				setLoader(false);
			} else {
				setimageData([]);
				setFilterImage([]);
				setLoader(false);
			}
		})
	};
	const filterSearchedImageIds = (data) => {
		let tempimagelist = [];
		for (let index = 0; index < data?.length; index++) {
			const element = data?.[index];
			for (let index2 = 0; index2 < element?.imageList?.length; index2++) {
				const element2 = element?.imageList?.[index2];
				element2.chapter_id = element?.chapter_id
				tempimagelist.push(element2);
			}
		}
		return tempimagelist;
	};
	const onChangeChapters = (value) => {
		setSearchValue("");
		setimageData([]);
		setChapterId(value);
		sessionStorage.setItem("chapterId", value);
	}
	const previwImage = (chapter_id,resourse_id) => {
		let findimageindex = imageSearchedList.findIndex((val)=>val.resource_file_id === resourse_id );
		setCurrent(findimageindex);
		setVisibleImage(false);
		setLoader(true);
		setPreviewImg([]);
		fetchApi(`/preview-image/${resourse_id}/${userId}/${chapter_id}`, 'get').then((res) => {
			if (res?.code === 200) {
				setVisibleImage(true);
				setPreviewImg(res?.data?.[0]);
				setLoader(false);
			} else {
				setVisibleImage(true);
				setPreviewImg([]);
				setLoader(false);
			}
		})
	};
	const disableContextMenu = (e) => {
		e.preventDefault();
	};
	const backToChapter = (chapter_id) => {
		const state = {
			chapter_id: chapter_id
		};
		navigate('/chapter-resources', { state });
	};
	const onchangeImagePreview = (current, prev) => {
		setCurrent(current);
		setLoader(true);
		let resource_file_id = imageSearchedList[current]?.resource_file_id;
		fetchApi(`/preview-image/${resource_file_id}/${userId}/${imageSearchedList[current]?.chapter_id}`, 'get').then((res) => {
			if (res?.code === 200) {
				setLoader(false);
				setPreviewImg(res?.data?.[0]);
			} else {
				setLoader(false);
				setPreviewImg([]);
			}
		});
	};
	const filterDataByCaptionAndDetails = (searchTerm) => {
		const filteredAndMappedData = filterImage.map((chapterimages) => {
			// Filter the items based on some condition
			const filteredItems = chapterimages.imageList.filter((item) =>  item?.caption?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
			item?.details?.toLowerCase()?.includes(searchTerm?.toLowerCase()));
			// Map the filtered items to modify or transform them
			const mappedItems = filteredItems.map((item) => ({
				...item,
			}));
			// Return the group with filtered and mapped items
			return {
				...chapterimages,
				imageList: mappedItems,
			};
		}).filter((group) => group.imageList.length > 0);
		setimageData(filteredAndMappedData);
		let getfilteredimageids = filterSearchedImageIds(filteredAndMappedData);
		setImageSearchedList(getfilteredimageids);
	};
	const previewClose = () => {
		filterDataByCaptionAndDetails(searchValue);
		setVisibleImage(!visibleImage);
	};
	const onChangeSearch =(e)=>{
		setSearchValue(e.target.value);
	}
	const n = 10;
	useEffect(() => {
		getChapterListing();
		getImageList();
		document.addEventListener('contextmenu', disableContextMenu);
	}, [chapterId]);
	useEffect(() => {
		return () => {
			// Remove the session here
			sessionStorage.removeItem('chapterId');
		};
	}, []);
	return (
		<Spin spinning={loader} size="large" className='z-30'>
			<div className='container mx-auto'>
				<div className='flex flex-wrap justify-between items-center'>
					<div className='text-2xl font-extrabold uppercase'>Image resources</div>
					<Form layout="inline" form={form}>
						<Form.Item name={"chapter"} className='!mb-3 lg:mb-0'>
							<Select onChange={onChangeChapters} className='!w-80 lg:!w-[450px]'>
								{chapterListing.length > 0 ? chapterListing?.map((listChapter) => (
									<Option key={listChapter.chapter_id} value={listChapter.chapter_id}>
										{listChapter.chapter}
									</Option>
								)) : null}
							</Select>
						</Form.Item>
						<Form.Item>
							<Search allowClear placeholder="Search" value={searchValue} onSearch={(e)=>filterDataByCaptionAndDetails(e)} onChange={onChangeSearch}/>
						</Form.Item>
					</Form>
				</div>
				<Divider className='my-2'/>
				<>
					<Image.PreviewGroup
						preview={{
							closeIcon: <CloseCircleOutlined className='hidden' />,
							visible: visibleImage,
							current:current,
							src: previewImg?.resource_name,
							onVisibleChange: (value) => {
								if (value === false) {
									setVisibleImage(false);
									previewClose();
								}
							},
							onChange: (current, prev) => onchangeImagePreview(current, prev),
							toolbarRender: (
								_,
								{ transform: { scale }, actions: { onZoomOut, onZoomIn } }
							) => (
								<div className="w-full lg:w-3/5 toolbar-wrapper text-base flex items-center justify-between fixed top-5 translate-x-[-50%] left-1/2 px-6 text-black rounded-full bg-white">
									<div className='my-1.5'>
										<div>{previewImg?.caption}</div>
										{previewImg?.details && (<div>{previewImg?.details}</div>)}
									</div>
									<Space size={12} className='my-2'>
										<ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
										<ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
										<CloseCircleOutlined className='text-red-600' onClick={previewClose} />
									</Space>
								</div>
							)
						}}
					>
						{imageData.length > 0 ?
							imageData.map((item, index) => {
								return (
									<div style={{ borderColor: getColorClass(item?.chapter_id, "colorcode") }} className='border p-2 mt-3' key={index} >
										<div className='flex flex-wrap justify-between items-center mb-2 md:mb-0'>
											<div style={{ color: getColorClass(item?.chapter_id, "colorcode") }} className='text-base lg:text-2xl underline underline-offset-4 font-extrabold uppercase w-full md:w-3/5 lg:w-3/4'>{item?.chapter_name}</div>
											<div className='mb-0 md:mb-2 !gap-0 md:!gap-6 flex justify-between md:justify-end items-center w-full md:w-2/5 lg:w-1/4'>
												<Button className='bg-[#1E40AF] text-white' onClick={() => backToChapter(item?.chapter_id)}>{"Go to Chapter >>"}</Button>
												<div
													style={{ backgroundColor: getColorClass(item?.chapter_id, "colorcode") }}
													className='text-white roboto-slab font-bold text-2xl md:text-4xl w-12 lg:w-[66px] h-10 md:h-16 p-2 flex justify-center items-center relative bottom-0 md:bottom-2 left-2'
												>
													{item?.chapter_id}
												</div>
											</div>
										</div>
										<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3'>
											{
												item?.imageList?.map((image_data, index) => {
													return <Card
														id={image_data?.resource_file_id}
														key={index}
														className='border-black border rounded-none p-1 grid place-content-center items-center overflow-hidden'
														cover={<Image
															onClick={() => previwImage(item?.chapter_id,image_data?.resource_file_id)}
															alt={image_data?.caption}
															src={image_data?.resource_name} />}
													>
														<Tooltip title={image_data?.caption}><Meta className="bg-gray-300 p-1 absolute bottom-1 left-0 w-full" title={image_data?.caption} /></Tooltip>
													</Card>
												})}
										</div>
									</div>
								);
							}) :
							loader ?
								<>
									<Skeleton.Input active size={'large'} />
									<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 mt-3'>
										{[...Array(n)].map((e, i) => <Skeleton.Image key={i} active size={'large'} className='!w-full !h-64' />)}
									</div>
								</> :
								<div className='text-black font-semibold text-center text-2xl'>No Images to display</div>}
					</Image.PreviewGroup>
				</>
			</div>
		</Spin>
	)
}
export default ImageResources;
