import React, { useEffect } from "react";
import { Button, Divider, Card, Space, Steps } from 'antd';
import { ArrowLeftOutlined, CheckCircleOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons';
import constants from "../../../constants/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { getColorClass } from "../../../functions/functions";

const FlashcardInstructions = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
  }, []);
  //Redirect to flashcard test
	const redirectToFlashcardTYS = () =>{
		let state={
			chapter_id:location?.state?.chapter_id,
			chapter_name:location?.state?.chapter_name,
			start_with :location?.state?.start_with
		}
		navigate('/flashcard-test',{state});
	};
  return (
    <div >
      <div
        style={{ backgroundColor: getColorClass(location?.state?.chapter_id, "colorcode") }}
        className='text-white w-12 lg:w-36 roboto-slab font-bold text-4xl flex justify-center items-end pb-3.5 absolute right-0 top-0 h-36'
      >
        {location?.state?.chapter_id}
      </div>
      <div className='container mx-auto'>
        <div className='flex justify-between items-center'>
          <div style={{ color: getColorClass(location?.state?.chapter_id, "colorcode") }} className='text-base lg:text-2xl underline underline-offset-4 font-extrabold uppercase w-4/5 lg:w-auto'>{location?.state?.chapter_name}</div>
        </div>
        <Divider className="my-1" />
        <div className={`flex justify-between items-center my-3 px-5`}>
          <div className='font-bold text-xl'>{constants.Instructions}</div>
          <div className="w-1/2">
          </div>
        </div>
        <div>
          <Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
            <div className="text-base">
              <p>This section is designed to assist you in assessing your progress.</p>
              <p className="mt-5">You will encounter a series of 10 Flashcards.</p>
              <p className="mt-5">Here’s how it works:</p>
              <p className="mt-5">A flashcard question will be presented.</p>
              <ul className="list-disc list-outside ml-4">
                <li>Take a moment to think of your answer before revealing the answer by flipping the card.</li>
                <li>Once flipped, you can verify whether your answer was correct.</li>
              </ul>
              <p className="mt-5">Here’s how to track your performance:</p>
              <ul className="list-disc list-outside ml-4">
                <li>If you believe your answer is correct, click on <Button
                  icon={<CheckCircleOutlined />}
                  className='rounded-lg bg-green-700 text-white'
                  size="small"
                >
                  {constants.Correct}
                </Button></li>
                <li>If you believe you answer is incorrect, select <Button
                  icon={<CloseOutlined />}
                  className='rounded-lg bg-red-700 text-white'
                  size="small"
                >
                  {constants.Incorrect}
                </Button> </li>
                <li>Your test score will be updated and displayed in the bottom right corner.</li>
                <li>Upon completion, your overall score will be presented on the Results page.</li>
              </ul>
              <div className="mt-5">Ready to get started? </div>
              <div className="mt-5">
                <Space wrap>
                  <Button className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" icon={<CopyOutlined className='rotate-90' />} onClick={() => redirectToFlashcardTYS()}>Start Flashcards</Button>
                  <Button onClick={() => {
                    const state = {
                      chapter_id: location?.state?.chapter_id
                    };
                    navigate('/chapter-resources', { state, replace: true });
                  }}
                    className='rounded-lg bg-blue-700 text-white'
                    icon={<ArrowLeftOutlined />}>{constants.BackToChapter}</Button>
                </Space>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default FlashcardInstructions;
