import React, { useEffect } from "react";
import { Button, Divider, Card, Space, Steps } from 'antd';
import { ArrowLeftOutlined, FileDoneOutlined } from '@ant-design/icons';
import constants from "../../../constants/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { getColorClass } from "../../../functions/functions";

const TutorialInstructions = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
  }, []);
  //Redirect to start test
  const redirectToStartTutorial = (attempt, isMFL = false, isReviewIncorrect = false) => {
    let state = {
      chapter_id: location?.state?.chapter_id,
      attempt: location?.state?.attempt,
      chapter_name: location?.state?.chapter_name
    }
    if (location?.state?.is_marked_later === true) {
      state.is_marked_later = true
    }
    if (location?.state?.is_ans_incorrect === true) {
      state.is_ans_incorrect = true
    }
    navigate('/start-tutorial', { state, replace: true });
  }
  return (
    <div >
      <div
        style={{ backgroundColor: getColorClass(location?.state?.chapter_id, "colorcode") }}
        className='text-white w-12 lg:w-36 roboto-slab font-bold text-4xl flex justify-center items-end pb-3.5 absolute right-0 top-0 h-36'
      >
        {location?.state?.chapter_id}
      </div>
      <div className='container mx-auto'>
        <div className='flex justify-between items-center'>
          <div style={{ color: getColorClass(location?.state?.chapter_id, "colorcode") }} className='text-base lg:text-2xl w-4/5 lg:w-auto underline underline-offset-4 font-extrabold uppercase'>{location?.state?.chapter_name}</div>
        </div>
        <Divider className="my-1" />
        <div className={`flex flex-wrap justify-between items-center my-3 px-5`}>
          <div className='font-bold text-xl'>{constants.Instructions}</div>
          <div className="w-1/2">
            <Steps
              current={0}
              items={[
                {
                  title: 'Instructions',
                },
                {
                  title: 'Take a tutorial',
                },
                {
                  title: 'Result',
                },
              ]}
            />
          </div>
        </div>
        <div>
          <Card bodyStyle={{ backgroundColor: '#FAFAFA' }}>
            <div className="text-base">
              <div className="underline font-medium text-xl">About the Tutorial</div>
              <p>This Tutorial has a total of 10 questions.</p>
              <ul className="list-disc list-outside ml-4">
                <li>For each question, you can choose what you believe is the correct option.</li>
                <li>To validate your answer, simply click <Button size="small" className="bg-green-700 hover:bg-green-600 !text-white rounded-lg">Check</Button></li>
                <li>If your answer happens to be incorrect, the correct answer and an explanation will be presented for your reference.</li>
              </ul>
              <div className="mt-5">Ready to begin? </div>
              <div className="mt-5">
                <Space wrap>
                  <Button className="bg-green-700 hover:bg-green-600 !text-white rounded-lg" icon={<FileDoneOutlined />} onClick={() => redirectToStartTutorial()}>{constants.startTutorial}</Button>
                  <Button onClick={() => {
                    const state = {
                      chapter_id: location?.state?.chapter_id
                    };
                    navigate('/chapter-resources', { state, replace: true });
                  }}
                    className='rounded-lg bg-blue-700 text-white'
                    icon={<ArrowLeftOutlined />}	>{constants.BackToChapter}</Button>
                </Space>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default TutorialInstructions;
