import { Layout } from "antd";
import constants from "./constants/constants";
const { Footer } = Layout;
const Footers = () => {
  return (
    <div className="">
      <Footer className="!bg-slate-900 text-white text-center !py-2">
        {constants.footerText}
      </Footer>
    </div>
  );
};
export default Footers;
