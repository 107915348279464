import React from "react";
import { Layout } from "antd";
import { Outlet } from 'react-router-dom';
import Headers from "./StudentHeader";
import Footers from "../../../Footers"
const { Content, Footer } = Layout;
const Layouts = () => {
	return (
		<div>
			<Layout className="!bg-white">
				<Headers />
				<Content className="min-h-[82vh] py-6 px-2 lg:px-0">
					<Outlet />
				</Content>
				<Footers />
			</Layout>
		</div>
	);
};
export default Layouts;
