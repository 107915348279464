import React, { useState, useEffect } from 'react';
import constants from '../../../../constants/constants';
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Table,
	Form,
	DatePicker,
	Select,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { getCustomisedBreadcrumbs, getStartDateAndEndDate } from '../../../../functions/functions';
import { fetchAdminApi } from '../../../../services/api';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const TotalMediaResourcesTaken = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const newCrumb = [<Link key={1} to="/total-video-views" className="!text-black font-semibold">{constants.TotalVideoViews}</Link>];
	const [tableData, setTableData] = useState([]);
	const [startDate, setStartDate] = useState(dayjs().startOf('year'));
	const [endDate, setEndDate] = useState(dayjs().endOf('year'));
	const [period, setPeriod] = useState(0);
	const [category, setCategory] = useState(null);
	const columns = [
		
		{
			title: <span className='ml-2'>{constants.Video}</span>,
			key: 'video',
			dataIndex: 'title',
			ellipse: true,
			width: "50%",
			render: (title) => {
				return (
					<span className='ml-2'>{title}</span>
				)
			},
		},
		{
			title: <span className='ml-2'>{constants.Category}</span>,
			key: "category",
			dataIndex: "category",
			width: "40%",
			render: (category) => {
				return (
					<span className='ml-2'>{category}</span>
				)
			},
		},
		{
			title: <span className='pr-4'>Views</span>,
			key: "views",
			dataIndex: "views",
			width: "10%",
			align:"right",
			render: (views, record) => <div className='linkColor font-semibold text-sm cursor-pointer text-right pr-4' onClick={() => redirectToResouseResult(record)}>
				{views}
			</div>
		},
	];

	const redirectToResouseResult = (recordData) => {
		sessionStorage.setItem("selectedSection", 2);
		navigate('/video-result', { state: { videowiseObject: recordData } });
	};

	const getTotalVideoViews = (start_date,end_date) => {
		let url = `/get-resource-list/2?start_date=${dayjs(start_date).format("YYYY-MM-DD")}&end_date=${dayjs(end_date).format("YYYY-MM-DD")}`;
		if(category){
			url += `&category=${category}`
		}
		fetchAdminApi(url, 'get').then((res) => {
			if (res?.code === 200) {
				setTableData(res.data);
			} else {
				setTableData([]);
			}
		})
	};

	const onChangeFromAndTo = (date) => {
		setPeriod(null);
		setStartDate(date[0]);
		setEndDate(date[1]);
	};

	const onChangePeiod = (value) => {
		setPeriod(value);
		let sted = getStartDateAndEndDate(value);
		setStartDate(sted?.startDate);
		setEndDate(sted?.endDate);
	};

	const onChangeVideoCategory = (category) => {
		setCategory(category);
	};

	useEffect(() => {
		getTotalVideoViews(startDate,endDate);
	}, [startDate,endDate,category]);

	return (
		<div>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card title={constants.TotalVideoViews} className="addQcard" hoverable>
							<div>
								<Form form={form} className='countriesByregistrationFilters'>
									<Row gutter={12}>
										<Col span={8}>
											<Form.Item label="From">
												<RangePicker
													className='w-full'
													format={constants.DateFormat}
													onChange={onChangeFromAndTo}
													value={[startDate , endDate]}
													allowClear={false}
												/>
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item label={constants.Period}>
												<Select
													placeholder={constants.Period}
													className='w-full rounded-md'
													value={period}
													onChange={onChangePeiod}
												>
													{constants.periodOptions.map((option, index) => (
														<Select.Option key={option} value={index}>
															{option}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item label={constants.Category}>
												<Select
													placeholder={constants.Category}
													className='w-full rounded-md'
													onChange={onChangeVideoCategory}
													allowClear
													value={category}
												>
													{constants.VideoCategoryList.map((option) => (
														<Select.Option key={option.id} value={option.id}>
															{option.title}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Col>
									</Row>
								</Form>
							</div>
							<Row>
								<Col span={24}>
									<Table
										bordered
										dataSource={tableData}
										columns={columns}
										className='registrationTable'
										title={() => { return <div className="font-bold text-base text-white uppercase">{constants.VideoViews}</div> }}
										size="small"
										locale={{ emptyText: constants.emptyTableText }}
										pagination={false}
									/>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default TotalMediaResourcesTaken