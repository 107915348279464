import React, { useState,useEffect } from 'react';
import {useLocation,useNavigate,Link} from 'react-router-dom';
import {
		Row,
		Col,
		Breadcrumb,
		Button,
		Card,
	  Form,
		message,
	  Input,
	  Upload
} from 'antd';
import {getCustomisedBreadcrumbs,success} from '../../../functions/functions';
import {PlusOutlined} from '@ant-design/icons';
import constants from '../../../constants/constants';
import { COOKIE,getCookie} from '../../../../src/services/cookie/index';

const {Dragger} = Upload;

function AddImage() {
	const newCrumb = [<Link key={0} to="/image-list" className="font-semibold"> Images </Link>, <Link key={0} to='/add-image' className="font-semibold !text-black"> Add Image </Link>]
	const navigate = useNavigate();
	const location = useLocation();
	const [form] = Form.useForm();
	const [messageApi, contextHolder] = message.useMessage();
	const [captiontext,setCaptiontext] = useState('');
	const [imageinfo,setImageinfo] = useState([]);
	const [chaptername,setChaptername] = useState('');
	const {data} = location.state || {};
	const [chapId,setChapid] = useState('');
	const [details,setDetails] = useState('');
	const [chapters,setChapters] = useState([]);

	useEffect(() => {
		let userId = getCookie(COOKIE.UserId);
		if (!userId) {
			navigate('/admin-login');
		}
	}, []);

	 const submitImageinfo = async (values)=>{
			try {
				const formData = new FormData();
				imageinfo.forEach((file) => {
					formData.append('file', file.originFileObj);
				});
				formData.append('caption',values.caption);
				formData.append('updated_by', 1);
				formData.append('resource_type', 1);
				formData.append('chapter_id',chapId);
				formData.append('details', details);

				const response = await fetch(`${constants.AdminApiUrl}/upload-image`, {
					method: 'POST',
					body: formData,
					headers: {
						Authorization: `Bearer ${getCookie(COOKIE.Token)}`
					},
				});
				const data = await response.json();
				if(data.code === 200){
						const imageUploadedstatus = true;
						localStorage.setItem('imageUploadedsuccess', imageUploadedstatus);
						localStorage.setItem('imageChapterId', chapId);
				    navigate(-1);
				}
						localStorage.setItem('imageUpdationStatus', data.code);
			} catch (error) {}
	 }

	const getDetails = (e) => {
		setDetails(e.target.value);
	}
	
	 const getCaptionText = (e)=>{
			setCaptiontext(e.target.value);
	 }
	 const getTmageFile = (e) => {
	 	setImageinfo(e ?.fileList)
	 	if (Array.isArray(e)) {
	 		return e;
	 	}
	 	return e ?.fileList;
	 }
	 const redirectBack = () => {
			const imagenotAdded = true;
			localStorage.setItem('imageNotAdded', imagenotAdded);
			localStorage.setItem('imagechapterid', chapId);
			navigate(-1)
	 }
	 useEffect(()=>{
		 if (data && data != '') {
			 const filteredChapter = data[0].allChapters.filter((each) => (each.value == data[0].chaptervalue));
			 setChaptername(filteredChapter[0].label);
			 setChapid(data[0].chaptervalue);
			 setChapters(data[0].allChapters);
		 } else {
			 setChapters([]);
		 }
	 },[])
	 
	return (
			<div className="cursorChange">
					<Row>
							<Col xs={24} sm={24} md={24} lg={24}>
		 							<div className = 'p-4 min-h-screen bg-gray-100'>
										<div>
												<Breadcrumb items={getCustomisedBreadcrumbs(constants.flashCardpageCrumbs,newCrumb)} className='bg-neutral-100 shadow-lg p-3 mb-3'/>
										</div>
										<Card 
										title = "ADD IMAGE"
										className='mainflashCard' 
										extra={<div className="font-semibold chapterInfo commonColor">{chaptername}</div>}
										hoverable>
												<Form 
												form={form}
												onFinish={submitImageinfo}
												onFinishFailed = {
													() => {
														window.scrollTo(0, 0);
														success('error', constants.image_added_failure, 'flashSuccess', messageApi)
													}
												}
												>
														<Card hoverable>
															<Form.Item 
															label = 
															{
															<span
															className = "font-semibold text-base caption-item pr-2 imagecardTitle">Caption</span>
															} 
															className='caption-form-item'
															name="caption"
															rules = {
																[{
																		required: true,
																		message: constants.mandatoryField
																	},
																	{
																		validator: (_, value) =>
																			value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
																	},
																]
															}
															>
																<Input type='text' style={{width:'70%'}} value={captiontext} onChange={getCaptionText}></Input>
															</Form.Item>
														</Card>
								<Card className='mt-5' hoverable>
									<Form.Item
										label=
										{
											<span
												className="font-semibold text-base caption-item pr-2 imagecardTitle">Details</span>
										}
										className='caption-form-item'
										name="details"
										rules={
											[{
												required: false,
												message: ''
											},
											{
												validator: (_, value) =>
													value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error('')),
											},
											]
										}
									>
										<Input type='text' style={{ width: '70%' }} value={details} onChange={getDetails}></Input>
									</Form.Item>
								</Card>
														<Card className='mt-5' hoverable>
															<Form.Item 
															valuePropName="fileList"
															getValueFromEvent = {getTmageFile}
															action = ""
															label = {<span 
																			className = "font-semibold text-base image-item pr-3 imagecardTitle">Image</span>
															} 
															name="image" 
															className="image-form-item"
															rules = {
																[{
																		required: true,
																		message: constants.mandatoryField
																	},
																]
															}
															>
														<Dragger 
														name ="files"
														action = ""
														listType = "picture"
														maxCount = {1}
														accept = ".png,.jpeg,.jpg"
														beforeUpload = {
														(file) => {
															const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
															if (!isJpgOrPng){
																message.error(constants.imageTypeSelection)
																success('error', 'Error!Only allowed extensions are jpg and png...', 'imageuploadError', messageApi);
															}
														}
														}
												>
															<p className = "ant-upload-drag-icon"><PlusOutlined/></p> 
															<p className="ant-upload-text"> {constants.chooseImage} </p>
														</Dragger> 
															</Form.Item>
														</Card>
															<Button htmlType='submit' type="primary" className='mt-5 font-semibold'>Submit</Button>
															{contextHolder}
															<Button type="primary" className='mt-5 ml-3 font-semibold' onClick={redirectBack}>Cancel</Button>
												</Form>
										</Card>
									</div>
							</Col>
					</Row>
			</div>
	)
}

export default AddImage
