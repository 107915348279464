import React, { useState, useEffect } from 'react';
import {
	Row,
	Col,
	Button,
	Breadcrumb,
	Form,
	Card,
	Radio,
	Upload,
	Space,
	message,
	InputNumber,
	Input
} from 'antd';
import { success, getCustomisedBreadcrumbs } from '../../../functions/functions';
import { PlusOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import constants from '../../../constants/constants';
import { fetchAdminApi } from '../../../services/api';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { COOKIE, getCookie } from '../../../../src/services/cookie/index';

function AddQuestion() {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [messageApi, contextHolder] = message.useMessage();
	const { Dragger } = Upload;
	const location = useLocation() || {};
	const { data } = location.state || {};
	let newCrumb = [<Link key={0} to="/addQuestion" className='font-semibold !text-black'> Add Question </Link>]
	const [question, setQuestion] = useState('');
	const [explaination, setExplaination] = useState('');
	const [correctOption, setcorrectOption] = useState();
	const [correctopsym, setCorrectopsym] = useState('f');
	const [chapter, setChapter] = useState('');
	const [qtype, setQtype] = useState('');
	const [newquestionid, setNewQuestionid] = useState('');
	const [imagedata, setImageData] = useState([]);
	const [status, setStatus] = useState(false);
	const [imageoptionCount, setimageoptionCount] = useState(1);
	const [tempArr, settempArr] = useState([]);
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState(1);
	const [labels, setLabels] = useState([{other_opt:'' }]);
	const [points,setPoints] = useState();
  const [chapterid,setChapterid] = useState(data[0].id);
	const [qid, setQid] = useState(data[0].qType);
	const [stepsMap, setStepsMap] = useState(
		[{
			label: "A",
			optionValue: 1,
			option_text: '',
			corr_opt: 'f',
			other_opt:'',
		},
		{
			label: "B",
			optionValue: 2,
			option_text: '',
			corr_opt: 'f',
			other_opt: '',
		},
		{
			label: "C",
			optionValue: 3,
			option_text: '',
			corr_opt: 'f',
			other_opt: '',
		},
		{
			label: "D",
			optionValue: 4,
			option_text: '',
			corr_opt: 'f',
			other_opt: '',
		}
		]
	)
	const goBack = () => {
		const cancelClickevent = true;
		localStorage.setItem('cancelClickAdd', cancelClickevent);
		localStorage.setItem('chapter_id', data[0].id);
		localStorage.setItem('question_type', data[0].qType);
		navigate(-1);
	}
	useEffect(() => {
		let userId = getCookie(COOKIE.UserId);
		if (!userId) {
			navigate('/admin-login');
		}

	}, []);
	const updateQuillValues = (value, i) => {
		let obj = { option_text: value, optionValue: i,other_opt:''}
		if (tempArr.length > 0) {
			let found = false;
			for (let i = 0; i < tempArr.length; i++) {
				if (tempArr[i].optionValue === obj.optionValue) {
					tempArr[i].option_text = value;
					tempArr[i].other_opt = '';
					found = true;
					break;
				}
			}
			if (found === false) {
				tempArr.push(obj);
			}
		} else {
			tempArr.push(obj);
		}
	}

	const onChangePoints = (value)=>{
		setPoints(value);
	}

	const onChangeoptionNumbers = (value) => {
		setimageoptionCount(value);
	}
	const setRadiovalue = (correctValue) => {
		stepsMap.map((obj, i) => {
			if (obj.optionValue === correctValue) {
				obj.corr_opt = "t"
			}
			else if (obj.optionValue !== correctValue) {
				obj.corr_opt = "f"
			}
		});
		setStepsMap(stepsMap);
	}

	function selectFewerPropsforSingleselect(show) {
		const {
			option_text,
			corr_opt
		} = show;
		return {
			option_text,
			corr_opt
		}
	}

	const answerDataSingleselect = stepsMap.map(selectFewerPropsforSingleselect);
	

	const handleUpload = async () => {
		try {
			const formData = new FormData();
			imagedata.forEach((file) => {
				formData.append('image', file.originFileObj);
			});
			const response = await fetch(`${constants.AdminApiUrl}/upload-question-image/${newquestionid}`, {
				method: 'POST',
				body: formData,
				headers: {
					Authorization: `Bearer ${getCookie(COOKIE.Token)}`
				},
			});
			const data = await response.json();;
			localStorage.setItem('imageUpdationStatus', data.code);
			if (data.code === 200) {
				const testQuote = 'newQuestion';
				localStorage.setItem('newTestQ', testQuote);
				//navigate(-1);
				navigate(`/get-chapter-questions/${chapterid}`, { state: { chapterID: chapterid, qID: qid }});
			}
		} catch (error) { }
	}
	const quillQuestionvalue = (value) => {
		setQuestion(value);
	}
	const getExplaination = (value) => {
		setExplaination(value);
	}
	const normFile = (e) => {
		setImageData(e?.fileList);
		if (Array.isArray(e)) {
			return e;
		}
		return e?.fileList;
	};
	useEffect(() => {
		setChapterandQuestiontype();
		setRadiovalue(correctOption);

		if (newquestionid && newquestionid != '') {
			if (imagedata && imagedata != '') {
				handleUpload();
				localStorage.setItem('newId', newquestionid);
				localStorage.setItem('chapId', data[0].id);
				localStorage.setItem('questionType', data[0].qType);
			} else {
				localStorage.setItem('newId', newquestionid);
				localStorage.setItem('chapId', data[0].id);
				localStorage.setItem('questionType', data[0].qType);
				//navigate(-1);
				navigate(`/get-chapter-questions/${chapterid}`, { state: { chapterID: chapterid, qID: qid }});
			}
		}
	}, [correctOption, correctopsym, chapter, newquestionid, status, imageoptionCount, loading])
	const setChapterandQuestiontype = () => {
		if(data && data != ''){
			if (data[0].clickEvent === true) {
				setStatus(true);
				const filteredChapter = (data[0].chapters).filter((el) => (el.value == data[0].id));
				if (filteredChapter && filteredChapter.length > 0) {
					setChapter(filteredChapter[0].label);
				}
				setQtype(data[0].qType == 1 ? 'Single select' : 'Image question');
			}
		}
		
	}

	const submitEnteredvalues = (values) => {
		let singleselecttypepayload;
		let Imagetypepayload;
		singleselecttypepayload = {
			question_text: question,
			opt_cnt: 4,
			explanation: explaination,
			edited_by: 1,
			q_type: 1,
			is_tutorial: 'f',
			answer_data: stepsMap,
			correct_optn: value,
			chapter_id: data&&data[0].id,
			points: values.points,
		}

		Imagetypepayload = {
			question_text: question,
			opt_cnt: imageoptionCount,
			explanation: explaination,
			edited_by: 1,
			q_type: 2,
			is_tutorial: 'f',
			answer_data: tempArr,
			chapter_id: data&&data[0].id,
			points: points,
		}
		const payloadType = data&&data[0].qType == 1 ? singleselecttypepayload : Imagetypepayload;
		form.validateFields();
		setLoading(true);
		fetchAdminApi(`/add-question`, 'post', payloadType).then((res) => {
			if (res.code === 200) {
				setNewQuestionid(res.Question_id);
			} else {
				form.resetFields();
				success('error', constants.question_added_failure, 'notificationPosition', messageApi);
			}
		})

	}
	const updateQuillOptionValues = (index, value) => {
		const updateStepsArrayData = stepsMap.map((data, idx) => {
			return idx === index ? {
				...data,
				option_text: value
			} : data;
		});
		setStepsMap(updateStepsArrayData);
	}
	const onChangeradioValue = (e) => {
		setValue(e.target.value);
		if (value !== '' || value !== null) {
			setcorrectOption(e.target.value);
		}
	}
	const tabHideEls = document.querySelectorAll("button.ql-underline,button.ql-bold,button.ql-italic");
	tabHideEls.forEach(function (item) {
		item.setAttribute("tabindex", -1);
	})

	return (
		<div className='min-h-screen cursorChange'>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.questionPagecrumbs, newCrumb)}
								className='bg-neutral-100 shadow-lg p-3 mb-3' />
						</div>
						<Card
							title="ADD QUESTION"
							className="addQcard mainCard"
							hoverable
							extra={
								<Space className="font-medium chapterInfo">
									<div className="commonColor font-semibold"><span>{chapter}
									</span></div> <div> | </div><div className="commonColor font-semibold">Question type : <span>{qtype}</span>
									</div>
								</Space>
							}
						>
							<Form
								layout='vertical'
								name="form_nest_item"
								onFinishFailed={
									() => {
										window.scrollTo(0, 0);
										success('error', constants.question_added_failure, 'afterQaddsuccess', messageApi)
									}
								}
								onFinish={submitEnteredvalues}
								form={form}
								initialValues={
									{
										options: [''],
										optionNumber: 1
									}
								}
							>
								<Row>
									<Col xs={24}>
										<Card
											hoverable
											title='Question'
											className='questionCard'
										>
											<Form.Item
												name="question"
												className='questionContainer'
												rules={
													[{
														required: true,
														message: constants.mandatoryField
													},
													{
														validator: (_, value) =>
															value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
													},
													]
												}
											>
												<ReactQuill
													theme="snow"
													value={question}
													modules={constants.ReactQuillModules}
													formats={constants.ReactQuillFormats}
													onChange={quillQuestionvalue}
												/>
											</Form.Item>
										</Card>
									</Col>
								</Row>
								<Card className="my-5" hoverable>
									<Row>
										<Col md={12} className="flex">
											<Form.Item
												label="No. of options :"
												className='defaultflexDirection'
												name='optionNumber'
												rules={
													data&&data[0].qType == 1
														?
														[{
															required: false,
															message: ""
														}] : [{
															required: true,
															message: constants.mandatoryField,
														}]
												}
											>
												{
													data&&data[0].qType == 1
														?
														<div className='absolute font-medium text-base'> 4 </div>
														:
														<InputNumber
															min={1}
															max={26}
															value={imageoptionCount}
															onChange={onChangeoptionNumbers}
															className='mt-3'
														/>
												}
											</Form.Item>
												<Form.Item name="points" label="Points" className='ml-7 points-field'>
														<InputNumber
															min={0}
															value={points}
															onChange={onChangePoints}
															className='mt-3'
														/>
												</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col md={16}>
											<Form.Item
												name="dragger"
												valuePropName="fileList"
												getValueFromEvent={normFile}
												action="/upload.do"
												className='defaultflexDirection mb-5'
												label="Image :"
												rules={
													data&&data[0].qType == 1
														?
														[{
															required: false,
															message: ""
														}]
														:
														[{
															required: true,
															message: ""
														},
														{
															validator: (_, value) =>
																value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
														},
														]
												}
											>
												<Dragger
													name="files"
													action=""
													listType="picture"
													maxCount={1}
													accept=".png,.jpeg,.jpg"
													beforeUpload={
														(file) => {
															const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
															if (!isJpgOrPng) {
																message.error(constants.imageTypeSelection);
															}
															return false;
														}
													}
												>
													<p className="ant-upload-drag-icon"><PlusOutlined /></p>
													<p className="ant-upload-text">{constants.chooseImage}</p>
												</Dragger>
											</Form.Item>
										</Col>
									</Row>
								</Card>
								{
									data&&data[0].qType == 1 ?
										<Card
											className="optionEditors border-t-3 my-5"
											title={constants.selectOneoptionText}
											hoverable>
											<Row className='flex flex-row'>
												<Col md={2} lg={2}>
													<Form.Item
														name="radio-group"
														rules={
															[{
																required: true,
																message: constants.selectcorrectOption,
															}]
														}>
														<Radio.Group
															onChange={onChangeradioValue}
															value={value}
															className='font-bold text-base radio-group'
														>
															<Radio value={1}> A </Radio>
															<Radio value={2}> B </Radio>
															<Radio value={3}> C </Radio>
															<Radio value={4}> D </Radio>
														</Radio.Group>
													</Form.Item>
												</Col>
												<Col xs={10} sm={18} md={22} lg={22}>
													{
														stepsMap.map((field, index) => {
															return (
																<Form.Item
																	key={index}
																	name={[field.optionValue, "name"]}
																	rules={
																		[{
																			required: true,
																			message: constants.mandatoryField
																		},
																		{
																			validator: (_, value) =>
																				value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
																		},
																		]
																	}
																>
																	<ReactQuill
																		theme="snow"
																		value={field.option_text}
																		modules={constants.ReactQuillModules}
																		formats={constants.ReactQuillFormats}
																		onChange={value => updateQuillOptionValues(index, value)}
																		className='quill-option'
																	/>
																</Form.Item>
															)
														})
													}
												</Col>
											</Row>
										</Card>
										:
										<Card
											title="Options"
											hoverable
											className="imagetypeoptionCard">
											{
												Array.from({
													length: imageoptionCount
												}).map((_, i) => {
													const totalCount = [i + 65];
													const alphabets = totalCount.map((x) => String.fromCharCode(x));
													return (
														<div className="optns_image_types" key=''>
															<Form.Item>
																<div className='font-bold text-base labels labels_iq'> {alphabets} </div>
															</Form.Item>
															<Form.Item
																key={i}
																name={[i, "option_text"]}
																rules={
																	[{
																		required: true,
																		message: constants.mandatoryField
																	},
																	{
																		validator: (_, value) =>
																			value != '' && value != "<p><br></p>" ? Promise.resolve() : Promise.reject(new Error(constants.mandatoryField)),
																	},
																	]
																}
															>
																<ReactQuill
																	className='my-3 imageQuills'
																	theme="snow"
																	modules={constants.ReactQuillModules}
																	formats={constants.ReactQuillFormats}
																	value={tempArr}
																	onChange={(value) => updateQuillValues(value, i)}
																/>
															</Form.Item>
														</div>
													)
												})
											}
										</Card>
								}
								<Card
									title="Explanation"
									hoverable
									className="mt-5 explaination border-2 explain">
									<Form.Item name="explaination">
										<ReactQuill
											theme="snow"
											value={explaination}
											modules={constants.ReactQuillModules}
											formats={constants.ReactQuillFormats}
											onChange={getExplaination}
										/>
									</Form.Item>
								</Card>
								<Form.Item
									label=""
									colon={false}
									className='my-5'
								>
									{contextHolder}
									<Button type="primary" htmlType="submit" className='font-semibold'> Submit </Button>
									<Button type="primary" className='ml-4 font-semibold' onClick={goBack}> Cancel </Button>
								</Form.Item>
							</Form>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}
export default AddQuestion;
