import React,{useState,useEffect} from 'react';
import { getCustomisedBreadcrumbs } from '../../../functions/functions';
import { CheckOutlined, MinusOutlined, CloseOutlined } from '@ant-design/icons';
import constants from '../../../constants/constants';
import { Link } from 'react-router-dom';
import { fetchAdminApi } from '../../../services/api';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
	Breadcrumb,
	Row,
	Input,
	Col,
	Card,
	Table,
	Form,
	Select,
	Tooltip
} from 'antd';

const EditStudent = () => {
	const [form] = Form.useForm();
	const [newCrumb,setNewcrumb] = useState();
	const [firstname,setFirstname] = useState('');
	const [lastname,setLastname] = useState('');
	const [countryid,setCountryid] = useState('');
	const [universityvalue, setUniversityvalue] = useState('');
	const [countries, setCountries] = useState([]);
	const [universities,setUniversities] = useState([]);
	const [rolevalue,setRolevalue] = useState('');
	const [studentdetails,setStudentDetails] = useState([]);
	const [registereddate,setRegistereddate] = useState('');
	const [resourcedata,setResourceData] = useState([]);
	const [studentId,setStudentId] = useState('');
	let studentInfo;
	const roles = [
		{ role: constants.studentTitle,key:'0'},
		{ role: constants.professionalTitle, key: '1' },
		{ role: constants.facultyTitle, key: '2' },
		{ role: constants.otherTitle, key: '3' },
	]
	useEffect(()=>{
		getAllCountries();
		getAllUniversities();
		getSessionStorageDataAndSetBreadcumbs('studentFromRegisteredStudentPage', 1);
		getSessionStorageDataAndSetBreadcumbs('studentFromOverallFeedbackpage', 2);
		getSessionStorageDataAndSetBreadcumbs('studentFromChapterFeedbackpage', 2);
		getSessionStorageDataAndSetBreadcumbs('studentFromIssueFeedbackpage', 2);
		getSessionStorageDataAndSetBreadcumbs('studentFromTestResultPage', 1);
		getSessionStorageDataAndSetBreadcumbs('studentFromTutorialResultPage', 1);
		getSessionStorageDataAndSetBreadcumbs('studentFromFlashcardResultPage', 1);
	},[])
	useEffect(()=>{
		if(studentId){
			getStudentInfo();
			getStudentResourceRelatedData();
		}
	}, [studentId])
	const getSessionStorageDataAndSetBreadcumbs = (sessionKey,type)=>{
		if (sessionStorage.getItem(sessionKey)){
			studentInfo = JSON.parse(sessionStorage.getItem(sessionKey));
			setStudentId(studentInfo?.studentId);
			type == 1 ?
				setNewcrumb([<Link key={0} to="/analysis-menus" className="font-semibold">Analysis</Link>, <a key={1} className="!text-black font-semibold">{constants.viewStudent}</a>])
			:
				setNewcrumb([<Link key={0} to="/feedback-menus" className="font-semibold">Feedbacks</Link>, <a key={1} className="!text-black font-semibold">{constants.viewStudent}</a>])
		}
	}
	const getStudentInfo = ()=>{
		fetchAdminApi(`/get-student?stud_id=${sessionStorage.getItem("studentFromRegisteredStudentPage") ? studentId : sessionStorage.getItem("studentFromOverallFeedbackpage") ? studentId : sessionStorage.getItem("studentFromChapterFeedbackpage") ? studentId : sessionStorage.getItem("studentFromIssueFeedbackpage") ? studentId : sessionStorage.getItem("studentFromTestResultPage") ? studentId : sessionStorage.getItem("studentFromTutorialResultPage") ? studentId : sessionStorage.getItem("studentFromFlashcardResultPage") ? studentId :'' }`, 'get').then((res) => {
			if (res?.data) {
				setStudentDetails(res?.data);
				setCountryid(res?.data[0].country_id);
				setRolevalue(res?.data[0].role);
				setRegistereddate(res?.data[0].dt_created);
				form.setFieldsValue({
					firstName: res?.data[0].stud_firstname,
					lastName: res?.data[0].stud_lastname,
					primaryEmail: res?.data[0].stud_email,
					alternateEmail: res?.data[0].alternate_stud_email,
					contactNumber: res?.data[0].stud_contact_no,
					role: res?.data[0].role,
					country: res?.data[0].country_id,
					state: res?.data[0].region_name,
					university: res?.data[0].university_name,
					registeredDate: res?.data[0].dt_created,
				})
			} else {
				setStudentDetails([]);
			}
		})
	}
	const getStudentResourceRelatedData = ()=>{
		fetchAdminApi(`/get-student-details/${studentId}`, 'get').then((res) => {
			if (res?.data) {
				setResourceData(res?.data);
			} else {
				setResourceData([]);
			}
		})
	}
	const getAllCountries = () => {
		fetchAdminApi(`/admin-countries`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				setCountries(res?.data);
			} else {
				setCountries([]);
			}
		})
	}
	const getAllUniversities = () => {
		fetchAdminApi(`/admin-universities?country_code=US`, "get").then(
			(res) => {
				if (res?.data) {
					setUniversities(res?.data);
				} else {
					setUniversities([]);
				}
			}
		);
	};
	const studentRelatedResourceTabColumns = [
		{
			title:<Tooltip title='Chapter Id' className="cursor-default">Ch#</Tooltip>,
			key: 'Chapter',
			dataIndex:'chapter_id',
			align: 'center',
			width: 10,
		},
		{
			title: <div className="text-base">{constants.chapterTitle}</div>,
			key: 'Title',
			dataIndex: 'chapter_title',
			align: 'left',
			width: 200,
		},
		{
			title: <div className="text-base">{`${constants.Tests} (${constants.QuestionCount} ${constants.questionsAbbr})`}</div>,
			children: [
				{
					title: <Tooltip title={constants.attemptOne} className="cursor-default">{constants.attempt1Abbr}</Tooltip>,
					key: 'Attempt#1',
					align: 'center',
					width: 12,
					render: (record) => {
						return (
							<span>{record.test_attempt1 == 'P' ? 'Paused' : record.test_attempt1 == '0' ? <MinusOutlined /> : record.test_attempt1}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.attemptTwo} className="cursor-default">{constants.attempt2Abbr}</Tooltip>,
					key: 'Attempt#2',
					align: 'center',
					width: 12,
					render: (record) => {
						return (
							<span>{record.test_attempt2 == 'P' ? 'Paused' : record.test_attempt2 == '0' ? <MinusOutlined /> : record.test_attempt2}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.attemptThree} className="cursor-default">{constants.attempt3Abbr}</Tooltip>,
					key: 'Attempt#3',
					align: 'center',
					width: 12,
					render: (record) => {
						return (
							<span>{record.test_attempt3 == 'P' ? constants.paused : record.test_attempt3 == '0' ? <MinusOutlined /> : record.test_attempt3}</span>
						)
					}
				}
			],
		},
		{
			title: <div className="text-base">{`Tutorials (${constants.TutorialCount} Qns)`}</div>,
			children: [
				{
					title: <Tooltip title={constants.attemptOne} className="cursor-default">{constants.attempt1Abbr}</Tooltip>,
					key: 'Attempt#1',
					align: 'center',
					width: 12,
					render: (record) => {
						return (
							<span>{record.tutorial_attempt1 != '0' ? record.tutorial_attempt1 : <MinusOutlined />}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.attemptTwo} className="cursor-default">{constants.attempt2Abbr}</Tooltip>,
					key: 'Attempt#2',
					align: 'center',
					width: 12,
					render: (record) => {
						return (
							<span>{record.tutorial_attempt2 != '0' ? record.tutorial_attempt2 : <MinusOutlined />}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.markedForLater} className="cursor-default">{constants.markedForlaterAbbr}</Tooltip>,
					key: constants.markedForlaterAbbr,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							<span>{record.mark_for_later_count != '0' ? record.mark_for_later_count : <MinusOutlined />}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.incorrectAnswers} className="cursor-default">{constants.incorrectAnswerAbbr}</Tooltip>,
					key: constants.incorrectAnswerAbbr,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							<span>{record.incorrect_ans_count != '0' ? record.incorrect_ans_count : <MinusOutlined />}</span>
						)
					}
				}
			],
		},
		{
			title: <div className="text-base">{constants.Flashcards}</div>,
			children: [
				{
					title: <Tooltip title={constants.pausedFlashcardCount} className="cursor-default">{constants.paused}</Tooltip>,
					key: 'Paused',
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							<span>{record.flashcard_paused != "0" ? record.flashcard_paused : <MinusOutlined />}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.flashcardCompletedDate} className="cursor-default">{constants.completed}</Tooltip>,
					key: 'Completed',
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							<span>{record.flashcard_completed ? record.flashcard_completed : <MinusOutlined />}</span>
						)
					}
				},
			],
		},
		{
			title: <div className="text-base">{constants.Images}</div>,
			children: [
				{
					title: <Tooltip title={constants.viewedImgCount} className="cursor-default">{constants.viewed}</Tooltip>,
					key: constants.viewed,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							<span>{record.images_viewed ? record.images_viewed : <MinusOutlined />}</span>
						)
					}
				},
				{
					title: <Tooltip title={constants.totalImgCount} className="cursor-default">{constants.total}</Tooltip>,
					dataIndex: 'total_images',
					key: 'Total',
					align: 'center',
					width: 10,
				},
			],
		},
		{
			title: <div className="text-base">{constants.studyGuide}</div>,
			key: constants.studyGuide,
			align: 'center',
			width: 10,
			render: (record) => {
				return (
					record.studyguide != null ?
						<div><Tooltip title={`${record.studyguide != null ? `Downloaded on ${record.studyguide}`:''}`}><CheckOutlined className='text-pink-700 font-bold'/></Tooltip></div>
						:
						<div><MinusOutlined /></div>
				)
			},
		},
		{
			title: <div className="text-base">Feedback</div>,
			children: [
				{
					title: <Tooltip title={constants.Images} className="cursor-default">{constants.imageAbbr}</Tooltip>,
					key: constants.imageAbbr,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							record.images_rating == '0' ?
								<Tooltip title='Excellent'><span className={constants.positiveColor}>E</span></Tooltip>
								:
							record.images_rating == '1' ?
								<Tooltip title='Good'><span className={constants.positiveColor}>G</span></Tooltip>
								:
							record.images_rating == '2' ?
								<Tooltip title='Helpful'><span className={constants.positiveColor}>H</span></Tooltip>
								:
							record.images_rating == '3' ?
								<Tooltip title='Unhelpful'><span className={constants.negativeColor}><CloseOutlined /></span></Tooltip>
								:
							record.images_rating == '4' ?
								<Tooltip title='Undecided'><span className={constants.negativeColor}><MinusOutlined /></span></Tooltip>
								:
								<MinusOutlined />
						)
					},
				},
				{
					title: <Tooltip title={constants.Flashcards} className="cursor-default">{constants.flashcardAbbr}</Tooltip>,
					key: constants.flashcardAbbr,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							record.flashcards_rating == '0' ?
								<Tooltip title='Excellent'><span className={constants.positiveColor}>E</span></Tooltip>
								:
							record.flashcards_rating == '1' ?
								<Tooltip title='Good'><span className={constants.positiveColor}>G</span></Tooltip>
								:
							record.flashcards_rating == '2' ?
								<Tooltip title='Helpful'><span className={constants.positiveColor}>H</span></Tooltip>
								:
							record.flashcards_rating == '3' ?
								<Tooltip title='Unhelpful'><span className={constants.negativeColor}><CloseOutlined /></span></Tooltip>
								:
							record.flashcards_rating == '4' ?
								<Tooltip title='Undecided'><span className={constants.negativeColor}><MinusOutlined /></span></Tooltip>
								:
								<MinusOutlined />
						)
					},
				},
				{
					title: constants.Tutorials,
					key: constants.Tutorials,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							record.tutorials_rating == '0' ?
								<Tooltip title='Excellent'><span className={constants.positiveColor}>E</span></Tooltip>
								:
							record.tutorials_rating == '1' ?
								<Tooltip title='Good'><span className={constants.positiveColor}>G</span></Tooltip>
								:
							record.tutorials_rating == '2' ?
								<Tooltip title='Helpful'><span className={constants.positiveColor}>H</span></Tooltip>
								:
							record.tutorials_rating == '3' ?
								<Tooltip title='Unhelpful'><span className={constants.negativeColor}><CloseOutlined /></span></Tooltip>
								:
							record.tutorials_rating == '4' ?
								<Tooltip title='Undecided'><span className={constants.negativeColor}><MinusOutlined /></span></Tooltip>
								:
								<MinusOutlined />
						)
					},
				},
				{
					title: constants.Tests,
					key: constants.Tests,
					align: 'center',
					width: 10,
					render: (record) => {
						return (
							record.tests_rating == '0' ?
								<Tooltip title='Excellent'><span className={constants.positiveColor}>E</span></Tooltip>
								:
							record.tests_rating == '1' ?
								<Tooltip title='Good'><span className={constants.positiveColor}>G</span></Tooltip>
								:
							record.tests_rating == '2' ?
								<Tooltip title='Helpful'><span className={constants.positiveColor}>H</span></Tooltip>
								:
							record.tests_rating == '3' ?
								<Tooltip title='Unhelpful'><span className={constants.negativeColor}><CloseOutlined /></span></Tooltip>
								:
							record.tests_rating == '4' ?
								<Tooltip title='Undecided'><span className={constants.negativeColor}><MinusOutlined /></span></Tooltip>
											:
								<MinusOutlined />
						)
					},
				},
			],
		},
	]
	const getRolevalue = (value) => {
		setRolevalue(value);
	}
	const getFirstname = (e) => {
		setFirstname(e.target.value);
	}
	const getLastname = (e)=>{
		setLastname(e.target.value);
	}
	const getCountry = (value, option) => {
		setCountryid(value);
	}
	const getUniversityvalue = (value, option)=>{
		setUniversityvalue(value);
	}
	const filterOption = (input, option) =>
		(option?.children.toLowerCase().includes(input.toLowerCase()));

	return (
		<div className="cursorChange">
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.homePageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card title={constants.viewStudentUppercase} hoverable className="addQcard">
								<Row>
									<Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
											<Form form={form} layout='vertical' className='LoginForm viewStudentForm'
												initialValues={
													{
														country: countryid,
														role: rolevalue,
													}
												} autocomplete="off"
											>
												<Row gutter={24}>
													<Col span={8}>
													<Form.Item label={constants.firstNameUppercase} name='firstName'>
														<Input onChange={getFirstname} value={firstname} readOnly></Input>
														</Form.Item>
													</Col>
													<Col span={8}>
													<Form.Item label={constants.lastNameUppercase} name='lastName'>
															<Input onChange={getLastname} value={lastname} readOnly></Input>
														</Form.Item>
													</Col>
													<Col span={8}>
													<Form.Item label={constants.roleUppercase} name='role'>
															<Select onChange={getRolevalue} className='cursor-text' value={rolevalue} open={false}>
																{roles.map((role, index) => (
																	<Select.Option key={role.key} title=" ">
																		{role.role}
																	</Select.Option>
																))}
															</Select>
														</Form.Item>
													</Col>
													<Col span={8}>
													<Form.Item label={constants.countryUppercase} name='country'>
															<Select onChange={getCountry} showSearch filterOption={filterOption} open={false}>
																{countries.map((country) => (
																	<Select.Option title=" " key={country?.country_id} value={country?.country_id}>
																		{country?.country_name}
																	</Select.Option>
																))}
															</Select>
														</Form.Item>
													</Col>
													<Col span={8}>
													<Form.Item label={constants.stateUppercase} name='state'>
															<Input readOnly></Input>
														</Form.Item>
													</Col>
													<Col span={8}>
													<Form.Item label={constants.universityUppercase} name='university'>
														<Select title=" " className='cursor-text' onChange={getUniversityvalue} optionFilterProp={"children"} open={false}>
																{universities &&
																	Object.entries(universities).map(([key, value]) => (
																		<Select.OptGroup label={key} key={key}>
																			{value && value.universities && value.universities.length > 0 &&
																				value.universities.map((uni) => (
																					<Select.Option region={uni.region_id} value={uni.university_id} key={uni.university_id}>{uni.university_name + (uni.city ? "," + uni.city : "")}</Select.Option>
																				))}
																		</Select.OptGroup>
																	))}
															</Select>
														</Form.Item>
													</Col>
													<Col span={8}>
													<Form.Item label={constants.primaryEmailUppercase} name='primaryEmail' 
														rules={[
															{
																type: 'email',
																message: '',
															},
														]}
														>
															<Input readOnly></Input>
														</Form.Item>
													</Col>
													<Col span={8}>
													<Form.Item label={constants.alternateEmailUppercase} name='alternateEmail'
															rules={[
																{
																	type: 'email',
																	message: '',
																},
															]}
														>
															<Input readOnly></Input>
														</Form.Item>
													</Col>
												<Col span={8}>
													<Form.Item label={constants.contactUppercase} name='contactNumber'>
														<PhoneInput country={'us'} placeholder="1(999)123-4567" className="contactInput" disabled />
													</Form.Item>
												</Col>
												<Col span={8}>
													<Form.Item label={constants.registeredOn} name='registeredDate'>
														<Input readOnly></Input>
													</Form.Item>
												</Col>
											</Row>
											</Form>
										</Card>
									</Col>
								</Row>
							
					 </Card>
						<Table
							bordered
							columns={studentRelatedResourceTabColumns}
							dataSource={resourcedata}
							className='registeredStudsTable viewStudentTab'
							scroll={{ x: 768 }}
							pagination={false}
						></Table>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default EditStudent
