import React, { useState, useEffect } from "react";
import {  BackTop, Table, Tooltip, Skeleton, Button, Result } from 'antd';
import constants from "../../../constants/constants";
import { COOKIE,getCookie } from "../../../services/cookie";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchApi } from "../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import Chart from "react-google-charts";

const CompareScore = () => {
	const navigate = useNavigate();
	const location = useLocation();
  const [latestScore, setLatestScore] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [graph, setGraph] = useState(true);
	const [apiWait,setApiWait]=useState(false);

  let options = {
		colors: [constants.CompareAttempt1Color, constants.CompareAttempt2Color, constants.CompareAttempt3Color],
		legend: { position: 'top', alignment: 'start' },
    vAxes: [
			{
				title: "Test Score",
				titleTextStyle: { italic: false },
				minValue: 0,
				ticks: [0 ,20, 40, 60, 80, 100],
			},
		],
		vAxis: { 
			gridlines: { minValue: 0, },
			minorGridlines: { 
				count: 0,
				color: 'transparent',
			},
		},
		hAxis: {
			title: "Tests",
			titleTextStyle: { italic: false } ,
		},
		seriesType: "bars",
		axes: {
			y: {
				0: { 
					label: 'leftyaxis', 				
					minValue: 0,
					gridlines: { color: '#f3f3f3', },
				}, // Left y-axis.
				1: {
					side: 'left',
					label: 'rightyaxis',
					minValue: 0,
					gridlines: { color: 'transparent', },
				} // Right y-axis.
			}
		}
	};

  // Columns for table to display score
  const columns = [
		{
			title: '#',
			key: 'srNum',
			dataIndex:'srNum',
			align:'center',
			className: 'td-right',
		},
		{
			title: 'Chapter',
			key: 'chapter_name',
			dataIndex:'chapter_name'
		},
		{
			title: 'Attempt #1',
			key: 'attempt1',
      align:'center',
			render: (record) => {
				return (
          <>
          {record?.attempt1?
            <Tooltip title={record?.attempt1?.score==="p"?"Attempted on: "+record.attempt1.date:"Completed on: "+record.attempt1.date}><Button type="text" className="text-blue-600" onClick={()=>{record?.attempt1?.score==="p"?routeToChapter(record?.srNum):redirectTestresult(record?.srNum,1,record?.chapter_name,record?.attempt1?.scorecard)}}>{record.attempt1.score==="p"?"Paused":record.attempt1.score}</Button></Tooltip>:
            <span>-</span>
          }
          </>
				);
			}
		},
    {
			title: 'Attempt #2',
			key: 'attempt2',
      align:'center',
			render: (record) => {
				return (
					<>
          {record?.attempt2?
            <Tooltip title={record?.attempt2?.score==="p"?"Attempted on: "+record.attempt2.date:"Completed on: "+record.attempt2.date}><Button type="text" className="text-blue-600" onClick={()=>{record?.attempt2?.score==="p"?routeToChapter(record?.srNum):redirectTestresult(record?.srNum,2,record?.chapter_name,record?.attempt2?.scorecard)}}>{record.attempt2.score==="p"?"Paused":record.attempt2.score}</Button></Tooltip>:
            <span>-</span>
          }
          </>
				);
			}
		},
    {
			title: 'Attempt #3',
			key: 'attempt3',
      align:'center',
			render: (record) => {
				return (
					<>
          {record?.attempt3?
            <Tooltip title={record?.attempt3?.score==="p"?"Attempted on: "+record.attempt3.date:"Completed on: "+record.attempt3.date}><Button type="text" className="text-blue-600" onClick={()=>{record?.attempt3?.score==="p"?routeToChapter(record?.srNum):redirectTestresult(record?.srNum,3,record?.chapter_name,record?.attempt3?.scorecard)}}>{record.attempt3.score==="p"?"Paused":record.attempt3.score}</Button></Tooltip>:
            <span>-</span>
          }
          </>
				);
			}
		},

	];
	//Redirect to chapter resource
	const routeToChapter = (chapter_id) => {
    const state = { 
      chapter_id: chapter_id,
			isScrollToTest:"t"
    };
    navigate('/chapter-resources', { state });
  };
	//Redirect to test result page
	const redirectTestresult=(chapterId,attempt,chapter_name,scoreCardId)=>{
		let state={
			chapter_id:chapterId,
			attempt:attempt,
			chapter_name:chapter_name,
			scoreCardId:scoreCardId
		}
		navigate('/test-results',{ state , replace: true});
	}
  useEffect(() => {
		fetchLatestScores();
	}, []);

  // To Fetch the data for test taken by the user
	const fetchLatestScores = async() => {
		setApiWait(false);
    let userId = getCookie(COOKIE.UserId);
		fetchApi(`/compare-score?stud_id=${userId}`, "get").then((res) => {
      let tempGraphData=[[
        "Chapter",
        "Attempt #1",
        "Attempt #2",
        "Attempt #3"
      ]]
			if (res && res.code === 200) {
        for(let i=0;i<res?.data?.length;i++){
          let pushArray=[];
          pushArray=["Ch #"+res?.data[i]?.srNum,res?.data[i]?.attempt1?Number(res.data[i].attempt1?.score==="p"?0:res.data[i].attempt1?.score):0,res?.data[i]?.attempt2?Number(res.data[i].attempt2?.score==="p"?0:res.data[i].attempt2?.score):0,res?.data[i]?.attempt3?Number(res.data[i].attempt3?.score==="p"?0:res.data[i].attempt3?.score):0]
          tempGraphData.push(pushArray);
        }
        setGraph(true);
				setLatestScore(res?.data);
        setGraphData(tempGraphData);
				setApiWait(true);
			}else{
				setLatestScore([]);
        setGraphData([]);
        setGraph(false);
				setApiWait(true);
			}
		});
	};

  return (
		<div>
			<BackTop/>
			<div className="grid gap-3 container mx-auto">
				<div className="flex flex-wrap justify-between items-center">
					<h1 className="font-bold text-xl">{constants.ScoreComparison}</h1>
          <Button onClick={() => navigate('/dashboard')} icon={<ArrowLeftOutlined />} className='rounded-lg bg-blue-700 text-white'>{constants.BckDashbd}</Button>
				</div>
				{apiWait?
				<>
				<div className="border border-gray-300 rounded-lg overflow-hidden">
          { graph && graphData.length>0? 
						<>
							<Chart
								chartType="ComboChart"
								loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
								width="100%"
								height="400px"
								data={graphData}
								options={options}
							/>
						</>
						: 						
            <div>
              <Result
                title={constants.NoTestsAnswered}
              />
            </div>
					}
				</div>
				<div className="w-2/3 mx-auto">
          {latestScore.length<1?null:
            <div id='tableCompare'>
              <Table columns={columns} 
                dataSource={latestScore}
                pagination={false}
                bordered
              />
            </div>
          }
				</div>
				</>:null}
			</div>
		</div>
	);
}
export default CompareScore;