import React, { useState, useEffect } from 'react';
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Table,
	Form,
	DatePicker,
	Select,
} from 'antd';
import { getCustomisedBreadcrumbs, setStartandEnddate, changeDateFormat } from '../../../functions/functions';
import constants from '../../../constants/constants';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { fetchAdminApi } from '../../../services/api';
const { RangePicker } = DatePicker;

const RegistrationsByUniversities = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [universityRegistrations, setUniversityRegistrations] = useState([]);
	const [startdate, setStartdate] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddate, setEnddate] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
	const [countries, setCountries] = useState([]);
	const newCrumb = [<Link key={1} to="/registration-by-university" className=" font-semibold">Top 10 University Registrations</Link>, <Link key={1} to="/registration-by-all-universities" className="!text-black font-semibold">All University Registrations</Link>]
	const [period, setPeriod] = useState();
	const [year, setYear] = useState(dayjs().year());

	const dateFormat = 'MM/DD/YYYY';

	useEffect(() => {
		getUniversityRegistrationData();
	}, [startdate, enddate])

	const getUniversityRegistrationData = () => {
		fetchAdminApi(`/students-by-university?start_date=${changeDateFormat(startdate)}&end_date=${changeDateFormat(enddate)}`, "get").then(
			(res) => {
				setUniversityRegistrations(res?.data);
			})
	}

	useEffect(() => {
		getAllCountries();
	}, [])

	const getAllCountries = () => {
		fetchAdminApi(`/admin-countries`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				setCountries(res?.data);
			} else {
				setCountries([]);
			}
		})
	}


	const options = {
		title: 'University registrations for the year 2023',
		titleTextStyle: {
			fontSize: 20,
			bold: 'true',
			color: '#8a1253',
		},
		vAxis: {
			title: 'Universities',
			textStyle: {
				fontSize: 14,
				color: '#000',
				bold: 'true'
			},
			titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
		},
		bar: { groupWidth: "50%" },
		bars: "horizontal",
		// colors: ["#fdb44b", "#8ed6ff", '#cf7979','#41d8bf'],
		is3D: true,
		animation: {
			duration: 1000,
			easing: 'linear',
			startup: true
		},
		'hAxis': {
			title: 'Registrations', gridlines: { count: -10 }, minorGridlines: 'NULL', textStyle: { fontSize: 14, color: 'black', bold: 'true' }, format: '####', titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
		},

		chartArea: { width: "55%", height: "80%" },
		isStacked: true,
		tooltip: { isHtml: true },
	};

	const getCountryCode = (id) => {
		const selectedCountry = countries.filter((each) => (each.country_id == id));
		return selectedCountry[0].country_code;
	}

	// const universityRegistrationData = [
	//   {
	//     state_id: "3854",
	//     university_id: "50",
	//     university_name: "Nova Southeastern University,   Ft Lauderdale",
	//     region_code: "FL",
	//     total_count: "149",
	//     student_count: "148",
	//     professional_count: "120",
	//     faculty_count: "110",
	//     other_count: "40",
	//   },
	//   {
	//     state_id: "3893",
	//     university_id: "255",
	//     university_name: "Texas Woman's University,  Denton",
	//     region_code: "TX",
	//     total_count: "94",
	//     student_count: "93",
	//     professional_count: "90",
	//     faculty_count: "85",
	//     other_count: "80",
	//   },
	//   {
	//     state_id: "3849",
	//     university_id: "25",
	//     university_name: "California State University,  Northridge",
	//     region_code: "CA",
	//     total_count: "46",
	//     student_count: "44",
	//     professional_count: "2",
	//     faculty_count: "0",
	//     other_count: "0",
	//   },
	//   {
	//     state_id: "3866",
	//     university_id: "109",
	//     university_name: "Emerson College,  Boston",
	//     region_code: "MA",
	//     total_count: "42",
	//     student_count: "38",
	//     professional_count: "4",
	//     faculty_count: "0",
	//     other_count: "0",
	//   },
	//   {
	//     state_id: "3866",
	//     university_id: "111",
	//     university_name: "Northeastern University,  Boston",
	//     region_code: "MA",
	//     total_count: "35",
	//     student_count: "35",
	//     professional_count: "0",
	//     faculty_count: "0",
	//     other_count: "0",
	//   },
	//   {
	//     state_id: "3893",
	//     university_id: "259",
	//     university_name: "University of St. Augustine for Health Sciences,  Austin",
	//     region_code: "TX",
	//     total_count: "35",
	//     student_count: "31",
	//     professional_count: "3",
	//     faculty_count: "0",
	//     other_count: "1",
	//   },
	//   {
	//     state_id: "3887",
	//     university_id: "223",
	//     university_name: "Misericordia University,  Dallas",
	//     region_code: "PA",
	//     total_count: "34",
	//     student_count: "34",
	//     professional_count: "0",
	//     faculty_count: "0",
	//     other_count: "0",
	//   },
	//   {
	//     state_id: "3893",
	//     university_id: "246",
	//     university_name: "Abilene Christian University,  Abilene",
	//     region_code: "TX",
	//     total_count: "33",
	//     student_count: "33",
	//     professional_count: "0",
	//     faculty_count: "0",
	//     other_count: "0",
	//   },
	//   {
	//     state_id: "3893",
	//     university_id: "247",
	//     university_name: "Baylor University,  Waco",
	//     region_code: "TX",
	//     total_count: "33",
	//     student_count: "30",
	//     professional_count: "2",
	//     faculty_count: "1",
	//     other_count: "0",
	//   },
	//   {
	//     state_id: "3883",
	//     university_id: "172",
	//     university_name: "New York University,  New York",
	//     region_code: "NY",
	//     total_count: "32",
	//     student_count: "31",
	//     professional_count: "30",
	//     faculty_count: "25",
	//     other_count: "20",
	//   }
	// ]

	// return (
	//   <div className="cursorChange">
	//     <Row>
	//       <Col xs={24} sm={24} md={24} lg={24}>
	//         <div className='p-4 min-h-screen bg-gray-100'>
	//           <div>
	//             <Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
	//               className='bg-neutral-100 shadow-md p-3 mb-3' />
	//           </div>
	//           <Card title="REGISTRATIONS BY UNIVERSITIES" className="addQcard" hoverable>
	//             <Row>
	//               <Col xs={24} sm={24} md={24} lg={24}>
	//                 <Card className='' hoverable>
	//                   <Chart
	//                     chartType="BarChart"
	//                     className='w-full'
	//                     width={'100%'}
	//                     height={'500px'}
	//                     loader={<div>Loading Chart</div>}
	//                     data={universityregisterationdata}
	//                     options={options}
	//                   />
	//                 </Card>
	//               </Col>
	//             </Row>
	//             <Row>
	//               <Col xs={24} sm={24} md={24} lg={24}>
	//                 <Card className='mt-3' hoverable>
	//                   <Table
	//                     bordered
	//                     dataSource={universityRegistrationData}
	//                     columns={universityTabColumns}
	//                     className='registrationTable'
	//                     pagination={false}
	//                     rowKey={(record) => record.university_name}
	//                     title={() => { return <div className="font-bold text-base text-white">{`UNIVERSITY REGISTRATIONS FOR THE YEAR 2023`}</div> }}
	//                     size="small"
	//                     summary={() => (
	//                       <Table.Summary fixed>
	//                         <Table.Summary.Row>
	//                           <Table.Summary.Cell index={0} className="font-bold text-base text-center">Total</Table.Summary.Cell>
	//                           <Table.Summary.Cell index={1} className="font-bold text-base text-center"></Table.Summary.Cell>
	//                           <Table.Summary.Cell index={2} className="font-bold text-base text-center"></Table.Summary.Cell>
	//                           <Table.Summary.Cell index={3} className="font-bold text-base text-center"></Table.Summary.Cell>
	//                           <Table.Summary.Cell index={4} className="font-bold text-base text-center"></Table.Summary.Cell>
	//                           <Table.Summary.Cell index={5} className="font-bold text-base text-center"></Table.Summary.Cell>
	//                           <Table.Summary.Cell index={6} className="font-bold text-base text-center">2100</Table.Summary.Cell>
	//                         </Table.Summary.Row>
	//                       </Table.Summary>
	//                     )}
	//                   />
	//                 </Card>
	//               </Col>
	//             </Row>
	//           </Card>
	//         </div>
	//       </Col>
	//     </Row>
	//   </div>
	// )

	const getInfoAndRedirect = (record, rolevalue, roleTitle) => {
		if (startdate && enddate) {
			let alluniversityRegistrationData = {};
			alluniversityRegistrationData.startdate = startdate;
			alluniversityRegistrationData.enddate = enddate;
			alluniversityRegistrationData.country = record.country_id;
			alluniversityRegistrationData.state = record.state_id;
			alluniversityRegistrationData.stateCode = record.region_code;
			alluniversityRegistrationData.countryCode = getCountryCode(record.country_id);
			alluniversityRegistrationData.university = record.university_id;
			alluniversityRegistrationData.universityName = record.university_name;
			alluniversityRegistrationData.occupation = rolevalue;
			alluniversityRegistrationData.roletitle = roleTitle;
			alluniversityRegistrationData.criteria = `Registrations from ${startdate} to ${enddate}`;
			navigate('/registered-students', { state: { allUniversityRegData: alluniversityRegistrationData } });
		}
	}

	const getStartandEnddate = (value, dateString) => {
		setStartdate(dateString[0]);
		setEnddate(dateString[1]);
	}
	const universityTabColumns = [
		{
			title: 'University',
			key: 'University',
			render: (record) => {
				return (
					<div className='text-sm'> {record.university_name}</div>
				)
			}
		},
		{
			title: 'State',
			key: 'State',
			dataIndex: 'region_code',
			align: 'center',
		},
		{
			title: 'Students',
			key: 'Students',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,0,'Student')}
					> {record.student_count}
					</div>
				)
			}
		},
		{
			title: 'Professionals',
			key: 'Professionals',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,1,'Professional')}
					> {record.professional_count}
					</div>
				)
			}
		},
		{
			title: 'Faculty',
			key: 'Faculty',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,2,'Faculty')}
					> {record.faculty_count}
					</div>
				)
			}
		},
		{
			title: 'Other',
			key: 'Other',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,3,'other')}
					> {record.other_count}
					</div>
				)
			}
		},
		{
			title: 'Total Registrations',
			key: 'Total Registrations',
			dataIndex: 'total_count',
			align: 'right',
		},
	]
	return (
		<div className="cursorChange">
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card hoverable className="addQcard" title="REGISTRATIONS BY UNIVERSITIES">
							<Row>
								<Col xs={24} sm={24} md={24} lg={24}>
									<Card hoverable>
										<Form form={form} className='countriesByregistrationFilters'>
											<Row gutter={24}>
												<Col span={12}>
													<Form.Item label="From">
														<RangePicker
															placeholder={["From", "To"]}
															className='w-11/12'
															defaultValue={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
															format={dateFormat}
															onChange={getStartandEnddate}
															allowClear={false}
															value={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item label="Period">
														<Select className='w-11/12 rounded-md'
															onChange={(value) => setStartandEnddate(value, startdate, enddate, setStartdate, setEnddate, setPeriod, setYear)}
															value={period}
															defaultValue={constants.periodOptions[0]}
														>
															{constants.periodOptions.map((option, index) => (
																<Select.Option key={option} value={index}>
																	{option}
																</Select.Option>
															))}
														</Select>

													</Form.Item>
												</Col>
											</Row>
										</Form>
									</Card>
									<Card className='mt-5' hoverable>
										<Table
											bordered
											dataSource={universityRegistrations}
											columns={universityTabColumns}
											className='registrationTable'
											rowKey={(record) => record.university_name}
											scroll={{ x: 768 }}
											title={() => { return <div className="font-bold text-base text-white">{`UNIVERSITY REGISTRATIONS FOR THE ${period == 0 || period == 1 ? `YEAR ${year}`: "SELECTED PERIOD"}`}</div> }}
											size="small"
											summary={(pageData) => {
												let totalstudentsPoints = 0;
												let totalprofPoints = 0;
												let totalfacultyPoints = 0;
												let totalotherPoints = 0;
												let totalPoints = 0;
												pageData.forEach(({ student_count, professional_count, faculty_count, other_count, total_count }) => {
													totalstudentsPoints += Number(student_count)
													totalprofPoints += Number(professional_count)
													totalfacultyPoints += Number(faculty_count)
													totalotherPoints += Number(other_count)
													totalPoints += Number(total_count)
												});
												return (
													<Table.Summary fixed>
														<Table.Summary.Row>
															<Table.Summary.Cell index={0} className="font-bold text-base text-left">Total</Table.Summary.Cell>
															<Table.Summary.Cell index={1} className="font-bold text-base text-center"></Table.Summary.Cell>
															<Table.Summary.Cell index={2} className="font-bold text-base text-right">{totalstudentsPoints}</Table.Summary.Cell>
															<Table.Summary.Cell index={3} className="font-bold text-base text-right">{totalprofPoints}</Table.Summary.Cell>
															<Table.Summary.Cell index={4} className="font-bold text-base text-right">{totalfacultyPoints}</Table.Summary.Cell>
															<Table.Summary.Cell index={5} className="font-bold text-base text-right">{totalotherPoints}</Table.Summary.Cell>
															<Table.Summary.Cell index={6} className="font-bold text-base text-right">{totalPoints}</Table.Summary.Cell>
														</Table.Summary.Row>
													</Table.Summary>
												)
											}}
										/>
									</Card>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default RegistrationsByUniversities
